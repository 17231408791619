let auth;
let WEB_URL = window.location.hostname;
if (WEB_URL === "localhost") {
  auth = {
    username: "Admin!",
    password: "%1[&Hc{?OkOSzg!",
  };
} else if (WEB_URL === "timetablingdevelop.campusplanner.in") {
  auth = {
    username: "Admin!",
    password: "%1[&Hc{?OkOSzg!",
  };
} else if (WEB_URL === "timetabling.campusplanner.in") {
  auth = {
    username: "Admin!",
    password: "%1[&Hc{?OkOSzg!",
  };
}
export default auth;
