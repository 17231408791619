import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Grid, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import LoginService from "../../Services/LoginService";
import { LoginDetails, SemesterDetails } from "../../Store/Reducers/login_menu";
import { useDispatch } from "react-redux";
import {
  getUserName,
  removeUserSession,
  setUserSession,
} from "../../Utils/common";
import CampusPlannerLogo from "../../Assets/Images/CampusPlannerLogo.png";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// ==============================|| LOGIN ||============================== //

const Login = () => {
  const navigate = useNavigate();

  const userState = {
    username: "",
    password: "",
  };
  const userName = getUserName();
  const dispatch = useDispatch();

  useEffect(() => {
    removeUserSession();
    if (userName === null) navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [uservalue, setUserValue] = useState(userState);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  //Function used to handle Username and Password
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserValue({ ...uservalue, [name]: value });
  };

  //Function used to handle show/hide Password
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  //Function used to handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (uservalue.username.length === 0 && uservalue.password.length === 0) {
      setError("Username and password is Required");
    } else if (uservalue.username.length === 0) {
      setError("Username is Required");
    } else if (uservalue.password.length === 0) {
      setError("Password is Required");
    } else {
      let data = {
        username: uservalue.username,
        password: uservalue.password,
      };
      LoginService.createLogin(data)
        .then((response) => {
          if (response.status === 200) {
            let userName = response.data.username;
            dispatch(LoginDetails(response.data));
            setUserSession(userName);

            LoginService.getSemester().then((res) => {
              dispatch(SemesterDetails(res.data));
            });

            navigate("/home");
          } else {
            setError("No more data");
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              setError("Invalid Credentials");
            } else {
              setError("Something went wrong");
            }
          } else {
            setError("Something went wrong");
          }
        });
    }
  };

  return (
    <>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className="background-Login"
      >
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "500px",
            backgroundPosition: "center",
          }}
        />
        <div className="login">
          <Container component="main" maxWidth="sm">
            <Box
              sx={{
                boxShadow: 1,
                borderRadius: 4,
                px: 2,
                py: 6,
                marginTop: "35%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "18%",
                width: "65%",
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <Grid>
                  <img
                    src={CampusPlannerLogo}
                    className="campusplanner-logo-login"
                    alt="logo"
                  />
                </Grid>
              </div>
              <div>
                <br></br>

                <form onSubmit={(e) => handleSubmit(e)}>
                  <Typography className="username-text">Username</Typography>
                  <OutlinedInput
                    className="username-textbox"
                    margin="normal"
                    required
                    id="username"
                    name="username"
                    placeholder="Enter Username"
                    value={uservalue.username}
                    onChange={(e) => handleChange(e)}
                  />
                  <br></br>
                  <Typography className="password-text">Password</Typography>
                  <OutlinedInput
                    className="password-textbox"
                    required
                    name="password"
                    placeholder="Enter Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    value={uservalue.password}
                    onChange={(e) => handleChange(e)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword}>
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <br></br>
                  {error && <p className="error-text">{error}</p>}
                  <Button
                    type="submit"
                    className="login-submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Login
                  </Button>
                </form>
              </div>
            </Box>
          </Container>
        </div>
      </Grid>
    </>
  );
};
export default Login;
