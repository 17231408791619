import React from "react";
import {
  Grid,
  List,
  ListItem,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Divider,
  Tooltip,
  ListSubheader,
} from "@mui/material";
import {
  EditCourseModel,
  DeleteCourseModel,
} from "../../../Store/Reducers/course_menu";
import { useSelector, useDispatch } from "react-redux";
import EditCourse from "./Course Models/EditCourse";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteCourseConfirmation from "./Course Models/DeleteCourseConfiramtion";
import "./CourseList.css";

// ==============================|| LHS COURSE LIST ||============================== //

const CourseList = ({ courseList, handleCourseClick, handleRemoveCourse }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.course_menu);
  const editCourseModel = menu.editCourseModel;
  const deleteCourseModel = menu.deleteCourseModel;
  const courseId = menu.courseId;
  const courseInFixedSlot = menu.courseInFixedSlot;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function used for getting Course name
  const getCourseCode = (id) => {
    const course = courseList.find((res) => res.id === id);

    if (course && courseInFixedSlot.includes(id)) {
      return (
        <span>
          <Tooltip
            title="Can't Edit and Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip title={course.courseCode}>
            <span>{course.courseCode}</span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip title={course.courseCode}>
          <span>{course.courseCode}</span>
        </Tooltip>
      );
    }
  };

  return (
    <>
      <Grid container>
        <List className="course_list" subheader={<li />}>
          <ListSubheader>
            <Grid container>
              <Grid item xs={3}>
                <Typography className="course-header">
                  <b> Course Code </b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className="course-header">
                  <b> Course Name </b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className="course-header">
                  <b> Course Type </b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography className="course-header">
                  <b>
                    Number of hours <br></br>per week
                  </b>
                </Typography>
              </Grid>
              <Divider className="course-divider" />
            </Grid>
          </ListSubheader>
          <div className="list-container courselist-height">
            {courseList.map((obj) => (
              <li key={obj.id}>
                <Button
                  className={
                    courseId === obj.id
                      ? "course-content--button--selected course-content--button"
                      : "course-content--button"
                  }
                  onClick={(event) => {
                    handleCourseClick(obj.id);
                  }}
                >
                  <ListItem className="list-course">
                    <Grid container>
                      <Grid item xs={3} sx={{ ml: 2 }}>
                        <Typography className="course-header text-overflow">
                          {getCourseCode(obj.id)}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={{ ml: -0.3 }}>
                        <Tooltip
                          title={obj.courseName}
                          placement="bottom-start"
                        >
                          <Typography className="course-header text-overflow">
                            {obj.courseName}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={3} sx={{ ml: 0 }}>
                        <Typography className="course-header">
                          {obj.courseTypeName}
                        </Typography>
                      </Grid>

                      <Grid item sx={{ ml: 0 }}>
                        <Typography className="course-header">
                          {obj.courseHours}
                        </Typography>
                      </Grid>

                      <IconButton
                        aria-label="more"
                        onClick={(event) => {
                          handleClick(event);
                        }}
                        aria-haspopup="true"
                        aria-controls="long-menu"
                        className="course-menu-icon"
                      >
                        <MoreVertIcon className="moreVertIcon-style" />
                      </IconButton>
                    </Grid>
                  </ListItem>
                </Button>
              </li>
            ))}
          </div>
        </List>
      </Grid>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(EditCourseModel(true));
            handleClose();
          }}
          disabled={
            courseInFixedSlot.includes(courseId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(DeleteCourseModel(true));
            handleClose();
          }}
          disabled={
            courseInFixedSlot.includes(courseId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
        >
          Delete
        </MenuItem>
      </Menu>
      {editCourseModel === true && <EditCourse />}
      {deleteCourseModel === true && (
        <DeleteCourseConfirmation handleRemoveCourse={handleRemoveCourse} />
      )}
    </>
  );
};
export default CourseList;
