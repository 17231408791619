/* eslint-disable array-callback-return */
import React from "react";
import {
  Grid,
  Button,
  List,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  ListSubheader,
  ListItem,
  Tooltip,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  DeleteStaffModel,
  EditStaffModel,
} from "../../../Store/Reducers/staff_menu";
import EditStaff from "./Staff Models/EditStaff";
import DeleteStaffConfirmation from "./Staff Models/DeleteStaffConfirmation";
import "./Staff.css";

// ==============================|| LHS STAFF LIST ||============================== //

const StaffList = ({ handleStaffClick, handleRemoveStaff }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.staff_menu);
  const editStaffModel = menu.editStaffModel;
  const deleteStaffModel = menu.deleteStaffModel;
  const staffList = menu.staffList;
  const staffId = menu.staffId;
  const staffInFixedSlot = menu.staffInFixedSlot;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const departmentList = infrastructure_menu.departmentList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Used to sort the staff list based on the department
  const sortedStaffList = [...staffList].sort(
    (a, b) => a.departmentId - b.departmentId,
  );

  // Function used for getting Staff code
  const getStaffCode = (id) => {
    const staff = staffList.find((res) => res.id === id);

    if (staff && staffInFixedSlot.includes(id)) {
      return (
        <span>
          <Tooltip
            title="Can't Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip title={staff.staffCode}>
            <span>{staff.staffCode}</span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip title={staff.staffCode}>
          <span>{staff.staffCode}</span>
        </Tooltip>
      );
    }
  };

  // Function used for getting Department name
  const getDepartmentName = (id) => {
    const departments = departmentList.find((res) => res.id === id);

    return (
      <Tooltip title={departments.departmentName}>
        {departments.departmentName.length > 25
          ? `${departments.departmentName.substring(0, 25)}...`
          : departments.departmentName}
      </Tooltip>
    );
  };

  return (
    <Grid container className="staff-page">
      <Grid container>
        <Grid item xs={13.7}>
          <>
            <List className="staff-list-style" subheader={<li />}>
              <ListSubheader>
                <Grid container>
                  <Grid item xs={3.9}>
                    <Typography className="staff-header">
                      Staff Reference
                    </Typography>
                  </Grid>
                  <Grid item xs={3.1}>
                    <Typography className="staff-header">Staff Name</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className="staff-header">
                      Department{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </ListSubheader>
              <Divider />
              <div className="list-container stafflist-height">
                {sortedStaffList.map((obj, index) => (
                  <>
                    {index !== 0 &&
                      sortedStaffList[index - 1].departmentId !==
                        obj.departmentId && (
                        <Divider
                          sx={{ color: "grey", width: "100%" }}
                          flexItem
                        />
                      )}

                    <li key={obj.id} className="comclass-list">
                      <Button
                        className={
                          staffId === obj.id
                            ? "staff-content--button--selected staff-content--button"
                            : "staff-content--button"
                        }
                        onClick={() => {
                          handleStaffClick(obj.id);
                        }}
                      >
                        <ListItem className="stafflist">
                          <Grid
                            item
                            xs={4}
                            style={{
                              color: obj.isDummy === false ? "black" : "gray",
                            }}
                          >
                            {getStaffCode(obj.id)}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{
                              cursor: "pointer",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color: obj.isDummy === false ? "black" : "gray",
                            }}
                            sx={{ ml: 2 }}
                          >
                            <Tooltip
                              title={obj.staffName}
                              placement="bottom-start"
                            >
                              {obj.staffName}
                            </Tooltip>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            style={{
                              color: obj.isDummy === false ? "black" : "gray",
                            }}
                            className="staff-department-text"
                          >
                            {obj.departmentId === null
                              ? "null"
                              : getDepartmentName(obj.departmentId)}
                          </Grid>
                          <div>
                            <IconButton
                              aria-label="more"
                              onClick={handleClick}
                              aria-haspopup="true"
                              aria-controls="long-menu"
                              className="staff-menu-icon"
                            >
                              <MoreVertIcon className="moreVertIcon-style" />
                            </IconButton>
                          </div>
                        </ListItem>
                      </Button>
                    </li>
                  </>
                ))}
              </div>
            </List>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  dispatch(EditStaffModel(true));
                  handleClose();
                }}
                className="course-menu"
                disabled={
                  algorithmStatus !== null &&
                  algorithmStatus !== "FAILED" &&
                  algorithmStatus !== "UNKNOWN" &&
                  algorithmStatus !== "NOT_CREATED"
                }
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  dispatch(DeleteStaffModel(true));
                  handleClose();
                }}
                disabled={
                  staffInFixedSlot.includes(staffId) ||
                  (algorithmStatus !== null &&
                    algorithmStatus !== "FAILED" &&
                    algorithmStatus !== "UNKNOWN" &&
                    algorithmStatus !== "NOT_CREATED")
                }
              >
                Delete
              </MenuItem>
            </Menu>
            {editStaffModel === true && <EditStaff />}
            {deleteStaffModel === true && (
              <DeleteStaffConfirmation handleRemoveStaff={handleRemoveStaff} />
            )}
          </>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default StaffList;
