import React from "react";
import {
  Grid,
  List,
  ListItem,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Divider,
  ListSubheader,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  DeleteDepartmentModel,
  EditDepartmentModel,
} from "../../../../Store/Reducers/infrastructure_menu";
import DeleteDepartmentConfirmation from "./Department Model/DeleteDepartmentConfirmation";
import EditDepartment from "./Department Model/EditDepartment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./DepartmentList.css";

// ==============================|| LHS DEPARTMENT LIST ||============================== //

const DepartmentList = ({ handleDepartmentClick, handleRemoveDepartment }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.infrastructure_menu);
  const editDepartmentModel = menu.editDepartmentModel;
  const deleteDepartmentModel = menu.deleteDepartmentModel;
  const departmentList = menu.departmentList;
  const departmentId = menu.departmentId;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid container>
        <List className="department-list" subheader={<li />}>
          <ListSubheader>
            <Grid>
              <Typography
                className="name-textColor"
                sx={{ mb: 3, mt: -1.1, ml: -1 }}
              >
                <b>Department Name</b>
              </Typography>
            </Grid>
          </ListSubheader>
          <Divider />
          <div className="list-container department-height">
            {departmentList.map((obj) => (
              <li>
                <Button
                  className={
                    departmentId === obj.id
                      ? "department-content--button--selected department-content--button"
                      : "department-content--button"
                  }
                  onClick={(event) => {
                    handleDepartmentClick(obj.id);
                  }}
                >
                  <ListItem className="department-list department-listItem">
                    <Grid
                      item
                      xs={10}
                      style={{
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {obj.departmentName}
                    </Grid>

                    <IconButton
                      aria-label="more"
                      onClick={(event) => {
                        handleClick(event);
                      }}
                      aria-haspopup="true"
                      aria-controls="long-menu"
                      className="department-menu-icon"
                    >
                      <MoreVertIcon className="moreVertIcon-style" />
                    </IconButton>
                  </ListItem>
                </Button>
              </li>
            ))}
          </div>
        </List>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              dispatch(EditDepartmentModel(true));
              handleClose();
            }}
            className="room-menu"
            disabled={
              algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED"
            }
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(DeleteDepartmentModel(true));
              handleClose();
            }}
            disabled={
              algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED"
            }
          >
            Delete
          </MenuItem>
        </Menu>
        {editDepartmentModel === true && <EditDepartment />}

        {deleteDepartmentModel === true && (
          <DeleteDepartmentConfirmation
            handleRemoveDepartment={handleRemoveDepartment}
          />
        )}
      </Grid>
    </>
  );
};
export default DepartmentList;
