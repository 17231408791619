import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { EditCourseModel } from "../../../../Store/Reducers/course_menu";
import CourseService from "../../../../Services/CourseService";
import { AddCourseList } from "../../../../Store/Reducers/course_menu";
import "./CourseModel.css";

// ==============================|| EDIT COURSE MODEL ||============================== //

const EditCourse = () => {
  const menu = useSelector((state) => state.course_menu);
  const editCourseModel = menu.editCourseModel;
  const courseList = menu.courseList;
  const courseId = menu.courseId;
  const courseInfo = menu.courseInfo;

  const dispatch = useDispatch();

  let modalOpen = false;
  editCourseModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const [courseCodeDetails, setCourseCodeDetails] = useState(courseInfo);
  const [courseNameDetails, setCourseNameDetails] = useState(courseInfo);
  const [courseHoursDetails, setCourseHoursDetails] = useState(courseInfo);
  const [courseTypeNameDetails, setCourseTypeNameDetails] =
    useState(courseInfo);
  const [editCourseCode, setEditCourseCode] = useState(courseList.courseCode);
  const [editCourseName, setEditCourseName] = useState(courseList.courseName);
  const [editCourseHours, setEditCourseHours] = useState(
    courseList.courseHours,
  );
  const [editCourseTypeName, setEditCourseTypeName] = useState(
    courseList.courseTypeName,
  );
  const [handleBackendError, setBackendError] = useState("");
  const [editCourse, setEditCourse] = useState(false);

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(EditCourseModel(false));
  };

  // Function used to edit the course code
  const handleEditCourseCode = (event) => {
    setEditCourseCode(event.target.value);
    setCourseCodeDetails(event.target.value);
  };

  // Function used to edit the course name
  const handleEditCourseName = (event) => {
    setEditCourseName(event.target.value);
    setCourseNameDetails(event.target.value);
  };

  // Function used to edit the course hours
  const handleEditCourseHours = (event) => {
    setEditCourseHours(event.target.value);
    setCourseHoursDetails(event.target.value);
  };

  // Function used to edit the course type
  const handleEditCourseTypeName = (event) => {
    setEditCourseTypeName(event.target.value);
    setCourseTypeNameDetails(event.target.value);
  };

  // Function used to update the Course List
  const changeCourse = (
    editCourseCode,
    editCourseName,
    editCourseHours,
    editCourseTypeName,
  ) => {
    const updatedCourseData = courseList.map((obj) => {
      if (obj.id === courseId) {
        return {
          ...obj,
          courseCode:
            editCourseCode === undefined
              ? courseInfo.courseCode
              : editCourseCode.trim(),
          courseName:
            editCourseName === undefined
              ? courseInfo.courseName
              : editCourseName.trim(),
          courseHours:
            editCourseHours === undefined
              ? courseInfo.courseHours
              : editCourseHours,
          courseTypeName:
            editCourseTypeName === undefined
              ? courseInfo.courseTypeName
              : editCourseTypeName,
        };
      }
      return obj;
    });
    dispatch(AddCourseList(updatedCourseData));
  };

  // Function used to edit the course
  const handleEdit = (event) => {
    let data = {
      courseCode:
        editCourseCode === undefined
          ? courseInfo.courseCode
          : editCourseCode.trim(),
      courseName:
        editCourseName === undefined
          ? courseInfo.courseName
          : editCourseName.trim(),
      courseHours:
        editCourseHours === undefined
          ? courseInfo.courseHours
          : editCourseHours,
      courseTypeName:
        editCourseTypeName === undefined
          ? courseInfo.courseTypeName
          : editCourseTypeName,
    };
    CourseService.editCourse(courseId, data)
      .then((res) => {
        changeCourse(
          editCourseCode,
          editCourseName,
          editCourseHours,
          editCourseTypeName,
        );
        handleClose();
      })
      .catch((error) => {
        setBackendError(error.response.data);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "28rem", height: "31rem", boxShadow: "none" },
        }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">Edit Course</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-course"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <br></br>
              <Typography className="model-text course-text1">
                Course Code
              </Typography>
              <OutlinedInput
                id="courseCode"
                name="courseCode"
                type="text"
                value={courseCodeDetails.courseCode || editCourseCode}
                onChange={handleEditCourseCode}
                placeholder="Enter Course Code"
                variant="outlined"
                size="small"
                className="input-style"
              />
              <br></br>

              <Typography className="model-text course-text2">
                Course Name
              </Typography>
              <OutlinedInput
                id="courseName"
                name="courseName"
                type="text"
                value={courseNameDetails.courseName || editCourseName}
                onChange={handleEditCourseName}
                placeholder="Enter Course Name"
                variant="outlined"
                size="small"
                className="input-style"
              />

              <Typography className="model-text course-text2">
                Course Hours
              </Typography>
              <OutlinedInput
                id="courseHours"
                name="courseHours"
                type="number"
                value={courseHoursDetails.courseHours || editCourseHours}
                onChange={handleEditCourseHours}
                placeholder="Enter Course Hours"
                variant="outlined"
                size="small"
                className="input-style"
              />

              <Typography className="model-text course-text2">
                Course Type
              </Typography>
              <FormControl
                className="course-radio-btn"
                onChange={(e) => handleEditCourseTypeName(e)}
              >
                <RadioGroup
                  row
                  aria-labelledby="courseTypeName"
                  value={
                    courseTypeNameDetails.courseTypeName || editCourseTypeName
                  }
                >
                  <FormControlLabel
                    name="courseTypeName"
                    value="Theory"
                    control={<Radio />}
                    label="Theory"
                  />
                  <FormControlLabel
                    name="courseTypeName"
                    value="Lab"
                    control={<Radio />}
                    label="Lab"
                  />
                </RadioGroup>
              </FormControl>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    editCourseCode === "" ||
                    editCourseName === "" ||
                    editCourseHours === "" ||
                    editCourseHours === 0 ||
                    editCourse === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={
                    editCourseCode === "" ||
                    editCourseName === "" ||
                    editCourseHours === "" ||
                    editCourseHours === 0 ||
                    editCourse === true
                  }
                  onClick={() => {
                    handleEdit();
                    setEditCourse(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-building-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
            </center>
            {handleBackendError && (
              <>
                <p className="duplicate-text">{handleBackendError}</p>
                {handleBackendError !== "" &&
                  editCourse &&
                  setEditCourse(false)}
              </>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditCourse;
