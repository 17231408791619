import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { AddCourseModel } from "../../../../Store/Reducers/course_menu";
import "./CourseModel.css";

// ==============================|| ADD NEW COURSE MODEL ||============================== //

const CourseModel = ({
  courseCode,
  courseName,
  courseHours,
  courseTypeName,
  courseDuplicate,
  setCourseDuplicate,
  handleSave,
  handleCourseCode,
  handleCourseName,
  handleCourseHours,
  handleCourseTypeName,
  handleAddCourseError,
}) => {
  const menu = useSelector((state) => state.course_menu);
  const addCourseModel = menu.addCourseModel;
  const courseList = menu.courseList;

  let modalOpen = false;
  addCourseModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const [addCourse, setAddCourse] = useState(false);

  const dispatch = useDispatch();

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    setCourseDuplicate("");
    dispatch(AddCourseModel(false));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { width: "28rem", height: "34rem" } }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">Add Course</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-course"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <br></br>
              <Typography className="model-text course-text1">
                Course Code
              </Typography>
              <OutlinedInput
                id="courseCode"
                name="courseCode"
                type="text"
                value={courseList.courseCode}
                onChange={handleCourseCode}
                placeholder="Enter Course Code"
                variant="outlined"
                size="small"
                className="input-style"
              />

              <Typography className="model-text course-text2">
                Course Name
              </Typography>
              <OutlinedInput
                id="courseName"
                name="courseName"
                type="text"
                value={courseList.courseName}
                onChange={handleCourseName}
                placeholder="Enter Course Name"
                variant="outlined"
                size="small"
                className="input-style"
              />

              <Typography className="model-text course-text2">
                Course Hours
              </Typography>
              <OutlinedInput
                id="courseHours"
                name="courseHours"
                type="number"
                value={courseList.courseHours}
                onChange={handleCourseHours}
                placeholder="Enter Course Hours"
                variant="outlined"
                size="small"
                className="input-style"
              />

              <Typography className="model-text course-text2">
                Course Type
              </Typography>
              <FormControl
                className="course-radio-btn"
                onChange={(e) => handleCourseTypeName(e)}
              >
                <RadioGroup
                  row
                  aria-labelledby="courseTypeName"
                  value={courseTypeName}
                >
                  <FormControlLabel
                    name="courseTypeName"
                    value="Theory"
                    control={<Radio checked={courseTypeName === "Theory"} />}
                    label="Theory"
                  />
                  <FormControlLabel
                    name="courseTypeName"
                    value="Lab"
                    control={<Radio checked={courseTypeName === "Lab"} />}
                    label="Lab"
                  />
                </RadioGroup>
              </FormControl>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    courseCode.courseCode === "" ||
                    courseName.courseName === "" ||
                    courseHours.courseHours === "" ||
                    courseHours.courseHours === 0 ||
                    addCourse === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={
                    courseCode.courseCode === "" ||
                    courseName.courseName === "" ||
                    courseHours.courseHours === "" ||
                    courseHours.courseHours === 0 ||
                    addCourse === true
                  }
                  onClick={() => {
                    handleSave();
                    setAddCourse(true);
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-building-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {courseDuplicate && (
                <>
                  <p className="duplicate-text">{courseDuplicate}</p>
                  {courseDuplicate !== "" && addCourse && setAddCourse(false)}
                </>
              )}
              {handleAddCourseError && (
                <>
                  <p className="duplicate-text">{handleAddCourseError}</p>
                  {handleAddCourseError !== "" &&
                    addCourse &&
                    setAddCourse(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CourseModel;
