import React from "react";
import {
  Alert,
  Stack,
  AlertTitle,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import "../Timetable.css";

// ==============================|| ALGORITHM WARNINGS ||============================== //

const AlgorithmWarnings = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const menu = useSelector((state) => state.menu);
  const warningsList = menu.warningsList;
  const algorithmStatus = menu.algorithmStatus;
  const algorithmFailedError = menu.algorithmFailedError;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {(algorithmStatus === "NOT_CREATED" || algorithmStatus === null) &&
        warningsList.length === 0 && (
          <Alert severity="info" className="import-warning">
            <AlertTitle className="import-warning__alert-title">
              <b>Info : Warnings Cleared Successfully</b>
            </AlertTitle>
          </Alert>
        )}

      {algorithmStatus === "CREATED" ||
        (algorithmStatus === "RUNNING" && (
          <Alert severity="info" className="import-warning">
            <AlertTitle className="import-warning__alert-title">
              <b>Info: Please wait, the algorithm is running</b>
            </AlertTitle>
          </Alert>
        ))}

      {algorithmStatus === "COMPLETED" && (
        <Alert severity="success" className="import-warning">
          <AlertTitle className="import-warning__alert-title">
            <b>Success: The algorithm has completed</b>
          </AlertTitle>
        </Alert>
      )}
      {(algorithmStatus === "FAILED" || algorithmStatus === "UNKNOWN") &&
        warningsList.length === 0 && (
          <>
            <Alert severity="error" className="import-warning">
              <Stack direction="row">
                <AlertTitle className="import-warning__alert-title">
                  <b>Error: The Algorithm has failed</b>
                </AlertTitle>

                <Stack direction="row" className="view_icon_failed">
                  <IconButton
                    sx={{ borderRadius: "5%" }}
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    <VisibilityIcon
                      className="warnings-view-icon"
                      sx={{ paddingRight: "5px" }}
                    />
                    <Typography className="view_title">View</Typography>
                  </IconButton>
                </Stack>
              </Stack>
            </Alert>
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                  sx: {
                    maxWidth: "38rem",
                    width: "100%",
                    height: "50%",
                    minHeight: "27rem",
                  },
                }}
              >
                <Stack direction="row">
                  <DialogTitle id="responsive-dialog-title">
                    <b>{"Failed Info"}</b>
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: "#64748B",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Divider />
                <DialogContent>
                  <DialogContentText className="errorDialog-style">
                    {algorithmFailedError}
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            </div>
          </>
        )}
    </>
  );
};

export default AlgorithmWarnings;
