// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  semesterDetails: [],
  loginDetails: [],
  selectedSemesterId: [],
  semesterType: [],
  academicYears: [],
  semesterLoader: false,
};

// ==============================|| SLICE - LOGIN MENU ||============================== //

const login_menu = createSlice({
  name: "login_menu",
  initialState,
  reducers: {
    SemesterDetails(state, action) {
      state.semesterDetails = action.payload;
    },
    LoginDetails(state, action) {
      state.loginDetails = action.payload;
    },
    SelectedSemesterId(state, action) {
      state.selectedSemesterId = action.payload;
    },
    SemesterType(state, action) {
      state.semesterType = action.payload;
    },
    AcademicYears(state, action) {
      state.academicYears = action.payload;
    },
    SemesterLoader(state, action) {
      state.semesterLoader = action.payload;
    },
  },
});

export default login_menu.reducer;

export const {
  SemesterDetails,
  LoginDetails,
  SelectedSemesterId,
  SemesterType,
  AcademicYears,
  SemesterLoader,
} = login_menu.actions;
