/* eslint-disable array-callback-return */
import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import {
  AddClassCombinedList,
  EditCombinedClassModel,
} from "../../../../Store/Reducers/combined_class_menu";
import DoneIcon from "@mui/icons-material/Done";
import CombinedClassService from "../../../../Services/CombinedClassService";

// ==============================|| EDIT COMBINED CLASS MODEL ||============================== //

const EditCombinedClass = () => {
  const menu = useSelector((state) => state.combined_class_menu);
  const editCombinedClassModel = menu.editCombinedClassModel;
  const classCombinedList = menu.classCombinedList;
  const combinedId = menu.combinedId;
  const combinedInfo = menu.combinedInfo;

  const building_menu = useSelector((state) => state.infrastructure_menu);
  const buildingList = building_menu.buildingList;
  const addNewRoom = building_menu.addNewRoom;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const dispatch = useDispatch();

  let modalOpen = false;
  editCombinedClassModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const [classCombinedName, setClassCombinedName] = useState(combinedInfo);
  const [classCombinedStaff, setClassCombinedStaff] = useState(combinedInfo);
  const [classCombinedRooms, setClassCombinedRooms] = useState(combinedInfo);
  const [editClassName, setEditClassName] = useState(
    classCombinedList.combinedClassName,
  );
  const [editStaff, setEditStaff] = useState(
    classCombinedList.numberOfStaffRequiredAtOneTime,
  );
  const [editRooms, setEditRooms] = useState(
    classCombinedList.numberOfRoomsRequired,
  );
  const [handleError, setHandleError] = useState("");
  const [editCombinedClass, setEditCombinedClass] = useState(false);

  const combinedClassDetailsCourse = combinedInfo;

  // Getting possible room from combined class
  let selectedPossibleRoom = [];
  addNewRoom
    .filter((val) => combinedInfo.possibleRooms.includes(val.id))
    .map((res) => {
      selectedPossibleRoom.push(res.roomName);
    });

  const [editPossibleRooms, setEditPossibleRooms] =
    useState(selectedPossibleRoom);

  // To display the code and name of the Course
  let course;
  courseList
    .filter((cid) => combinedClassDetailsCourse.courseId === cid.id)
    .map((res) => {
      course = `${res.courseName}`;
    });

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(EditCombinedClassModel(false));
  };

  // Function used to store possible rooms
  const handleChange = (event) => {
    setEditPossibleRooms(event.target.value);
  };

  // Function used to handle number of rooms required
  const handleNumberOfRoomsRequired = (event) => {
    setClassCombinedRooms(event.target.value);
    setEditRooms(event.target.value);
  };

  // Function used to handle number of staff required
  const handleNumberOfStaffRequiredAtOneTime = (event) => {
    setClassCombinedStaff(event.target.value);
    setEditStaff(event.target.value);
  };

  // Function used to handle combined class name
  const handleEditCombinedClassName = (event) => {
    setEditClassName(event.target.value);
    setClassCombinedName(event.target.value);
  };

  // Retrieve the course id using course code from course list
  let selectedCourseId;
  const filteredCourses = courseList.filter(
    (course) => course.courseCode === combinedClassDetailsCourse.courseCode,
  );
  filteredCourses.map((value) => {
    selectedCourseId = value.id;
  });

  // Function used to store building data
  const buildingHeader = [];
  buildingList.map((build) => {
    buildingHeader.push(build);
  });

  // Function used to store room data
  const roomList = [];
  addNewRoom.map((room) => {
    roomList.push(room);
  });

  // Function used to display menu items
  const menuItems = buildingHeader.map((build, index) => {
    const roomsForBuilding = roomList.filter(
      (room) => room.buildingId === build.id,
    );

    return [
      <MenuItem
        key={`header-${build.id}`}
        disabled
        sx={{ color: "black", fontWeight: "bold" }}
      >
        {build.buildingName}
      </MenuItem>,
      ...roomsForBuilding.map((res) => (
        <MenuItem key={res.roomName} value={res.roomName}>
          {res.roomName}
          {editPossibleRooms.includes(res.roomName) && (
            <DoneIcon sx={{ marginLeft: "65%" }} />
          )}
        </MenuItem>
      )),
    ];
  });

  let editPossibleRoomsId = [];
  editPossibleRooms.forEach((possible) => {
    addNewRoom
      .filter((room) => room.roomName === possible)
      .forEach((res) => {
        editPossibleRoomsId.push(res.id);
      });
  });

  // Function used to update the Combined class List
  const changeCombinedClass = (
    editClassName,
    editStaff,
    editPossibleRoomsId,
    editRooms,
  ) => {
    const updatedCombinedClassData = classCombinedList.map((obj) => {
      if (obj.id === combinedId) {
        return {
          ...obj,
          combinedClassName:
            editClassName === undefined
              ? combinedInfo.combinedClassName
              : editClassName.trim(),
          courseId: combinedClassDetailsCourse.courseId,
          numberOfStaffRequiredAtOneTime:
            editStaff === undefined
              ? combinedInfo.numberOfStaffRequiredAtOneTime
              : editStaff,
          possibleRooms: editPossibleRoomsId,
          numberOfRoomsRequired:
            editRooms === undefined
              ? combinedInfo.numberOfRoomsRequired
              : editRooms,
        };
      }
      return obj;
    });
    dispatch(AddClassCombinedList(updatedCombinedClassData));
  };

  // Function used to edit the combined class
  const handleEdit = (event) => {
    setHandleError("");
    let data = {
      combinedClassName:
        editClassName === undefined
          ? combinedInfo.combinedClassName
          : editClassName.trim(),
      courseId: combinedClassDetailsCourse.courseId,
      combinedResourceDTO: {
        numberOfStaffRequiredAtOneTime:
          editStaff === undefined
            ? combinedInfo.numberOfStaffRequiredAtOneTime
            : editStaff,
        possibleRooms: editPossibleRoomsId,
        numberOfRoomsRequired:
          editRooms === undefined
            ? combinedInfo.numberOfRoomsRequired
            : editRooms,
      },
    };
    CombinedClassService.editCombinedClass(combinedId, data)
      .then((res) => {
        changeCombinedClass(
          editClassName,
          editStaff,
          editPossibleRoomsId,
          editRooms,
        );
        handleClose();
      })
      .catch((error) => {
        setHandleError(error.response.data);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "30rem", height: "39rem", boxShadow: "none" },
        }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            Edit Combined Class <br></br> {course}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-comclass-assignment"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <Typography className="model-text comclass-text2">
                Combined Class Name
              </Typography>
              <OutlinedInput
                id="className"
                name="className"
                type="text"
                value={classCombinedName.combinedClassName || editClassName}
                onChange={handleEditCombinedClassName}
                placeholder="Enter Combined Class Name"
                variant="outlined"
                size="small"
                className="input-style"
              />
              <br></br>
              <br></br>
              <Typography className="model-text course-assignment-text2">
                Staff Required
              </Typography>
              <OutlinedInput
                id="numberOfStaffRequiredAtOneTime"
                name="numberOfStaffRequiredAtOneTime"
                type="number"
                value={
                  classCombinedStaff.numberOfStaffRequiredAtOneTime || editStaff
                }
                onChange={handleNumberOfStaffRequiredAtOneTime}
                placeholder="Enter Number of Staff Required"
                variant="outlined"
                size="small"
                className="input-style"
                required
              />
              <br></br>
              <br></br>
              <Typography className="model-text course-assignment-text4">
                Number of Rooms Required
              </Typography>
              <OutlinedInput
                id="numberOfRoomsRequired"
                name="numberOfRoomsRequired"
                type="number"
                value={classCombinedRooms.numberOfRoomsRequired}
                onChange={handleNumberOfRoomsRequired}
                placeholder="Enter Number of Rooms Required"
                variant="outlined"
                size="small"
                className="input-style"
                required
              />
              <br></br>
              <br></br>
              <Typography className="model-text course-assignment-text3">
                Possible Rooms
              </Typography>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-multiple-name-label"
                  id="possibleRooms"
                  multiple
                  value={editPossibleRooms}
                  onChange={handleChange}
                  className="possible-rooms-dropdown"
                  required
                >
                  {menuItems}
                </Select>
              </FormControl>
              <br></br>
              <br></br>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    selectedCourseId === "" ||
                    classCombinedName.combinedClassName === "" ||
                    editClassName === "" ||
                    classCombinedStaff.numberOfStaffRequiredAtOneTime === 0 ||
                    classCombinedStaff.numberOfStaffRequiredAtOneTime === "" ||
                    editStaff === 0 ||
                    editStaff === "" ||
                    classCombinedRooms.numberOfRoomsRequired === 0 ||
                    classCombinedRooms.numberOfRoomsRequired === "" ||
                    editPossibleRooms.length === 0 ||
                    editCombinedClass === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  onClick={() => {
                    handleEdit();
                    setEditCombinedClass(true);
                  }}
                  disabled={
                    selectedCourseId === "" ||
                    classCombinedName.combinedClassName === "" ||
                    editClassName === "" ||
                    classCombinedStaff.numberOfStaffRequiredAtOneTime === 0 ||
                    classCombinedStaff.numberOfStaffRequiredAtOneTime === "" ||
                    editStaff === 0 ||
                    editStaff === "" ||
                    classCombinedRooms.numberOfRoomsRequired === 0 ||
                    classCombinedRooms.numberOfRoomsRequired === "" ||
                    editPossibleRooms.length === 0 ||
                    editCombinedClass === true
                  }
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-class-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
            </center>
            {handleError && (
              <>
                <p className="duplicate-text">{handleError}</p>
                {handleError !== "" &&
                  editCombinedClass &&
                  setEditCombinedClass(false)}
              </>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditCombinedClass;
