/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
  ListSubheader,
  Menu,
  MenuItem,
  Chip,
  Tooltip,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  EditStaffCourseModel,
  DeleteStaffCourseModel,
} from "../../../Store/Reducers/staff_menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditStaffCourseAssignment from "./Staff Models/EditStaffCourseAssignment";
import DeleteStaffCourseAssignment from "./Staff Models/DeleteStaffCourseAssignment";
import "./StaffCourseAssignmentList.css";
import PossibleRoomsModelStaffList from "./Staff Models/PossibleRoomsModelStaffList";

// ==============================|| DISPLAYING STAFF COURSE ASSIGNMENT LIST ||============================== //

const StaffCourseAssignmentList = ({
  handleStaffCourseClick,
  handleRemoveStaffCourse,
  retrieveStaffCourseList,
}) => {
  useEffect(() => {
    retrieveStaffCourseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();
  const menu = useSelector((state) => state.staff_menu);
  const editStaffCourseModel = menu.editStaffCourseModel;
  const deleteStaffCourseModel = menu.deleteStaffCourseModel;
  const staffId = menu.staffId;
  const staffCourseAssignment = menu.staffCourseAssignment;
  const staffCourseId = menu.staffCourseId;
  const staffCourseInFixedSlot = menu.staffCourseInFixedSlot;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;
  const classCourseAssignment = class_menu.classCourseAssignmentAll;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function used for getting class course name
  const getClassCourseName = (id, sid) => {
    const classCourse = classCourseAssignment.find((res) => res.id === id);
    const course = courseList.find((res) => res.id === classCourse.courseId);
    const classes = classList.find((res) => res.id === classCourse.classId);
    const maxLengthCode = 15;

    if (course && staffCourseInFixedSlot.includes(sid)) {
      return (
        <span>
          <Tooltip
            title="Can't Edit and Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip
            title={`${classes.className} - ${course.courseCode} : ${course.courseName}`}
            placement="bottom-start"
          >
            <span>
              {course.courseName.length > maxLengthCode
                ? `${course.courseName.substring(0, maxLengthCode)}...`
                : course.courseName}
            </span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip
          title={`${classes.className} - ${course.courseCode} : ${course.courseName}`}
          placement="bottom-start"
        >
          <span>
            {course.courseName.length > maxLengthCode
              ? `${course.courseName.substring(0, maxLengthCode)}...`
              : course.courseName}
          </span>
        </Tooltip>
      );
    }
  };

  // Function used for getting combined class name
  const getCombinedCourseName = (id, sid) => {
    const comClass = classCombinedList.find((res) => res.id === id);
    const course = courseList.find((res) => res.id === comClass.courseId);

    const maxLengthCode = 15;

    if (course && staffCourseInFixedSlot.includes(sid)) {
      return (
        <span>
          <Tooltip
            title="Can't Edit and Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip
            title={`${comClass.combinedClassName} - ${course.courseCode} : ${course.courseName}`}
            placement="bottom-start"
          >
            <span>
              {course.courseName.length > maxLengthCode
                ? `${course.courseName.substring(0, maxLengthCode)}...`
                : course.courseName}
            </span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip
          title={`${comClass.combinedClassName} - ${course.courseCode} : ${course.courseName}`}
          placement="bottom-start"
        >
          <span>
            {course.courseName.length > maxLengthCode
              ? `${course.courseName.substring(0, maxLengthCode)}...`
              : course.courseName}
          </span>
        </Tooltip>
      );
    }
  };

  // Function used for getting class course type
  let courseType;
  const getClassCourseType = (id) => {
    classCourseAssignment
      .filter((clsCourse) => clsCourse.id === id)
      .map((resClassCourse) => {
        courseList
          .filter((course) => course.id === resClassCourse.courseId)
          .map((res) => {
            courseType = res.courseTypeName;
          });
      });
    return courseType;
  };

  // Function used for getting combined course type
  const getCombinedCourseType = (id) => {
    classCombinedList
      .filter((clsCourse) => clsCourse.id === id)
      .map((resClassCourse) => {
        courseList
          .filter((course) => course.id === resClassCourse.courseId)
          .map((res) => {
            courseType = res.courseTypeName;
          });
      });
    return courseType;
  };

  // Function used for getting class course room name
  let possibleRoomId;
  const getClassCourseRoom = (id) => {
    classCourseAssignment
      .filter((clsCourse) => clsCourse.id === id)
      .map((resClassCourse) => {
        possibleRoomId = resClassCourse.possibleRooms;
      });

    return possibleRoomId;
  };

  // Function used for getting combined class room name
  const getCombinedCourseRoom = (id) => {
    classCombinedList
      .filter((clsCourse) => clsCourse.id === id)
      .map((resClassCourse) => {
        possibleRoomId = resClassCourse.possibleRooms;
      });
    return possibleRoomId;
  };

  // Function used for getting total teaching hours
  const getTotalTeachingHours = () => {
    let totalStaffHours = 0;

    staffCourseAssignment
      .filter((staff) => staff.staffId === staffId)
      .forEach((res) => {
        totalStaffHours = Number(totalStaffHours) + Number(res.teachingHours);
      });
    return totalStaffHours;
  };

  return (
    <>
      <Grid>
        <List className="staffcourse-list-style" subheader={<li />}>
          <ListSubheader>
            <Grid container sx={{ mt: 2 }}>
              <Grid xs={2.4}>
                <Typography className="staff-course-header">
                  Course Name
                </Typography>
              </Grid>
              <Grid xs={2.2}>
                <Typography className="staff-course-header">
                  Teaching Hours
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography className="staff-course-header">
                  Theory/Lab
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography className="staff-course-header">
                  Continous <br></br>Preference
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography className="staff-course-header">
                  Possible Rooms
                </Typography>
              </Grid>
            </Grid>
          </ListSubheader>
          <Divider className="staffCourse-divider" />
          <div className="list-container staffCourseList-height">
            {staffCourseAssignment.map((res) => (
              <li className="comclass-list list-width">
                <Button
                  className={
                    staffCourseId === res.id
                      ? "staff-course-content--button--selected staff-course-content--button"
                      : "staff-course-content--button"
                  }
                  onClick={(event) => {
                    handleStaffCourseClick(res.id);
                  }}
                >
                  <ListItem>
                    <Grid item xs={2} sx={{ ml: 1.5 }}>
                      {res.classCourseAssignmentId !== null ? (
                        <Chip
                          label={getClassCourseName(
                            res.classCourseAssignmentId,
                            res.id,
                          )}
                          className="custom-chip-notcombined chip-width"
                        />
                      ) : (
                        <Chip
                          label={getCombinedCourseName(
                            res.combinedClassId,
                            res.id,
                          )}
                          className="custom-chip-iscombined chip-width"
                        />
                      )}
                    </Grid>

                    <Grid item xs={3.3} sx={{ ml: 6 }}>
                      <Stack direction="row" className="list-title-sort">
                        {res.teachingHours}
                      </Stack>
                    </Grid>
                    <Grid item xs={2.5}>
                      <Stack direction="row" className="list-title-sort">
                        <Typography className="room-header1">
                          {res.classCourseAssignmentId !== null
                            ? getClassCourseType(res.classCourseAssignmentId)
                            : getCombinedCourseType(res.combinedClassId)}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid xs={3} sx={{ ml: 1.8 }}>
                      <Typography className="room-header1">
                        {courseType === "Theory" ||
                        (courseType === "Lab" && res.teachingHours === 1)
                          ? "-"
                          : Array.isArray(res.continuousHourPreference)
                          ? res.continuousHourPreference.join(" , ")
                          : res.continuousHourPreference}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography className="room-header1">
                        {(() => {
                          const classCourseRoomValue =
                            res.classCourseAssignmentId !== null
                              ? getClassCourseRoom(res.classCourseAssignmentId)
                              : getCombinedCourseRoom(res.combinedClassId);

                          return (
                            <>
                              <Tooltip
                                title={
                                  <div className="possibleRooms-hover">
                                    <PossibleRoomsModelStaffList
                                      classCourseRoom={possibleRoomId}
                                    />
                                  </div>
                                }
                              >
                                <Typography className="possiblerooms-header">
                                  <u>
                                    {classCourseRoomValue !== null &&
                                      classCourseRoomValue.length !== 0 &&
                                      classCourseRoomValue.length}
                                  </u>
                                </Typography>
                              </Tooltip>
                            </>
                          );
                        })()}
                      </Typography>
                    </Grid>

                    <IconButton
                      aria-label="more"
                      onClick={(event) => {
                        handleClick(event);
                      }}
                      aria-haspopup="true"
                      aria-controls="long-menu"
                      className="room-menu-icon"
                    >
                      <MoreVertIcon className="moreVertIcon-style" />
                    </IconButton>
                  </ListItem>
                </Button>
              </li>
            ))}

            <Typography sx={{ marginLeft: "75%", mt: 2 }}>
              <b className="hoursText-color">Total Hours : </b>
              {getTotalTeachingHours()}
            </Typography>
          </div>
        </List>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              dispatch(EditStaffCourseModel(true));
              handleClose();
            }}
            disabled={
              staffCourseInFixedSlot.includes(staffCourseId) ||
              (algorithmStatus !== null &&
                algorithmStatus !== "FAILED" &&
                algorithmStatus !== "UNKNOWN" &&
                algorithmStatus !== "NOT_CREATED")
            }
            className="course-menu"
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(DeleteStaffCourseModel(true));
              handleClose();
            }}
            disabled={
              staffCourseInFixedSlot.includes(staffCourseId) ||
              (algorithmStatus !== null &&
                algorithmStatus !== "FAILED" &&
                algorithmStatus !== "UNKNOWN" &&
                algorithmStatus !== "NOT_CREATED")
            }
          >
            Delete
          </MenuItem>
        </Menu>
        {editStaffCourseModel === true && <EditStaffCourseAssignment />}
        {deleteStaffCourseModel === true && (
          <DeleteStaffCourseAssignment
            handleRemoveStaffCourse={handleRemoveStaffCourse}
          />
        )}
      </Grid>
    </>
  );
};

export default StaffCourseAssignmentList;
