import React from "react";
import { Grid, Stack, Typography, Tooltip, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch, useSelector } from "react-redux";
import AddClassInCombined from "./CombinedClass Model/AddClassInCombined";
import {
  AddClassCombinedModel,
  DeleteAllClassInCombined,
} from "../../../Store/Reducers/combined_class_menu";
import ClassCombinedList from "./ClassCombinedList";
import DeleteAllClassCombined from "./CombinedClass Model/DeleteAllClassCombined";
import "./CombinedClassContent.css";

// ==============================|| RHS COMBINED CLASS CONTENT ||============================== //

const CombinedClassContent = ({ retrieveCombinedClassList }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.combined_class_menu);
  const addClassCombinedModel = menu.addClassCombinedModel;
  const deleteAllClassInCombined = menu.deleteAllClassInCombined;
  const classCombinedList = menu.classCombinedList;
  const combinedId = menu.combinedId;
  const combinedInfo = menu.combinedInfo;

  const class_menu = useSelector((state) => state.class_menu);
  const classCourseAssignment = class_menu.classCourseAssignmentAll;
  const classList = class_menu.classList;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const addNewRoom = infrastructure_menu.addNewRoom;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  // Function used to retrieve the course code
  const getCourseCode = (id) => {
    const course = courseList.find((code) => code.id === id);
    if (course) {
      return course.courseCode;
    }
  };

  // Function used to retrieve the course name
  const getCourseName = (id) => {
    const course = courseList.find((code) => code.id === id);
    if (course) {
      return course.courseName;
    }
  };

  // Function used to retrieve the number of hours
  const getNumberOfHours = (id) => {
    const course = courseList.find((code) => code.id === id);
    if (course) {
      return course.courseHours;
    }
  };

  //Function to get room name from room id
  const getPossibleRoomsName = (rooms) => {
    if (rooms && rooms.length > 0) {
      let matchingRoomNames = [];
      classCourseAssignment.forEach((cls) => {
        matchingRoomNames = rooms.map((roomId) =>
          addNewRoom
            .filter((room) => room.id === roomId)
            .map((matchingRoom) => matchingRoom.roomName),
        );
      });
      return matchingRoomNames.join(" , ");
    } else {
      return ""; // Return empty string if rooms is undefined or empty
    }
  };

  // Disable Delete All classes icon when list is empty
  let clscrsId = false;
  const combinedItem = classCombinedList.find(
    (clsdata) => clsdata.id === combinedId,
  );

  if (combinedItem && combinedItem.classCourseAssignmentId === null) {
    clscrsId = true;
  }

  //Function to get the Class Name and Course Name and combined it
  const getAllClassName = (id, clscrsid) => {
    const combinedArray = [];

    const assignment = classCourseAssignment.find(
      (data) => data.id === clscrsid,
    );

    if (assignment && assignment.courseId === id) {
      classList.forEach((cls, index) => {
        if (cls.id === assignment.classId) {
          const course = courseList.find(
            (course) => course.id === assignment.courseId,
          );

          if (course) {
            const combined = {
              id: clscrsid,
              name: `${cls.className} - ${course.courseName}`,
            };
            combinedArray.push(combined);
          }
        }
      });
    }

    return combinedArray;
  };

  // Display Combined names in Menu box
  const combinedNames =
    classCombinedList
      .filter((clsid) => clsid.id === combinedId)
      .map((clsres) => {
        const allCombinedNames = [];

        classCourseAssignment
          .filter((clscrs) => clscrs.courseId === clsres.courseId)
          .forEach((res) => {
            getAllClassName(res.courseId, res.id).forEach((combinedName) => {
              allCombinedNames.push(combinedName);
            });
          });

        return allCombinedNames;
      })[0] || [];

  return (
    <>
      {classCombinedList
        .filter((comb) => comb.id === combinedId)
        .map((res) => (
          <div>
            <Grid>
              <Grid className="combinedClass-details-display">
                <Stack direction={"col"}>
                  <Typography className="combined-details-header combined-text1">
                    Course Code :{" "}
                  </Typography>
                  <Typography className="combined-details-text combined-text1">
                    {getCourseCode(res.courseId)}
                  </Typography>
                </Stack>
                <Stack direction={"col"}>
                  <Typography className="combined-details-header combined-text2">
                    CourseName :{" "}
                  </Typography>
                  <Typography className="combined-details-text combined-text2">
                    {getCourseName(res.courseId)}
                  </Typography>
                </Stack>
                <Stack direction={"col"}>
                  <Typography className="combined-details-header combined-text3">
                    Combined Class Name :{" "}
                  </Typography>
                  <Typography className="combined-details-text combined-text3">
                    {res.combinedClassName}
                  </Typography>
                </Stack>
                <Stack direction={"col"}>
                  <Typography className="combined-details-header combined-text3">
                    Number of hours per week :{" "}
                  </Typography>
                  <Typography className="combined-details-text combined-text3">
                    {getNumberOfHours(combinedInfo.courseId)}
                  </Typography>
                </Stack>
                <Stack direction={"col"}>
                  <Typography className="combined-details-header combined-text3">
                    Possible Rooms :{" "}
                  </Typography>
                  <Typography className="combined-details-text combined-text3">
                    {getPossibleRoomsName(res.possibleRooms)}
                  </Typography>
                </Stack>
                <Stack direction={"col"}>
                  <Typography className="combined-details-header combined-text3">
                    Number Of Staff required at one time:{" "}
                  </Typography>
                  <Typography className="combined-details-text combined-text3">
                    {res.numberOfStaffRequiredAtOneTime}
                  </Typography>
                </Stack>
                <Stack direction={"col"}>
                  <Typography className="combined-details-header combined-text3">
                    Number Of Students :
                  </Typography>
                  <Typography className="combined-details-text combined-text3">
                    {res.numberOfStudents}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={8} className="display-add-delete">
                <Stack direction="row" mt={1}>
                  <Tooltip
                    title="Add Classes to be Combined"
                    placement="bottom-start"
                    arrow
                  >
                    <IconButton
                      className={
                        combinedNames.length === 0 ||
                        (algorithmStatus !== null &&
                          algorithmStatus !== "FAILED" &&
                          algorithmStatus !== "UNKNOWN" &&
                          algorithmStatus !== "NOT_CREATED")
                          ? "add-class-combined-button-disabled"
                          : "add-class-combined-button"
                      }
                      disabled={
                        combinedNames.length === 0 ||
                        (algorithmStatus !== null &&
                          algorithmStatus !== "FAILED" &&
                          algorithmStatus !== "UNKNOWN" &&
                          algorithmStatus !== "NOT_CREATED")
                      }
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        dispatch(AddClassCombinedModel(true));
                      }}
                    >
                      <AddIcon className="add-icon" />
                    </IconButton>
                  </Tooltip>
                  <Grid item xs={8}>
                    <Stack direction="row" mt={0}>
                      <Tooltip
                        title="Delete All Classes"
                        placement="bottom"
                        arrow
                      >
                        <IconButton
                          className={
                            clscrsId === true ||
                            (algorithmStatus !== null &&
                              algorithmStatus !== "FAILED" &&
                              algorithmStatus !== "UNKNOWN" &&
                              algorithmStatus !== "NOT_CREATED")
                              ? "add-class-combined-button-disabled"
                              : "add-class-combined-button"
                          }
                          type="submit"
                          variant="contained"
                          disabled={
                            clscrsId === true ||
                            (algorithmStatus !== null &&
                              algorithmStatus !== "FAILED" &&
                              algorithmStatus !== "UNKNOWN" &&
                              algorithmStatus !== "NOT_CREATED")
                          }
                          onClick={() => {
                            dispatch(DeleteAllClassInCombined(true));
                            retrieveCombinedClassList();
                          }}
                        >
                          <DeleteForeverIcon
                            className={
                              clscrsId === true
                                ? "add-combined-class-icon-disabled deleteAllCombinedClass"
                                : "add-combined-class-icon deleteAllCombinedClass"
                            }
                            disabled={clscrsId === true}
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Grid>
                </Stack>
              </Grid>

              <div className="classCombinedList-style">
                <ClassCombinedList
                  retrieveCombinedClassList={retrieveCombinedClassList}
                />
              </div>
              {addClassCombinedModel === true && (
                <AddClassInCombined
                  retrieveCombinedClassList={retrieveCombinedClassList}
                  combinedNames={combinedNames}
                />
              )}
              {deleteAllClassInCombined === true && (
                <DeleteAllClassCombined
                  retrieveCombinedClassList={retrieveCombinedClassList}
                />
              )}
            </Grid>
          </div>
        ))}
    </>
  );
};

export default CombinedClassContent;
