import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import CombinedClassService from "../../../../Services/CombinedClassService";
import { DeleteAllClassInCombined } from "../../../../Store/Reducers/combined_class_menu";
import { AddCombinedClassList } from "../../../../Store/Reducers/combined_class_menu";

// ==============================|| DELETE COMBINED CLASS CONFIRMATION MODEL ||============================== //

const DeleteAllClassCombined = ({ retrieveCombinedClassList }) => {
  const menu = useSelector((state) => state.combined_class_menu);
  const deleteAllClassInCombined = menu.deleteAllClassInCombined;
  const combinedClassList = menu.combinedClassList;
  const combinedId = menu.combinedId;

  let modalOpen = false;
  deleteAllClassInCombined === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const [deleteAllClassCombined, setDeleteAllClassCombined] = useState(false);

  const dispatch = useDispatch();

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(DeleteAllClassInCombined(false));
  };

  // Function used to delete a particular combined class from list
  const handleDelete = () => {
    let data = {
      combinedClassId: combinedId,
      classCourseAssignmentId: [],
    };

    CombinedClassService.addClassCombined(data).then((res) => {
      dispatch(AddCombinedClassList([...combinedClassList, res.data]));

      retrieveCombinedClassList();
    });

    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "23.7rem", height: "13rem", boxShadow: "none" },
        }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            {" Clear Confirmation"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="model-close-icon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText className="dialog-style" align="center">
            Are you sure you want to Delete all Classes ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button
            disabled={deleteAllClassCombined === true}
            className={
              deleteAllClassCombined === true
                ? "yes-btn-delete-all-disabled"
                : "yes-btn-delete-all"
            }
            onClick={() => {
              handleDelete();
              setDeleteAllClassCombined(true);
            }}
            variant="outlined"
          >
            Yes
          </Button>
          <Button
            className="no-btn-delete-all"
            onClick={handleClose}
            variant="outlined"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteAllClassCombined;
