/* eslint-disable array-callback-return */
import React from "react";
import {
  Grid,
  Stack,
  Typography,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { AddStaffCourseModel } from "../../../Store/Reducers/staff_menu";
import AddStaffCourseAssignment from "./Staff Models/AddStaffCourseAssignment";
import StaffCourseAssignmentList from "./StaffCourseAssignmentList";
import "./Staff.css";
import Loader from "../../Loader/Loader";

// ==============================|| RHS STAFF CONTENT ||============================== //

const StaffContent = ({
  handleStaffCourseClick,
  handleRemoveStaffCourse,
  retrieveStaffCourseList,
}) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.staff_menu);
  const addStaffCourseModel = menu.addStaffCourseModel;
  const staffList = menu.staffList;
  const staffId = menu.staffId;
  const isStaffCourseStatusCode = menu.isStaffCourseStatusCode;
  const timePreference = menu.timePreference;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const departmentList = infrastructure_menu.departmentList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const styles = {
    label: {
      display: "flex",
      justifyContent: "flex-start",
    },
    spanContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
  };

  let staffCode,
    staffName,
    departmentId,
    selectedStartTime = [],
    selectedEndTime = [];
  staffList
    .filter((obj) => obj.id === staffId)
    .map((val) => {
      // Storing staff code
      staffCode = val.staffCode;

      // Storing staff name
      staffName = val.staffName;

      // Storing staff department
      if (val.departmentId === null) {
        departmentId = "null";
      } else {
        departmentList
          .filter((ob) => ob.id === val.departmentId)
          .map((val) => (departmentId = val.departmentName));
      }

      // Storing staff time preference
      timePreference
        .filter((time1) =>
          val.timePreferenceIds.some((time2) => time2 === time1.id),
        )
        .forEach((res) => {
          selectedStartTime.push(res.startTime);
          selectedEndTime.push(res.endTime);
        });
    });

  // Getting formatted staff time prefernece
  const formattedTimeRanges = [];

  for (let i = 0; i < selectedStartTime.length; i++) {
    const startTime = selectedStartTime[i];
    const endTime = selectedEndTime[i];
    const formattedRange = `${startTime} - ${endTime}`;
    formattedTimeRanges.push(formattedRange);
  }
  const formattedTimeRangesString = formattedTimeRanges.join(", ");

  return (
    <>
      {isStaffCourseStatusCode === false ? (
        <Loader />
      ) : (
        <Grid>
          <Grid container sx={{ ml: 6 }}>
            <Grid item xs={6}>
              <Stack direction="col" className="staffContent-grid">
                <Typography className="staff-details-header staff-text1">
                  Staff Reference :
                </Typography>
                <Typography className="staff-details-text staff-text1">
                  {staffCode}
                </Typography>
              </Stack>
              <Stack direction={"col"}>
                <Typography className="staff-details-header staff-text2">
                  Staff Name :
                </Typography>
                <Typography className="staff-details-text staff-text2">
                  {staffName}
                </Typography>
              </Stack>
              <Stack direction={"col"}>
                <Typography className="staff-details-header staff-text3">
                  Department :
                </Typography>
                <Typography className="staff-details-text staff-text3">
                  {departmentId}
                </Typography>
              </Stack>
              <Stack direction={"col"}>
                <Typography className="staff-details-header staff-text4">
                  Time Preference :
                </Typography>
                <Typography className="staff-details-text staff-text4">
                  {formattedTimeRangesString.split(", ").slice(0, 2).join(", ")}
                  <br />
                  {formattedTimeRangesString.split(", ").slice(2).join(", ")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sx={styles.spanContainer}>
              <Stack sx={{ ml: 15 }}>
                <Chip
                  label="Combined Class"
                  className="custom-chip-iscombined legend-iscombined"
                />

                <Grid sx={{ ml: 2 }}>
                  <Chip
                    label="Not Combined Class"
                    className="custom-chip-notcombined legend-notcombined"
                  />
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Stack direction="row">
              <Tooltip title="Add Course for Staff" placement="right" arrow>
                <IconButton
                  className={
                    algorithmStatus === null ||
                    algorithmStatus === "FAILED" ||
                    algorithmStatus === "UNKNOWN" ||
                    algorithmStatus === "NOT_CREATED"
                      ? "add-staff-course-button"
                      : "add-staff-course-button-disabled"
                  }
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    dispatch(AddStaffCourseModel(true));
                  }}
                  disabled={
                    algorithmStatus !== null &&
                    algorithmStatus !== "FAILED" &&
                    algorithmStatus !== "UNKNOWN" &&
                    algorithmStatus !== "NOT_CREATED"
                  }
                >
                  <AddIcon className="add-icon" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>

          <div className="staffCourseAssignment-list">
            <StaffCourseAssignmentList
              handleStaffCourseClick={handleStaffCourseClick}
              handleRemoveStaffCourse={handleRemoveStaffCourse}
              retrieveStaffCourseList={retrieveStaffCourseList}
            />
          </div>

          {addStaffCourseModel === true && <AddStaffCourseAssignment />}
        </Grid>
      )}
    </>
  );
};

export default StaffContent;
