import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { AddRoomsModel } from "../../../../../Store/Reducers/infrastructure_menu";
import "./RoomModel.css";

// ==============================|| ADD NEW ROOM MODEL ||============================== //

const RoomModel = ({
  handleRoomName,
  handleRoomCapacity,
  handleMultipleCourse,
  handleSaveRoom,
  roomsName,
  roomsCapacity,
  multipleCourse,
  handleAddRoomError,
}) => {
  const menu = useSelector((state) => state.infrastructure_menu);
  const addRoomsModel = menu.addRoomsModel;
  const addNewRoom = menu.addNewRoom;

  let modalOpen = false;
  addRoomsModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const [addRoom, setAddRoom] = useState(false);

  const dispatch = useDispatch();

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(AddRoomsModel(false));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { width: "26rem", height: "26.5rem" } }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">Add Room</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-room"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <br></br>
              <Typography className="model-text room-text1">
                Room Name
              </Typography>
              <OutlinedInput
                id="roomName"
                name="roomName"
                type="text"
                value={addNewRoom.roomName}
                onChange={handleRoomName}
                placeholder="Enter Room Name"
                variant="outlined"
                size="small"
                className="input-style"
              />
              <br></br>

              <Typography className="model-text room-text2">
                Room Capacity
              </Typography>
              <OutlinedInput
                id="roomCapacity"
                name="roomCapacity"
                type="number"
                value={addNewRoom.roomCapacity}
                onChange={handleRoomCapacity}
                placeholder="Enter Room Capacity"
                variant="outlined"
                size="small"
                className="input-style"
              />

              <Typography className="model-text room-text3">
                Multiple course allowed at one time
              </Typography>
              <FormControl
                className="room-radio-btn"
                onChange={(e) => handleMultipleCourse(e)}
              >
                <RadioGroup
                  row
                  name="multipleCourseAllowedAtOnetime"
                  aria-labelledby="multipleCourseAllowedAtOnetime"
                  value={multipleCourse}
                >
                  <FormControlLabel
                    name="multipleCourseAllowedAtOnetime"
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    name="multipleCourseAllowedAtOnetime"
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    roomsName.roomName === "" ||
                    roomsCapacity.roomCapacity === 0 ||
                    roomsCapacity.roomCapacity === "" ||
                    addRoom === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={
                    roomsName.roomName === "" ||
                    roomsCapacity.roomCapacity === 0 ||
                    roomsCapacity.roomCapacity === "" ||
                    addRoom === true
                  }
                  onClick={() => {
                    handleSaveRoom();
                    setAddRoom(true);
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-building-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleAddRoomError && (
                <>
                  <p className="duplicate-text">{handleAddRoomError}</p>
                  {handleAddRoomError !== "" && addRoom && setAddRoom(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RoomModel;
