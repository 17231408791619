import React from "react";
import { Grid, Paper, Box } from "@mui/material";
import Infrastructure from "./Infrastructure";
import Loader from "../../Loader/Loader";
import AlgorithmWarnings from "../Timetable/Warnings/AlgorithmWarnings";
import Department from "./Department/Department";
import { useSelector } from "react-redux";
import SemesterSelect from "../../SemesterSelectWarning/SemesterSelect";
import "../MainPage.css";

// ==============================|| INFRASTRUCTURE MAIN LAYOUT ||============================== //

const MainLayout = ({
  // Buildings Details
  handleBuildingClick,
  handleRemoveBuilding,
  // Rooms Details
  handleRoomClick,
  handleRemoveRoom,
  // Department Details
  handleDepartmentClick,
  handleRemoveDepartment,
}) => {
  const menu = useSelector((state) => state.infrastructure_menu);
  const isBuildingStatusCode = menu.isBuildingStatusCode;
  const isDepartmentStatusCode = menu.isDepartmentStatusCode;

  const course_menu = useSelector((state) => state.course_menu);
  const isCourseStatusCode = course_menu.isCourseStatusCode;

  const class_menu = useSelector((state) => state.class_menu);
  const isClassStatusCode = class_menu.isClassStatusCode;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const isCombinedClassStatusCode =
    combined_class_menu.isCombinedClassStatusCode;

  const staff_menu = useSelector((state) => state.staff_menu);
  const isStaffStatusCode = staff_menu.isStaffStatusCode;

  const fixedslot_menu = useSelector((state) => state.fixedslot_menu);
  const isFixedSlotStatusCode = fixedslot_menu.isFixedSlotStatusCode;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  return (
    <>
      {(isBuildingStatusCode === false ||
        isDepartmentStatusCode === false ||
        isCourseStatusCode === false ||
        isClassStatusCode === false ||
        isCombinedClassStatusCode === false ||
        isStaffStatusCode === false ||
        isFixedSlotStatusCode === false ||
        algorithmStatus === "") &&
      sessionStorage.getItem("branchName") !== null ? (
        <Loader />
      ) : (
        <>
          {sessionStorage.getItem("branchName") !== null ? (
            <>
              <div className="display-warnings">
                <AlgorithmWarnings />
              </div>
              <Paper className="paper-padding" sx={{ mt: 0 }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ mt: -3.5 }}
                >
                  <Grid item xs={6} sx={{ ml: -2 }}>
                    <Box
                      style={{
                        width: "310%",
                        maxWidth: "310vh",
                        height: "74vh",
                        marginLeft: "3vh",
                        marginTop: "2vh",
                      }}
                    >
                      <Infrastructure
                        // Buildings Details
                        handleBuildingClick={handleBuildingClick}
                        handleRemoveBuilding={handleRemoveBuilding}
                        // Rooms Details
                        handleRoomClick={handleRoomClick}
                        handleRemoveRoom={handleRemoveRoom}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sx={{ ml: 2 }}>
                    <Box
                      style={{
                        width: "50%",
                        maxWidth: "83vh",
                        height: "74vh",
                        marginLeft: "51%",
                        marginTop: "2.1vh",
                      }}
                    >
                      <Department
                        handleDepartmentClick={handleDepartmentClick}
                        handleRemoveDepartment={handleRemoveDepartment}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </>
          ) : (
            <SemesterSelect />
          )}
        </>
      )}
    </>
  );
};

export default MainLayout;
