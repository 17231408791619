import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http-common";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");

// ========================================|| LOGIN SERVICE ||======================================== //

const createLogin = (data) => {
  return apiService.post("/login", data, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getSemester = () => {
  return apiService.get("/semester", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const addSemester = (data) => {
  return apiService.post("/semester", data, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getSemesterType = () => {
  return apiService.get("/semester/semesterType", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getAcademicYear = () => {
  return apiService.get("/academicYear", {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const LoginService = {
  createLogin,
  getSemester,
  addSemester,
  getSemesterType,
  getAcademicYear,
};
export default LoginService;
