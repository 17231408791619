/* eslint-disable array-callback-return */
import React, { useState } from "react";
import {
  Grid,
  Stack,
  Typography,
  List,
  IconButton,
  Button,
  Divider,
  ListSubheader,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector, useDispatch } from "react-redux";
import { DeleteClassCombinedModel } from "../../../Store/Reducers/combined_class_menu";
import DeleteClassCombined from "./CombinedClass Model/DeleteClassCombined";
import "./ClassCombinedList.css";

// ==============================|| RHS CLASS COMBINED LIST ||============================== //

const ClassCombinedList = ({ retrieveCombinedClassList }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.combined_class_menu);
  const deleteClassCombinedModel = menu.deleteClassCombinedModel;
  const classCombinedList = menu.classCombinedList;
  const combinedId = menu.combinedId;

  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;
  const classCourseAssignment = class_menu.classCourseAssignmentAll;
  const classCourseId = class_menu.classCourseId;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [classCombinedId, setClassCombinedId] = useState(0);

  // Function used for getting Class name
  const getClassName = (id) => {
    const classNames = classCourseAssignment
      .filter((cid) => cid.id === id)
      .map((res) => {
        const classInfo = classList.find(
          (classid) => classid.id === res.classId,
        );
        if (classInfo) {
          return classInfo.className;
        }
        return null; // Return null if no matching class is found
      });

    // Return the classNames array, or null if it's empty
    return classNames.length > 0 ? classNames : null;
  };

  // Function used for getting No of students
  const getNumberOfStudents = (id) => {
    const numberOfStudents = classCourseAssignment
      .filter((cid) => cid.id === id)
      .map((res) => res.numberOfStudents);

    // Return the numberOfStudents, or null if no matching class is found
    return numberOfStudents.length > 0 ? numberOfStudents[0] : null;
  };

  // Function used to set the particular class id in classCombinedList
  const handleCombinedClassCourseClick = (classCombinedId) => {
    classCourseAssignment
      .filter((obj) => obj.id === classCombinedId)
      .map((val) => {
        setClassCombinedId(classCombinedId);
      });
  };

  return (
    <>
      <Grid container className="course-page">
        <Grid container>
          <Grid item xs={12}>
            <List className="classCombined-list" subheader={<li />}>
              <ListSubheader className="comclass-list_header">
                <Grid container mt={1} className="comclass-list_header-Grid">
                  <Grid item xs={3}>
                    <Stack
                      direction="row"
                      className="list-title-sort"
                      ml={-5.2}
                    >
                      <Typography className="comclass-header">
                        Class name
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={4.9}>
                    <Stack direction="row" className="list-title-sort" ml={3}>
                      <Typography className="comclass-header">
                        Number of Students
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListSubheader>
              <Divider className="classcom-divider" />
              <div className="list-container classCombinedlist-height">
                {classCombinedList
                  .filter((cid) => cid.id === combinedId)
                  .map(
                    (item, index) =>
                      // Check if item is defined before accessing its properties
                      item &&
                      item.classCourseAssignmentId &&
                      item.classCourseAssignmentId.map((element, subIndex) => (
                        <li key={index} className="comclass-list list-width">
                          <Button
                            className={
                              classCourseId === element
                                ? "combinedclass-content--button--selected combinedclass-content--button"
                                : "combinedclass-content--button"
                            }
                          >
                            <Grid
                              container
                              className="comclass-list_header-Grid"
                              sx={{ marginLeft: "1rem !important" }}
                            >
                              <Grid item xs={6} sx={{ ml: -1.3 }}>
                                {getClassName(element)}
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                className="activate-deactivate-dots"
                              >
                                {getNumberOfStudents(element)}
                                <IconButton
                                  aria-label="more"
                                  onClick={() => {
                                    handleCombinedClassCourseClick(element);
                                    dispatch(DeleteClassCombinedModel(true));
                                  }}
                                  aria-haspopup="true"
                                  aria-controls="long-menu"
                                  className="combinedclass-menu-icon"
                                  disabled={
                                    algorithmStatus !== null &&
                                    algorithmStatus !== "FAILED" &&
                                    algorithmStatus !== "UNKNOWN"
                                  }
                                >
                                  <DeleteIcon className="delete-combinedclass-icon" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Button>
                        </li>
                      )),
                  )}
              </div>
            </List>
            {deleteClassCombinedModel === true && (
              <DeleteClassCombined
                retrieveCombinedClassList={retrieveCombinedClassList}
                classCombinedId={classCombinedId}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClassCombinedList;
