import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http-common";
import { getBranchName } from "../Utils/common";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");
// ========================================|| ALGORITHM SERVICE ||======================================== //

const getAlgorithmStatus = () => {
  return apiService.get("/algorithmStatus", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const updateAlgorithmStatus = () => {
  return apiService.post("/algorithmStatus/reset", "", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const createAlgorithm = (data) => {
  return apiService.post("/algorithm", data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const clearResult = () => {
  return apiService.delete("/clearResult", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const AlgorithmService = {
  getAlgorithmStatus,
  updateAlgorithmStatus,
  createAlgorithm,
  clearResult,
};

export default AlgorithmService;
