/* eslint-disable array-callback-return */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteFixedSlotGroupModel } from "../../../Store/Reducers/fixedslot_menu";
import {
  Button,
  IconButton,
  MenuItem,
  Grid,
  List,
  ListSubheader,
  ListItem,
  Menu,
  Tooltip,
  Divider,
} from "@mui/material";
import {
  EditFixedSlotGroupModel,
  ViewFixedSlotGroupModel,
} from "../../../Store/Reducers/fixedslot_menu";
import DeleteFixedSlotGroup from "./FixedSlot Models/DeleteFixedSlotGroup";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditFixedSlotGroup from "./FixedSlot Models/EditFixedSlotGroup";
import ViewFixedSlotGroup from "./FixedSlot Models/ViewFixedSlotGroup";
import "./FixedSlots.css";

// ==============================|| FIXED SLOTS GROUP LIST ||============================== //

const FixedSlotGroupList = ({
  fixedSlotId,
  handleError,
  setPossibleRooms,
  setSelectedCheckboxes,
  setHandleErrorEdit,
  setEditSlot,
  setEditSelectedRooms,
  setEditSelectedStaffs,
  setDayId,
  setTimeId,
  setSelectRooms,
  setSelectedStaff,
  setRoomIdArr,
  setEditRoomIdArr,
  fixedSlotGroupId,
  handleFixedSlotGroupClick,
  getClassCourseId,
  getCombinedClassId,
  handleClick,
  anchorEl,
  handleClose,
  handleRemoveFixedSlotGroup,
  classId,
  classCourseOptions,
  getAllClassCourseName,
  combinedClassId,
  combinedClassOptions,
  getAllCombinedClassName,
  dayId,
  timeId,
  possibleRooms,
  fixedSlotInfo,
  selectAvailableRoomsEdit,
  selectAvailableStaffsEdit,
  handleEditFixedSlot,
  selectedCheckboxes,
  setFixedSlotInfo,
  setFixedSlot,
  setPossibleRoomsCountEdit,
  setAvailableStaffHours,
  setAvailableStaffSelect,
  setAvailableStaffName,
  fixedSlot,
  editSlot,
  emptyError,
  setEmptyError,
  setFixedSlotLoader,
}) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.fixedslot_menu);
  const fixedSlotsGroup = menu.fixedSlotsGroup;
  const viewFixedSlotGroupModel = menu.viewFixedSlotGroupModel;
  const editFixedSlotGroupModel = menu.editFixedSlotGroupModel;
  const deleteFixedSlotGroupModel = menu.deleteFixedSlotGroupModel;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const addNewRoom = infrastructure_menu.addNewRoom;

  const class_menu = useSelector((state) => state.class_menu);
  const classCourseAssignment = class_menu.classCourseAssignmentAll;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;

  const staff_menu = useSelector((state) => state.staff_menu);
  const staffList = staff_menu.staffList;
  const staffCourseAssignment = staff_menu.staffCourseAssignmentAll;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const getPossibleRooms = () => {
    if (fixedSlotInfo.classCourseAssignmentId !== null) {
      classCourseAssignment
        .filter(
          (clsCourse) => clsCourse.id === fixedSlotInfo.classCourseAssignmentId,
        )
        .map((resClassCourse) => {
          setPossibleRoomsCountEdit(resClassCourse.numberOfRoomsRequired);

          if (possibleRooms.length === 0) {
            addNewRoom
              .filter((room) => resClassCourse.possibleRooms.includes(room.id))
              .forEach((res) => {
                setPossibleRooms((prevPossibleRooms) => [
                  ...prevPossibleRooms,
                  res.roomName,
                ]);
              });
          }
        });

      const matchingStaffCourses = staffCourseAssignment.filter(
        (staffCourse) =>
          staffCourse.classCourseAssignmentId ===
          fixedSlotInfo.classCourseAssignmentId,
      );

      if (matchingStaffCourses.length > 0) {
        const matchingStaffIds = matchingStaffCourses
          .sort((a, b) => a.staffId - b.staffId)
          .map((staffCourse) => staffCourse.staffId);
        let namesArray = [];
        for (let i = 0; i < matchingStaffIds.length; i++) {
          let matchingStaffNames = staffList
            .filter((staff) => staff.id === matchingStaffIds[i])
            .map((staff) => staff.staffName);
          namesArray.push(matchingStaffNames);
        }
        let names = namesArray.flat();
        let hoursArray = [];
        for (let i = 0; i < matchingStaffIds.length; i++) {
          let matchingHours = matchingStaffCourses
            .filter((item) => item.staffId === matchingStaffIds[i])
            .map((obj) => obj.teachingHours);
          hoursArray.push(matchingHours);
        }
        let hours = hoursArray.flat();

        setAvailableStaffHours(hours);
        if (names.length > 0) {
          setAvailableStaffSelect(names);
          setAvailableStaffName(names);
        }
      }
    }

    if (fixedSlotInfo.combinedClassId !== null) {
      classCombinedList
        .filter((comcls) => comcls.id === fixedSlotInfo.combinedClassId)
        .map((resCom) => {
          setPossibleRoomsCountEdit(resCom.numberOfRoomsRequired);

          if (possibleRooms.length === 0) {
            addNewRoom
              .filter((room) => resCom.possibleRooms.includes(room.id))
              .forEach((res) => {
                setPossibleRooms((prevPossibleRooms) => [
                  ...prevPossibleRooms,
                  res.roomName,
                ]);
              });
          }
        });

      const matchingStaffCourses = staffCourseAssignment.filter(
        (staffCourse) =>
          staffCourse.combinedClassId === fixedSlotInfo.combinedClassId,
      );
      if (matchingStaffCourses.length > 0) {
        const matchingStaffIds = matchingStaffCourses
          .sort((a, b) => a.staffId - b.staffId)
          .map((staffCourse) => staffCourse.staffId);
        let namesArray = [];
        for (let i = 0; i < matchingStaffIds.length; i++) {
          let matchingStaffNames = staffList
            .filter((staff) => staff.id === matchingStaffIds[i])
            .map((staff) => staff.staffName);
          namesArray.push(matchingStaffNames);
        }
        let names = namesArray.flat();
        let hoursArray = [];
        for (let i = 0; i < matchingStaffIds.length; i++) {
          let matchingHours = matchingStaffCourses
            .filter((item) => item.staffId === matchingStaffIds[i])
            .map((obj) => obj.teachingHours);
          hoursArray.push(matchingHours);
        }
        let hours = hoursArray.flat();

        setAvailableStaffHours(hours);
        if (names.length > 0) {
          setAvailableStaffSelect(names);
          setAvailableStaffName(names);
        }
      }
    }
  };

  //Group fixedSlot by courseId
  const groupedFixedSlots = fixedSlotsGroup.reduce((acc, slot) => {
    if (slot.classCourseAssignmentId !== null) {
      const classCourseAssignments = classCourseAssignment.find(
        (assignment) => assignment.id === slot.classCourseAssignmentId,
      );

      if (classCourseAssignments) {
        const courseId = classCourseAssignments.courseId;
        if (!acc[courseId]) {
          acc[courseId] = [];
        }
        acc[courseId].push(slot);
      }
    } else if (slot.combinedClassId !== null) {
      const combinedClass = classCombinedList.find(
        (combined) => combined.id === slot.combinedClassId,
      );

      if (combinedClass) {
        const courseId = combinedClass.courseId;
        if (!acc[courseId]) {
          acc[courseId] = [];
        }
        acc[courseId].push(slot);
      }
    }
    return acc;
  }, {});

  return (
    <Grid sx={{ ml: 1 }}>
      <List className="fixedSlot-list-style" subheader={<li />}>
        <ListSubheader sx={{ backgroundColor: "#f9f9f9" }}>
          <Grid>
            <div className="list-container fixedslot-list-height">
              {Object.entries(groupedFixedSlots).map(([courseId, slots]) => (
                <React.Fragment key={courseId}>
                  <>
                    {slots.map((res, index) => (
                      <React.Fragment key={res.id}>
                        <li className="comclass-list">
                          <Button
                            className={
                              fixedSlotGroupId === res.id
                                ? "fixedgroup-content--button--selected fixedgroup-content--button"
                                : "fixedgroup-content--button"
                            }
                            onClick={() => {
                              handleFixedSlotGroupClick(res.id);
                              setFixedSlotLoader(true);
                            }}
                          >
                            <ListItem>
                              <Grid
                                item
                                xs={11}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "0rem",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <Tooltip
                                  title={
                                    res.classCourseAssignmentId !== null
                                      ? getClassCourseId(
                                          res.classCourseAssignmentId,
                                        )
                                      : getCombinedClassId(res.combinedClassId)
                                  }
                                >
                                  {res.classCourseAssignmentId !== null
                                    ? getClassCourseId(
                                        res.classCourseAssignmentId,
                                      )
                                    : getCombinedClassId(res.combinedClassId)}
                                </Tooltip>
                              </Grid>
                              <IconButton
                                aria-label="more"
                                onClick={(event) => {
                                  if (fixedSlotInfo.length !== 0) {
                                    event.stopPropagation();
                                  }
                                  handleClick(event);
                                  if (res.id !== fixedSlotGroupId) {
                                    handleFixedSlotGroupClick(res.id);
                                    setFixedSlotLoader(true);
                                  }
                                }}
                                aria-haspopup="true"
                                aria-controls="long-menu"
                                className="fixedgroup-menu-icon"
                              >
                                <MoreVertIcon className="moreVertIcon-style" />
                              </IconButton>
                            </ListItem>
                          </Button>
                        </li>
                      </React.Fragment>
                    ))}
                    <Divider />
                  </>
                </React.Fragment>
              ))}
            </div>
          </Grid>
        </ListSubheader>
      </List>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(ViewFixedSlotGroupModel(true));
            getPossibleRooms();
            handleClose();
          }}
          className="course-menu"
          disabled={fixedSlotInfo.length === 0}
        >
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(EditFixedSlotGroupModel(true));
            getPossibleRooms();
            handleClose();
          }}
          className="course-menu"
          disabled={
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED") ||
            fixedSlotInfo.length === 0
          }
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(DeleteFixedSlotGroupModel(true));
            handleClose();
          }}
          disabled={
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED") ||
            fixedSlotInfo.length === 0
          }
        >
          Delete
        </MenuItem>
      </Menu>
      {viewFixedSlotGroupModel === true && (
        <ViewFixedSlotGroup
          fixedSlotId={fixedSlotId}
          fixedSlotGroupId={fixedSlotGroupId}
        />
      )}

      {editFixedSlotGroupModel === true && (
        <EditFixedSlotGroup
          handleError={handleError}
          setPossibleRooms={setPossibleRooms}
          setSelectedCheckboxes={setSelectedCheckboxes}
          setHandleErrorEdit={setHandleErrorEdit}
          setEditSlot={setEditSlot}
          setEditSelectedStaffs={setEditSelectedStaffs}
          setEditSelectedRooms={setEditSelectedRooms}
          setDayId={setDayId}
          setTimeId={setTimeId}
          setSelectRooms={setSelectRooms}
          setSelectedStaff={setSelectedStaff}
          setRoomIdArr={setRoomIdArr}
          setEditRoomIdArr={setEditRoomIdArr}
          classId={classId}
          classCourseOptions={classCourseOptions}
          getAllClassCourseName={getAllClassCourseName}
          combinedClassId={combinedClassId}
          combinedClassOptions={combinedClassOptions}
          getAllCombinedClassName={getAllCombinedClassName}
          dayId={dayId}
          timeId={timeId}
          possibleRooms={possibleRooms}
          fixedSlotGroupId={fixedSlotGroupId}
          fixedSlotInfo={fixedSlotInfo}
          selectAvailableRoomsEdit={selectAvailableRoomsEdit}
          selectAvailableStaffsEdit={selectAvailableStaffsEdit}
          handleEditFixedSlot={handleEditFixedSlot}
          selectedCheckboxes={selectedCheckboxes}
          setFixedSlotInfo={setFixedSlotInfo}
          setFixedSlot={setFixedSlot}
          fixedSlot={fixedSlot}
          editSlot={editSlot}
          emptyError={emptyError}
          setEmptyError={setEmptyError}
        />
      )}

      {deleteFixedSlotGroupModel === true && (
        <DeleteFixedSlotGroup
          fixedSlotGroupId={fixedSlotGroupId}
          handleRemoveFixedSlotGroup={handleRemoveFixedSlotGroup}
        />
      )}
    </Grid>
  );
};

export default FixedSlotGroupList;
