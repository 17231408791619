import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  Stack,
  Divider,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import "./HomePage.css";

// ==============================|| ADD SEMESTER MODAL ||============================== //

export default function AddSemesterModal({
  academicYear,
  semesterType,
  importDataFromSemesterId,
  addSemesterModal,
  setAddSemesterModal,
  handleAcademicYear,
  handleSemesterType,
  handleSave,
  handleClose,
  addSemesterError,
  handleSemester,
  addNewClick,
  setAddNewClick,
}) {
  const login_menu = useSelector((state) => state.login_menu);
  const semesterDetails = login_menu.semesterDetails;
  const semester_type = login_menu.semesterType;
  const academic_years = login_menu.academicYears;

  return (
    <React.Fragment>
      <Dialog aria-labelledby="customized-dialog-title" open={addSemesterModal}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add New Semester
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="addsem-modal-close-icon"
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent>
          <Typography className="add-semester-labels">
            Semester data to be imported
          </Typography>
          <FormControl className="input-style" sx={{ mb: 1 }}>
            <Select
              value={importDataFromSemesterId}
              onChange={handleSemester}
              size="small"
              style={{
                color: importDataFromSemesterId === 0 ? "#aaacae" : "#000000",
              }}
              displayEmpty
            >
              <MenuItem value={0} disabled>
                Choose Semester
              </MenuItem>
              {semesterDetails.map((semester) => (
                <MenuItem key={semester.id} value={semester.id}>
                  {semester.tenantName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography sx={{ mt: 1 }} className="add-semester-labels">
            Academic year
          </Typography>

          <FormControl className="input-style">
            <Select
              value={academicYear}
              onChange={handleAcademicYear}
              size="small"
              style={{ color: academicYear === 0 ? "#aaacae" : "#000000" }}
              displayEmpty
            >
              <MenuItem value={0} disabled>
                Choose Academic year
              </MenuItem>
              {academic_years.map((academic, index) => (
                <MenuItem key={index} value={academic.academicYear}>
                  {academic.academicYear}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography sx={{ mt: 2 }} className="add-semester-labels">
            Semester Type
          </Typography>
          <FormControl className="input-style">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              className="addSemester-radio-group-style"
            >
              {semester_type.map((semType, index) => (
                <FormControlLabel
                  value={semType}
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          width: 19,
                          height: 19,
                        },
                      }}
                    />
                  }
                  label={semType}
                  onChange={handleSemesterType}
                  className="semesterType-style"
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>

        {addSemesterError && (
          <>
            <p className="add-new-semester-error">{addSemesterError}</p>
          </>
        )}
        <DialogActions>
          <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
            <Button
              variant="contained"
              className={
                addSemesterError ||
                semesterType === 0 ||
                academicYear === 0 ||
                importDataFromSemesterId === 0 ||
                addNewClick === true
                  ? "save--button save--button-disabled-background"
                  : "save--button save--button-background"
              }
              disabled={
                addSemesterError ||
                semesterType === 0 ||
                academicYear === 0 ||
                importDataFromSemesterId === 0 ||
                addNewClick === true
              }
              onClick={() => {
                handleSave();
                setAddNewClick(true);
              }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              className="cancel--button"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
