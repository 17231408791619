// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  addBuildingModel: false,
  editBuildingModel: false,
  deleteBuildingModel: false,
  buildingList: [],
  buildingId: null,
  buildingInfo: [],
  buildingInFixedSlot: [],
  isBuildingStatusCode: false,

  addRoomsModel: false,
  editRoomsModel: false,
  deleteRoomsModel: false,
  addNewRoom: [],
  roomId: null,
  roomInfo: [],
  roomInFixedSlot: [],
  isRoomStatusCode: false,

  addDepartmentModel: false,
  editDepartmentModel: false,
  deleteDepartmentModel: false,
  departmentList: [],
  departmentId: null,
  departmentInfo: [],
  isDepartmentStatusCode: false,
};

// ==============================|| SLICE - INFRASTRUCTURE MENU ||============================== //

const infrastructure_menu = createSlice({
  name: "infrastructure_menu",
  initialState,
  reducers: {
    AddBuildingModel(state, action) {
      state.addBuildingModel = action.payload;
    },
    EditBuildingModel(state, action) {
      state.editBuildingModel = action.payload;
    },
    DeleteBuildingModel(state, action) {
      state.deleteBuildingModel = action.payload;
    },
    AddBuilding(state, action) {
      state.buildingList = action.payload;
    },
    BuildingId(state, action) {
      state.buildingId = action.payload;
    },
    BuildingInfo(state, action) {
      state.buildingInfo = action.payload;
    },
    BuildingInFixedSlot(state, action) {
      state.buildingInFixedSlot = action.payload;
    },
    IsBuildingStatusCode(state, action) {
      state.isBuildingStatusCode = action.payload;
    },

    AddRoomsModel(state, action) {
      state.addRoomsModel = action.payload;
    },
    EditRoomsModel(state, action) {
      state.editRoomsModel = action.payload;
    },
    DeleteRoomsModel(state, action) {
      state.deleteRoomsModel = action.payload;
    },
    AddNewRoom(state, action) {
      state.addNewRoom = action.payload;
    },
    RoomId(state, action) {
      state.roomId = action.payload;
    },
    RoomInfo(state, action) {
      state.roomInfo = action.payload;
    },
    RoomInFixedSlot(state, action) {
      state.roomInFixedSlot = action.payload;
    },
    IsRoomStatusCode(state, action) {
      state.isRoomStatusCode = action.payload;
    },

    AddDepartmentModel(state, action) {
      state.addDepartmentModel = action.payload;
    },
    EditDepartmentModel(state, action) {
      state.editDepartmentModel = action.payload;
    },
    DeleteDepartmentModel(state, action) {
      state.deleteDepartmentModel = action.payload;
    },
    AddDepartment(state, action) {
      state.departmentList = action.payload;
    },
    DepartmentId(state, action) {
      state.departmentId = action.payload;
    },
    DepartmentInfo(state, action) {
      state.departmentInfo = action.payload;
    },
    IsDepartmentStatusCode(state, action) {
      state.isDepartmentStatusCode = action.payload;
    },
  },
});

export default infrastructure_menu.reducer;

export const {
  AddBuildingModel,
  EditBuildingModel,
  DeleteBuildingModel,
  AddBuilding,
  BuildingId,
  BuildingInfo,
  BuildingInFixedSlot,
  IsBuildingStatusCode,

  AddRoomsModel,
  EditRoomsModel,
  DeleteRoomsModel,
  AddNewRoom,
  RoomId,
  RoomInfo,
  RoomInFixedSlot,
  IsRoomStatusCode,

  AddDepartmentModel,
  EditDepartmentModel,
  DeleteDepartmentModel,
  AddDepartment,
  DepartmentId,
  DepartmentInfo,
  IsDepartmentStatusCode,
} = infrastructure_menu.actions;
