import React from "react";
import { useSelector } from "react-redux";

// ==============================|| CLASS GANTTCHART MODEL ||============================== //

export const GanttChartModel = ({ item }) => {
  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const addNewRoom = infrastructure_menu.addNewRoom;

  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;
  const classCourseAssignment = class_menu.classCourseAssignmentAll;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;

  const staff_menu = useSelector((state) => state.staff_menu);
  const staffList = staff_menu.staffList;

  //Getting all details to be displayed in Ganttchart model
  let className = "",
    numberOfStudents = "",
    roomNames = [],
    staffNames = [];

  if (item.classCourseAssignmentId !== null) {
    const matchingCourse = classCourseAssignment.find(
      (clsCourse) => clsCourse.id === item.classCourseAssignmentId,
    );

    if (matchingCourse) {
      numberOfStudents = matchingCourse.numberOfStudents;
      className = classList.find(
        (cls) => cls.id === matchingCourse.classId,
      )?.className;
    }

    if (Array.isArray(item.roomId)) {
      item.roomId.forEach((roomId) => {
        const matchingRooms = addNewRoom.filter((room) => room.id === roomId);
        const roomNamesForRoom = matchingRooms.map(
          (resRoom) => resRoom.roomName,
        );
        roomNames.push(roomNamesForRoom);
      });
    } else {
      const matchingRooms = addNewRoom.filter(
        (room) => room.id === item.roomId,
      );
      const roomNamesForRoom = matchingRooms.map((resRoom) => resRoom.roomName);
      roomNames.push(roomNamesForRoom);
    }

    if (Array.isArray(item.staffId)) {
      item.staffId.forEach((staffId) => {
        const matchingStaffs = staffList.filter(
          (staff) => staff.id === staffId,
        );
        const staffNamesForStaff = matchingStaffs.map(
          (resStaff) => resStaff.staffName,
        );
        staffNames.push(staffNamesForStaff);
      });
    } else {
      const matchingStaffs = staffList.filter(
        (staff) => staff.id === item.staffId,
      );
      const staffNamesForStaff = matchingStaffs.map(
        (resStaff) => resStaff.staffName,
      );
      staffNames.push(staffNamesForStaff);
    }
  }

  if (item.combinedClassId !== null) {
    const matchingCombinedCourse = classCombinedList.find(
      (clsComCourse) => clsComCourse.id === item.combinedClassId,
    );

    if (matchingCombinedCourse) {
      numberOfStudents = matchingCombinedCourse.numberOfStudents;
      className = matchingCombinedCourse.combinedClassName;
    }

    if (Array.isArray(item.roomId)) {
      item.roomId.forEach((roomId) => {
        const matchingRooms = addNewRoom.filter((room) => room.id === roomId);
        const roomNamesForRoom = matchingRooms.map(
          (resRoom) => resRoom.roomName,
        );
        roomNames.push(roomNamesForRoom);
      });
    } else {
      const matchingRooms = addNewRoom.filter(
        (room) => room.id === item.roomId,
      );
      const roomNamesForRoom = matchingRooms.map((resRoom) => resRoom.roomName);
      roomNames.push(roomNamesForRoom);
    }

    if (Array.isArray(item.staffId)) {
      item.staffId.forEach((staffId) => {
        const matchingStaffs = staffList.filter(
          (staff) => staff.id === staffId,
        );
        const staffNamesForStaff = matchingStaffs.map(
          (resStaff) => resStaff.staffName,
        );
        staffNames.push(staffNamesForStaff);
      });
    } else {
      const matchingStaffs = staffList.filter(
        (staff) => staff.id === item.staffId,
      );
      const staffNamesForStaff = matchingStaffs.map(
        (resStaff) => resStaff.staffName,
      );
      staffNames.push(staffNamesForStaff);
    }
  }

  return (
    <div class="grid-container">
      <div class="grid-label" className="hoursText-color">
        Course Code
      </div>
      <div class="grid-item">{item.courseCode}</div>
      <div class="grid-label" className="hoursText-color">
        Course Name
      </div>
      <div class="grid-item">{item.title}</div>

      <div class="grid-label" className="hoursText-color">
        {item.classCourseAssignmentId !== null
          ? "Class Name"
          : "Combined Class Name"}
      </div>
      <div class="grid-item">{className}</div>

      <div class="grid-label" className="hoursText-color">
        Number of Students
      </div>
      <div class="grid-item">{numberOfStudents}</div>

      <div class="grid-label" className="hoursText-color">
        Room Name
      </div>
      <div class="grid-item">
        <React.Fragment>
          {roomNames.map((names, index) => (
            <div key={index}>{names.join("<br/>")}</div>
          ))}
        </React.Fragment>
      </div>
      <div class="grid-label" className="hoursText-color">
        Staff Name
      </div>
      <div class="grid-item">
        <React.Fragment>
          {staffNames.map((names, index) => (
            <div key={index}>{names.join("<br/>")}</div>
          ))}
        </React.Fragment>
      </div>
    </div>
  );
};
