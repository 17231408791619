// third-party
import { combineReducers } from "redux";
// project import
import menu from "./menu";
import login_menu from "./login_menu";
import infrastructure_menu from "./infrastructure_menu";
import course_menu from "./course_menu";
import class_menu from "./class_menu";
import combined_class_menu from "./combined_class_menu";
import staff_menu from "./staff_menu";
import fixedslot_menu from "./fixedslot_menu";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  infrastructure_menu,
  course_menu,
  class_menu,
  combined_class_menu,
  staff_menu,
  fixedslot_menu,
  login_menu,
});

export default reducers;
