/* eslint-disable array-callback-return */
import React from "react";
import {
  Grid,
  Stack,
  Typography,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { AddClassCourseModel } from "../../../Store/Reducers/class_menu";
import AddCourseInClass from "./Classes Model/AddCourseInClass";
import ClassCourseAssignmentList from "./ClassCourseAssignmentList";
import Loader from "../../Loader/Loader";
import "./ClassContent.css";

// ==============================|| RHS CLASS CONTENT ||============================== //

const ClassContent = ({ handleClassCourseClick, handleRemoveClassCourse }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.class_menu);
  const addClassCourseModel = menu.addClassCourseModel;
  const classList = menu.classList;
  const classId = menu.classId;
  const isClassCourseStatusCode = menu.isClassCourseStatusCode;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const departmentList = infrastructure_menu.departmentList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  // Used to retireve the class name
  let className, departmentId;
  classList
    .filter((obj) => obj.id === classId)
    .map((val) => {
      // Storing class name
      className = val.className;

      // Storing class department
      if (val.departmentId === null) {
        departmentId = "null";
      } else {
        departmentList
          .filter((ob) => ob.id === val.departmentId)
          .map((val) => (departmentId = val.departmentName));
      }
    });

  return (
    <>
      {isClassCourseStatusCode === false ? (
        <Loader />
      ) : (
        <Grid>
          <Grid container>
            <Grid item xs={6}>
              <Stack direction="col" className="classContent-start">
                <Typography
                  className="institution-details1"
                  sx={{ ml: 2, whiteSpace: "nowrap" }}
                >
                  Class Name :
                </Typography>
                <Typography className="institution-details5">
                  {className}
                </Typography>
              </Stack>
              <Stack direction={"col"}>
                <Typography className="institution-details3" sx={{ ml: 2 }}>
                  Department :
                </Typography>
                <Typography className="institution-details5">
                  {departmentId}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} className="classContent-end">
              <Stack>
                <Chip label="Combined Class" className="legend-iscombined" />

                <Grid sx={{ ml: 2 }}>
                  <Chip
                    label="Not Combined Class"
                    className="legend-notcombined"
                  />
                </Grid>
              </Stack>
            </Grid>
          </Grid>

          <Grid item sx={{ ml: -7 }}>
            <Stack direction="row">
              <Tooltip title="Add Course for Class" placement="right" arrow>
                <IconButton
                  className={
                    algorithmStatus === null ||
                    algorithmStatus === "FAILED" ||
                    algorithmStatus === "UNKNOWN" ||
                    algorithmStatus === "NOT_CREATED"
                      ? "add-class-course-button"
                      : "add-class-course-button-disabled"
                  }
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    dispatch(AddClassCourseModel(true));
                  }}
                  disabled={
                    algorithmStatus !== null &&
                    algorithmStatus !== "FAILED" &&
                    algorithmStatus !== "UNKNOWN" &&
                    algorithmStatus !== "NOT_CREATED"
                  }
                >
                  <AddIcon className="add-icon" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>

          <div className="classCourseAssignmentList-style">
            <ClassCourseAssignmentList
              handleClassCourseClick={handleClassCourseClick}
              handleRemoveClassCourse={handleRemoveClassCourse}
              className={className}
            />
          </div>

          {addClassCourseModel === true && (
            <AddCourseInClass className={className} />
          )}
        </Grid>
      )}
    </>
  );
};

export default ClassContent;
