/* eslint-disable array-callback-return */
import React from "react";
import {
  Box,
  Stack,
  Grid,
  Button,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Popper,
  Grow,
  ButtonGroup,
  FormControl,
  Select,
  IconButton,
  Tooltip,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  HandleFailedModel,
  HandleGenerateLoader,
  ClearResultConfirm,
  ViewOverallCount,
} from "../../../Store/Reducers/menu";
import LoadingGif from "../../../Assets/Images/LoadingGif.gif";
import { LocalPrintshop } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CampusPlannerLogo from "../../../Assets/Images/CampusPlannerLogo.png";
import VVVLogo from "../../../Assets/Images/vvvclogo.png";
import InfoIcon from "@mui/icons-material/Info";

// ==============================|| DOWNLOAD BUTTON ||============================== //

export default function DownloadButton({
  selectedDepartment,
  handleSelectDepartment,
  departmentOptions,
  runalgorithm,
  anchorRef,
}) {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const handleLoader = menu.handleLoader;
  const handleGenerateLoader = menu.handleGenerateLoader;
  const timeList = menu.timeList;
  const warningsList = menu.warningsList;
  const algorithmStatus = menu.algorithmStatus;
  const resultClassList = menu.resultClassList;
  const resultStaffList = menu.resultStaffList;
  const resultRoomList = menu.resultRoomList;

  const building_menu = useSelector((state) => state.infrastructure_menu);
  const buildingList = building_menu.buildingList;
  const addNewRoom = building_menu.addNewRoom;
  const departmentList = building_menu.departmentList;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;
  const classCourseAssignment = class_menu.classCourseAssignmentAll;

  const staff_menu = useSelector((state) => state.staff_menu);
  const staffList = staff_menu.staffList;
  const staffCourseAssignment = staff_menu.staffCourseAssignmentAll;

  const day_menu = useSelector((state) => state.menu);
  const dayList = day_menu.dayList;

  const login_menu = useSelector((state) => state.login_menu);
  const semesterDetails = login_menu.semesterDetails;
  const selectedSemesterId = login_menu.selectedSemesterId;

  //Get selected semester details to check its status
  const semester = semesterDetails.find(
    (semester) => semester.id === selectedSemesterId,
  );

  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  // Function used for getting Department name
  const getDepartmentName = (deptId) => {
    const department = departmentList.find((dept) => dept.id === deptId);
    return department ? department.departmentName : "Department not found";
  };

  // Function used for getting Building name
  const getBuildingName = (buildingId) => {
    const building = buildingList.find((build) => build.id === buildingId);
    return building ? building.buildingName : "Building not found";
  };

  // Function used for getting Room name
  const getRoomName = (roomId) => {
    const room = addNewRoom.find((room) => room.id === roomId);
    return room ? room.roomName : "Room not found";
  };

  // Function used for getting Class name
  const getClassName = (classId) => {
    const classes = classList.find((classes) => classes.id === classId);
    return classes ? classes.className : "Class not found";
  };

  // Function used for getting Staff name
  const getStaffName = (staffIds) => {
    let staffNames = [];
    staffList
      .filter((obj) => staffIds.includes(obj.id))
      .map((val) => {
        staffNames.push(val.staffCode);
      });
    return staffNames;
  };

  // Function used for getting Class course name
  const getClassCourseAssignmentName = (classCourseId) => {
    let name = "";
    let courseId = 0;
    classCourseAssignment
      .filter((obj) => Number(obj.id) === Number(classCourseId))
      .map((val) => {
        courseId = val.courseId;
      });
    courseList
      .filter((obj) => obj.id === courseId)
      .map((val) => {
        name = val.courseName + "(" + val.courseCode + ")";
      });
    return name;
  };

  // Function used for getting Day and Time wise Room name
  const getDayAndTimeWiseRoom = (roomId, dayId, timeId) => {
    let courseName = "";
    let staffName = "";
    let className = "";
    let details = [];
    const roomDataArray = resultRoomList.filter(
      (obj) => Number(obj.roomId) === Number(roomId),
    );

    if (roomDataArray.length > 0) {
      const addNewRoom = roomDataArray[0]; // Take the first element from the filtered array

      if (
        addNewRoom.roomScheduleList &&
        Array.isArray(addNewRoom.roomScheduleList)
      ) {
        const scheduleData = addNewRoom.roomScheduleList.find((val3) => {
          return Number(val3.dayId) === dayId && Number(val3.timeId) === timeId;
        });

        if (scheduleData) {
          Object.values(scheduleData)[2].map((val) => {
            courseName = getClassCourseAssignmentName(
              val.classCourseAssignmentId,
            );
            className = getClassName(val.classId);
            staffName = getStaffName(val.staffIds);
            details.push(courseName);
            details.push("\n");
            details.push("class : " + className);
            details.push("\n");
            details.push("Staff : " + staffName);
            details.push("\n");
            Object.values(scheduleData)[2].length > 1 && details.push("\n");
          });
        }
      }
    }

    return details;
  };

  // Function used for getting Day and Time wise Class name
  const getDayAndTimeWiseClass = (classId, dayId, timeId) => {
    let courseName = "";
    let staffName = "";
    let roomName = "";
    let details = [];
    const classDataArray = resultClassList.filter(
      (obj) => Number(obj.classId) === Number(classId),
    );

    if (classDataArray.length > 0) {
      const classData = classDataArray[0]; // Take the first element from the filtered array

      if (
        classData.classScheduleList &&
        Array.isArray(classData.classScheduleList)
      ) {
        const scheduleData = classData.classScheduleList.find((val3) => {
          return Number(val3.dayId) === dayId && Number(val3.timeId) === timeId;
        });

        if (scheduleData) {
          Object.values(scheduleData)[2].map((val) => {
            courseName = getClassCourseAssignmentName(
              val.classCourseAssignmentId,
            );
            roomName = getRoomName(val.roomId);
            staffName = getStaffName(val.staffIds);
            details.push(courseName);
            details.push("\n");
            details.push("Room : " + roomName);
            details.push("\n");
            details.push("Staff : " + staffName);
            details.push("\n");
            Object.values(scheduleData)[2].length > 1 && details.push("\n");
          });
        }
      }
    }

    return details;
  };

  // Function used for getting Day and Time wise Staff name
  const getDayAndTimeWiseStaff = (staffId, dayId, timeId) => {
    let courseName = "";
    let className = "";
    let roomName = "";
    let details = [];

    const staffDataArray = resultStaffList.filter(
      (obj) => Number(obj.staffId) === Number(staffId),
    );

    if (staffDataArray.length > 0) {
      const staffData = staffDataArray[0]; // Take the first element from the filtered array

      if (
        staffData.staffScheduleList &&
        Array.isArray(staffData.staffScheduleList)
      ) {
        const scheduleData = staffData.staffScheduleList.find((val3) => {
          return Number(val3.dayId) === dayId && Number(val3.timeId) === timeId;
        });

        if (scheduleData) {
          let classIds = [];
          let courseIds = [];
          let roomIds = [];
          Object.values(scheduleData)[2]
            .filter((obj) => obj.combinedClassId === null)
            .map((val) => {
              courseName = getClassCourseAssignmentName(
                val.classCourseAssignmentId,
              );
              roomName = getRoomName(val.roomId);
              className = getClassName(val.classId);
              details.push(courseName);
              details.push("\n");
              details.push("Class : " + className);
              details.push("\n");
              details.push("Room : " + roomName);
              details.push("\n");
            });
          Object.values(scheduleData)[2]
            .filter((obj) => obj.combinedClassId !== null)
            .map((val) => {
              courseName = getClassCourseAssignmentName(
                val.classCourseAssignmentId,
              );
              roomIds.push(val.roomId);
              classIds.push(val.classId);
              courseIds.push(val.classCourseAssignmentId);
            });
          if (classIds.length > 0) {
            let clsName = "";
            classIds.map((id, i) => {
              if (i === 0) {
                clsName = getClassName(id);
              } else {
                clsName = clsName + "," + getClassName(id);
              }
            });
            courseName = getClassCourseAssignmentName(courseIds[0]);
            roomName = getRoomName(roomIds[0]);
            details.push(courseName);
            details.push("\n");
            details.push("Class : " + clsName);
            details.push("\n");
            details.push("Room : " + roomName);
            details.push("\n");
          }
        }
      }
    }

    return details;
  };

  // Function used for getting Teaching hours
  const getTeachingHours = (staffId) => {
    let totalTeachingHours = 0;

    staffCourseAssignment
      .filter((staff) => staff.staffId === staffId)
      .forEach((staff) => {
        totalTeachingHours += staff.teachingHours;
      });

    return totalTeachingHours;
  };

  // Function used for Printing Whole PDF
  const printPdf = () => {
    const doc = new jsPDF({ orientation: "landscape", format: "legal" });
    doc.setFontSize(12);
    doc.setFont("Times-Bold");
    var width = doc.internal.pageSize.getWidth();
    for (const deptWise of departmentList) {
      let deptClassList = classList.filter(
        (obj) => obj.departmentId === deptWise.id,
      );
      let deptStaffList = staffList.filter(
        (obj) => obj.departmentId === deptWise.id,
      );

      for (const obj of deptClassList) {
        let tr = [];
        let rows = [];
        let col = [
          "Day / Time",
          "09:30-10:30",
          "10:30-11:30",
          "11:30-12:30",
          "01:30-02:30",
          "02:30-03:30",
        ];
        let k = 1;
        doc.setFontSize(18);
        doc.addImage(VVVLogo, "PNG", 10, 5, 15, 15);
        doc.addImage(CampusPlannerLogo, "JPG", 320, 5, 25, 15);
        doc.text(
          "V.V.Vanniaperumal College for Women(Autonomous)",
          width / 2,
          15,
          {
            align: "center",
          },
        );
        doc.setFontSize(13);

        doc.text(getDepartmentName(obj.departmentId), width / 2, 25, {
          align: "center",
        });

        doc.text("Class : " + obj.className, 14, 30, {
          align: "left",
        });

        dayList.map((day) => {
          tr.push(k++);
          timeList.map((time) => {
            tr.push(getDayAndTimeWiseClass(obj.id, day.id, time.id).join(" "));
          });
        });
        while (tr.length) {
          rows.push(tr.splice(0, col.length));
        }
        doc.autoTable(col, rows, {
          theme: "grid",
          startY: 35,
          halign: "center",
          valign: "middle",
          styles: {
            fontSize: 10,
            minCellHeight: 20,
          },
        });

        doc.addPage();
      }
      for (const obj of deptStaffList) {
        let tr = [];
        let rows = [];
        let col = [
          "Day / Time",
          "09:30-10:30",
          "10:30-11:30",
          "11:30-12:30",
          "01:30-02:30",
          "02:30-03:30",
        ];
        let k = 1;
        doc.setFontSize(18);
        doc.addImage(VVVLogo, "PNG", 10, 5, 15, 15);
        doc.addImage(CampusPlannerLogo, "JPG", 320, 5, 25, 15);
        doc.text(
          "V.V.Vanniaperumal College for Women(Autonomous)",
          width / 2,
          15,
          {
            align: "center",
          },
        );
        doc.setFontSize(13);

        doc.text(getDepartmentName(obj.departmentId), width / 2, 25, {
          align: "center",
        });

        let staffName =
          "Staff : " +
          obj.staffName +
          "(" +
          obj.staffCode +
          ")," +
          " No. of Teaching Hours : " +
          getTeachingHours(obj.id);

        doc.text(staffName, 14, 30, {
          align: "left",
        });

        dayList.map((day) => {
          tr.push(k++);
          timeList.map((time) => {
            tr.push(getDayAndTimeWiseStaff(obj.id, day.id, time.id).join(" "));
          });
        });
        while (tr.length) {
          rows.push(tr.splice(0, col.length));
        }
        doc.autoTable(col, rows, {
          theme: "grid",
          startY: 35,
          halign: "center",
          valign: "middle",
          styles: {
            fontSize: 10,
            minCellHeight: 20,
          },
        });

        doc.addPage();
      }
    }
    doc.addPage();
    for (const obj of addNewRoom) {
      let tr = [];
      let rows = [];
      let col = [
        "Day / Time",
        "09:30-10:30",
        "10:30-11:30",
        "11:30-12:30",
        "01:30-02:30",
        "02:30-03:30",
      ];
      let k = 1;
      doc.setFontSize(18);
      doc.addImage(VVVLogo, "PNG", 10, 5, 15, 15);
      doc.addImage(CampusPlannerLogo, "JPG", 320, 5, 25, 15);
      doc.text(
        "V.V.Vanniaperumal College for Women(Autonomous)",
        width / 2,
        15,
        {
          align: "center",
        },
      );
      doc.setFontSize(13);

      doc.text(getBuildingName(obj.buildingId), width / 2, 25, {
        align: "center",
      });

      doc.text("Room : " + obj.roomName, 14, 30, {
        align: "left",
      });

      dayList.map((day) => {
        tr.push(k++);
        timeList.map((time) => {
          tr.push(getDayAndTimeWiseRoom(obj.id, day.id, time.id).join(" "));
        });
      });
      while (tr.length) {
        rows.push(tr.splice(0, col.length));
      }
      doc.autoTable(col, rows, {
        theme: "grid",
        startY: 35,
        halign: "center",
        valign: "middle",
        styles: {
          fontSize: 10,
          minCellHeight: 20,
        },
      });

      if (obj !== addNewRoom[addNewRoom.length - 1]) {
        doc.addPage();
      }
    }

    doc.save("timetable.pdf");
  };

  // Function used for Printing Class PDF
  const printClassPdf = () => {
    const doc = new jsPDF({ orientation: "landscape", format: "legal" });
    doc.setFontSize(12);
    doc.setFont("Times-Bold");
    var width = doc.internal.pageSize.getWidth();
    for (const obj of classList) {
      let tr = [];
      let rows = [];
      let col = [
        "Day / Time",
        "09:30-10:30",
        "10:30-11:30",
        "11:30-12:30",
        "01:30-02:30",
        "02:30-03:30",
      ];
      let k = 1;
      doc.setFontSize(18);
      doc.addImage(VVVLogo, "PNG", 10, 5, 15, 15);
      doc.addImage(CampusPlannerLogo, "JPG", 320, 5, 25, 15);
      doc.text(
        "V.V.Vanniaperumal College for Women(Autonomous)",
        width / 2,
        15,
        {
          align: "center",
        },
      );
      doc.setFontSize(13);

      doc.text(getDepartmentName(obj.departmentId), width / 2, 25, {
        align: "center",
      });

      doc.text("Class : " + obj.className, 14, 30, {
        align: "left",
      });

      dayList.map((day) => {
        tr.push(k++);
        timeList.map((time) => {
          tr.push(getDayAndTimeWiseClass(obj.id, day.id, time.id).join(" "));
        });
      });
      while (tr.length) {
        rows.push(tr.splice(0, col.length));
      }
      doc.autoTable(col, rows, {
        theme: "grid",
        startY: 35,
        halign: "center",
        valign: "middle",
        styles: {
          fontSize: 10,
          minCellHeight: 20,
        },
      });

      if (obj !== classList[classList.length - 1]) {
        doc.addPage();
      }
    }

    doc.save("class.pdf");
  };

  // Function used for Printing Staff PDF
  const printStaffPdf = () => {
    const doc = new jsPDF({ orientation: "landscape", format: "legal" });
    doc.setFontSize(12);
    doc.setFont("Times-Bold");
    var width = doc.internal.pageSize.getWidth();

    for (const obj of staffList) {
      let tr = [];
      let rows = [];
      let col = [
        "Day / Time",
        "09:30-10:30",
        "10:30-11:30",
        "11:30-12:30",
        "01:30-02:30",
        "02:30-03:30",
      ];
      let k = 1;
      doc.setFontSize(18);
      doc.addImage(VVVLogo, "PNG", 10, 5, 15, 15);
      doc.addImage(CampusPlannerLogo, "JPG", 320, 5, 25, 15);
      doc.text(
        "V.V.Vanniaperumal College for Women(Autonomous)",
        width / 2,
        15,
        {
          align: "center",
        },
      );
      doc.setFontSize(13);

      doc.text(getDepartmentName(obj.departmentId), width / 2, 25, {
        align: "center",
      });

      let staffName =
        "Staff : " +
        obj.staffName +
        "(" +
        obj.staffCode +
        ")," +
        " No. of Teaching Hours : " +
        getTeachingHours(obj.id);

      doc.text(staffName, 14, 30, {
        align: "left",
      });

      dayList.map((day) => {
        tr.push(k++);
        timeList.map((time) => {
          tr.push(getDayAndTimeWiseStaff(obj.id, day.id, time.id).join(" "));
        });
      });
      while (tr.length) {
        rows.push(tr.splice(0, col.length));
      }
      doc.autoTable(col, rows, {
        theme: "grid",
        startY: 35,
        halign: "center",
        valign: "middle",
        styles: {
          fontSize: 10,
          minCellHeight: 20,
        },
      });

      if (obj !== staffList[staffList.length - 1]) {
        doc.addPage();
      }
    }
    doc.save("staff.pdf");
  };

  // Function used for Printing Room PDF
  const printRoomPdf = () => {
    const doc = new jsPDF({ orientation: "landscape", format: "legal" });
    doc.setFontSize(12);
    doc.setFont("Times-Bold");
    var width = doc.internal.pageSize.getWidth();
    for (const obj of addNewRoom) {
      let tr = [];
      let rows = [];
      let col = [
        "Day / Time",
        "09:30-10:30",
        "10:30-11:30",
        "11:30-12:30",
        "01:30-02:30",
        "02:30-03:30",
      ];
      let k = 1;
      doc.setFontSize(18);
      doc.addImage(VVVLogo, "PNG", 10, 5, 15, 15);
      doc.addImage(CampusPlannerLogo, "JPG", 320, 5, 25, 15);
      doc.text(
        "V.V.Vanniaperumal College for Women(Autonomous)",
        width / 2,
        15,
        {
          align: "center",
        },
      );
      doc.setFontSize(13);

      doc.text(getBuildingName(obj.buildingId), width / 2, 25, {
        align: "center",
      });

      doc.text("Room : " + obj.roomName, 14, 30, {
        align: "left",
      });

      dayList.map((day) => {
        tr.push(k++);
        timeList.map((time) => {
          tr.push(getDayAndTimeWiseRoom(obj.id, day.id, time.id).join(" "));
        });
      });
      while (tr.length) {
        rows.push(tr.splice(0, col.length));
      }
      doc.autoTable(col, rows, {
        theme: "grid",
        startY: 35,
        halign: "center",
        valign: "middle",
        styles: {
          fontSize: 10,
          minCellHeight: 20,
        },
      });

      if (obj !== addNewRoom[addNewRoom.length - 1]) {
        doc.addPage();
      }
    }

    doc.save("room.pdf");
  };

  // Function used for Printing Department PDF
  const printDepartmentPdf = (selectedDepartmentId) => {
    const doc = new jsPDF({ orientation: "landscape", format: "legal" });
    doc.setFontSize(12);
    doc.setFont("Times-Bold");
    var width = doc.internal.pageSize.getWidth();

    const selectedDept = departmentList.find(
      (dept) => dept.id === selectedDepartmentId,
    );

    if (selectedDept) {
      // Filter departmentList to include only the selected department
      const filteredDeptList = departmentList.filter(
        (dept) => dept.id === selectedDepartmentId,
      );

      for (const deptWise of filteredDeptList) {
        let deptClassList = classList.filter(
          (obj) => obj.departmentId === deptWise.id,
        );
        let deptStaffList = staffList.filter(
          (obj) => obj.departmentId === deptWise.id,
        );

        for (const obj of deptClassList) {
          let tr = [];
          let rows = [];
          let col = [
            "Day / Time",
            "09:30-10:30",
            "10:30-11:30",
            "11:30-12:30",
            "01:30-02:30",
            "02:30-03:30",
          ];
          let k = 1;
          doc.setFontSize(18);
          doc.addImage(VVVLogo, "PNG", 10, 5, 15, 15);
          doc.addImage(CampusPlannerLogo, "JPG", 320, 5, 25, 15);
          doc.text(
            "V.V.Vanniaperumal College for Women(Autonomous)",
            width / 2,
            15,
            {
              align: "center",
            },
          );
          doc.setFontSize(13);

          doc.text(getDepartmentName(obj.departmentId), width / 2, 25, {
            align: "center",
          });

          doc.text("Class : " + obj.className, 14, 30, {
            align: "left",
          });

          dayList.map((day) => {
            tr.push(k++);
            timeList.map((time) => {
              tr.push(
                getDayAndTimeWiseClass(obj.id, day.id, time.id).join(" "),
              );
            });
          });
          while (tr.length) {
            rows.push(tr.splice(0, col.length));
          }
          doc.autoTable(col, rows, {
            theme: "grid",
            startY: 35,
            halign: "center",
            valign: "middle",
            styles: {
              fontSize: 10,
              minCellHeight: 20,
            },
          });

          doc.addPage();
        }
        for (const obj of deptStaffList) {
          let tr = [];
          let rows = [];
          let col = [
            "Day / Time",
            "09:30-10:30",
            "10:30-11:30",
            "11:30-12:30",
            "01:30-02:30",
            "02:30-03:30",
          ];
          let k = 1;
          doc.setFontSize(18);
          doc.addImage(VVVLogo, "PNG", 10, 5, 15, 15);
          doc.addImage(CampusPlannerLogo, "JPG", 320, 5, 25, 15);
          doc.text(
            "V.V.Vanniaperumal College for Women(Autonomous)",
            width / 2,
            15,
            {
              align: "center",
            },
          );
          doc.setFontSize(13);

          doc.text(getDepartmentName(obj.departmentId), width / 2, 25, {
            align: "center",
          });

          let staffName =
            "Staff : " +
            obj.staffName +
            "(" +
            obj.staffCode +
            ")," +
            " No. of Teaching Hours : " +
            getTeachingHours(obj.id);

          doc.text(staffName, 14, 30, {
            align: "left",
          });

          dayList.map((day) => {
            tr.push(k++);
            timeList.map((time) => {
              tr.push(
                getDayAndTimeWiseStaff(obj.id, day.id, time.id).join(" "),
              );
            });
          });
          while (tr.length) {
            rows.push(tr.splice(0, col.length));
          }
          doc.autoTable(col, rows, {
            theme: "grid",
            startY: 35,
            halign: "center",
            valign: "middle",
            styles: {
              fontSize: 10,
              minCellHeight: 20,
            },
          });

          doc.addPage();
        }
      }
    }

    doc.save(selectedDept.departmentName + ".pdf");
  };

  // Download button options
  const options = [
    "Download PDF",
    "Download Class PDF",
    "Download Staff PDF",
    "Download Room PDF",
  ];

  // Function used for Download button
  const handleClick = () => {
    if (selectedIndex === 0) {
      printPdf();
    } else if (selectedIndex === 1) {
      printClassPdf();
    } else if (selectedIndex === 2) {
      printStaffPdf();
    } else if (selectedIndex === 3) {
      printRoomPdf();
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box className="fixedslot-box-end">
      <Grid
        sx={{
          mt: 0.75,
          mr: 3,
        }}
      >
        <Stack direction="row" spacing={2}>
          {algorithmStatus === "COMPLETED" && (
            <React.Fragment>
              <FormControl className="department-dropdown-margin">
                <Select
                  value={selectedDepartment}
                  onChange={handleSelectDepartment}
                  size="small"
                  displayEmpty
                  className="department-dropdown"
                >
                  <MenuItem value={0} disabled>
                    Choose Department
                  </MenuItem>
                  {departmentOptions.map((option, id) => (
                    <MenuItem key={id} value={option.id}>
                      {option.departmentName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Tooltip title="Download Department PDF" placement="bottom">
                <IconButton
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    printDepartmentPdf(selectedDepartment);
                  }}
                  className={
                    selectedDepartment === 0
                      ? "departmentpdf-download-button-disabled"
                      : "departmentpdf-download-button"
                  }
                  disabled={selectedDepartment === 0}
                >
                  <DownloadIcon className="view-download-icon" />
                </IconButton>
              </Tooltip>

              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="split button"
                className="pdf-button"
              >
                <Button
                  className={selectedIndex !== 0 ? "pdf-width2" : "pdf-width1"}
                  onClick={handleClick}
                  startIcon={<LocalPrintshop />}
                >
                  {options[selectedIndex]}
                </Button>
                <Button
                  size="small"
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                  className="download-pdf-button"
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 999,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    className={
                      placement === "bottom"
                        ? "customTransformBottom"
                        : "customTransform"
                    }
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              selected={index === selectedIndex}
                              onClick={(event) => {
                                handleMenuItemClick(event, index);
                              }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </React.Fragment>
          )}
          <Tooltip title="Overall Count" placement="bottom">
            <IconButton
              type="submit"
              variant="contained"
              onClick={() => {
                dispatch(ViewOverallCount(true));
              }}
              className="viewCount-iconButton"
            >
              <InfoIcon className="view-download-icon" />
            </IconButton>
          </Tooltip>
          {(algorithmStatus === "UNKNOWN" || algorithmStatus === null) &&
            warningsList.length === 0 && (
              <Button
                className={
                  handleGenerateLoader === true
                    ? "generate-timetable-disabled"
                    : "generate-timetable"
                }
                onClick={() => {
                  runalgorithm();
                  dispatch(HandleGenerateLoader(true));
                }}
                variant="contained"
                disabled={handleGenerateLoader === true}
              >
                Generate Timetable
              </Button>
            )}
          {(algorithmStatus === "NOT_CREATED" ||
            algorithmStatus === "RUNNING" ||
            algorithmStatus === "CREATED") &&
            warningsList.length === 0 && (
              <Button
                className="algorithm-running"
                variant="contained"
                disabled
              >
                <b>Running...</b>
                <img
                  src={LoadingGif}
                  alt="loading..."
                  className="algorithm-button__loader"
                />
              </Button>
            )}
          {algorithmStatus === "COMPLETED" && warningsList.length === 0 && (
            <Button
              className={
                handleLoader === true
                  ? "algorithm-completed-disabled"
                  : "algorithm-completed"
              }
              onClick={() => {
                dispatch(ClearResultConfirm(true));
              }}
              variant="contained"
              disabled={
                handleLoader === true || semester.planStatus === "PLANNED"
              }
            >
              Clear Result
            </Button>
          )}
          {(algorithmStatus === "FAILED" || algorithmStatus === "UNKNOWN") &&
            warningsList.length === 0 && (
              <Button
                className="algorithm-tryagain"
                onClick={() => {
                  dispatch(HandleFailedModel(true));
                }}
                variant="contained"
                disabled={handleLoader === true}
              >
                Try Again
              </Button>
            )}
        </Stack>
      </Grid>
    </Box>
  );
}
