import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GanttChart from "../GanttCharts/GanttChart";
import Loader from "../../../Loader/Loader";
import { useSelector } from "react-redux";

// ==============================|| FIXED SLOT STAFF ACCORDION ||============================== //

export default function FixedslotStaffAccordion({
  searchDepartmentList,
  expandedMainAccordion,
  handleAccordion,
  expandedAccordion,
  handleClassAccordion,
  statusCode,
  ganttStaffItems,
  ganttGroup,
  value,
}) {
  const staff_menu = useSelector((state) => state.staff_menu);
  const staffList = staff_menu.staffList;

  return (
    <div className="accordion-style">
      {searchDepartmentList.map((dept) => (
        <Accordion
          key={dept.id}
          expanded={expandedMainAccordion.includes(dept.id)}
          onChange={handleAccordion(dept.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${dept.id}-content`}
            id={`panel${dept.id}-header`}
          >
            <Typography>{dept.departmentName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {staffList
              .filter((staffData) => staffData.departmentId === dept.id)
              .map((staff) => (
                <Accordion
                  key={staff.id}
                  expanded={expandedAccordion.includes(staff.id)}
                  onChange={handleClassAccordion(staff.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${staff.id}-content`}
                    id={`panel${staff.id}-header`}
                  >
                    <Typography>
                      {staff.staffName} ({staff.staffCode})
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {statusCode === false ? (
                        <Loader />
                      ) : (
                        ganttStaffItems
                          .filter((ganttdata) => ganttdata.id === staff.id)
                          .map((result) => (
                            <Typography
                              sx={{ mt: -1.5, ml: 2 }}
                              key={result.id}
                            >
                              <GanttChart
                                ganttGroup={ganttGroup}
                                ganttItems={ganttStaffItems}
                                resultItems={result.timeLineItem}
                                value={value}
                              />
                            </Typography>
                          ))
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
