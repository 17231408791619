let API_URL = "";
let WEB_URL = window.location.hostname;

if (WEB_URL === "localhost") {
  // API_URL = "http://localhost:8000/api/v1";
  API_URL = " https://timetablingdevelopapi.campusplanner.in/api/v1";
} else if (WEB_URL === "timetablingdevelop.campusplanner.in") {
  API_URL = " https://timetablingdevelopapi.campusplanner.in/api/v1";
} else if (WEB_URL === "timetabling.campusplanner.in") {
  API_URL = " https://timetablingapi.campusplanner.in/api/v1";
}

export default API_URL;
