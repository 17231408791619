/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddFixedSlotModel } from "../../../Store/Reducers/fixedslot_menu";
import AddFixedSlot from "./FixedSlot Models/AddFixedSlot";
import {
  Stack,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Box,
} from "@mui/material";
import FixedSlotService from "../../../Services/FixedSlotService";
import FixedSlotGroupList from "./FixedSlotGroupList";
import Loader from "../../Loader/Loader";
import AlgorithmWarnings from "../Timetable/Warnings/AlgorithmWarnings";
import FixedSlotTabs from "./FixedSlotTabs";
import { FixedSlotsGroup } from "../../../Store/Reducers/fixedslot_menu";
import SemesterSelect from "../../SemesterSelectWarning/SemesterSelect";
import "./FixedSlots.css";

// ==============================|| FIXED SLOTS MAIN PAGE ||============================== //

const FixedSlots = () => {
  const dispatch = useDispatch();

  const menu = useSelector((state) => state.fixedslot_menu);
  const addFixedSlotModel = menu.addFixedSlotModel;
  const fixedSlotsGroup = menu.fixedSlotsGroup;
  const isFixedSlotStatusCode = menu.isFixedSlotStatusCode;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const addNewRoom = infrastructure_menu.addNewRoom;
  const isBuildingStatusCode = infrastructure_menu.isBuildingStatusCode;
  const isDepartmentStatusCode = infrastructure_menu.isDepartmentStatusCode;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;
  const isCourseStatusCode = course_menu.isCourseStatusCode;

  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;
  const classCourseAssignment = class_menu.classCourseAssignmentAll;
  const isClassStatusCode = class_menu.isClassStatusCode;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;
  const isCombinedClassStatusCode =
    combined_class_menu.isCombinedClassStatusCode;

  const staff_menu = useSelector((state) => state.staff_menu);
  const staffCourseAssignment = staff_menu.staffCourseAssignmentAll;
  const staffList = staff_menu.staffList;
  const isStaffStatusCode = menu.isStaffStatusCode;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [fixedSlot, setFixedSlot] = useState([]);
  const [fixedSlotInfo, setFixedSlotInfo] = useState([]);
  const [fixedSlotGroupId, setFixedSlotGroupId] = useState(null);
  const [classId, setClassId] = useState(0);
  const [classCourseId, setClassCourseId] = useState(0);
  const [combinedClassId, setCombinedClassId] = useState(0);
  const [availableHours, setAvailableHours] = useState(0);
  const [possibleRoomsCount, setPossibleRoomsCount] = useState(0);
  const [possibleRooms, setPossibleRooms] = useState([]);
  const [selectRooms, setSelectRooms] = useState(
    Array(possibleRoomsCount).fill(null),
  );
  const [availableStaffSelect, setAvailableStaffSelect] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [selectedStaff, setSelectedStaff] = useState(
    Array(possibleRoomsCount).fill([]),
  );
  const [dayId, setDayId] = useState("");
  const [timeId, setTimeId] = useState("");
  const [fixedSlotId, setfixedSlotId] = useState("");
  const [availableStaffName, setAvailableStaffName] = useState([]);
  const [availableStaffHours, setAvailableStaffHours] = useState([]);
  const [editSlot, setEditSlot] = useState(null);
  const [editSelectedRooms, setEditSelectedRooms] = useState([]);
  const [editSelectedStaffs, setEditSelectedStaffs] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [roomIdArr, setRoomIdArr] = useState([]);
  const [editRoomIdArr, setEditRoomIdArr] = useState([]);
  const [savedCheckboxes, setSavedCheckboxes] = useState({});
  const [confirmationShown, setConfirmationShown] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [possibleRoomsCountEdit, setPossibleRoomsCountEdit] = useState();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [handleErrorEdit, setHandleErrorEdit] = useState();
  const [handleErrorAdd, setHandleErrorAdd] = useState();
  const [emptyError, setEmptyError] = useState("");
  const [fixedSlotLoader, setFixedSlotLoader] = useState(false);

  const classCourseOptions = [...classCourseAssignment];
  const combinedClassOptions = [...classCombinedList];

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function used to set the particular fixedSlot id and fixedSlot info
  const handleFixedSlotGroupClick = (fixedSlotId) => {
    setFixedSlotInfo([]);
    FixedSlotService.getFixedSlot(fixedSlotId).then((res) => {
      if (res.status === 200) {
        setfixedSlotId(res.data.id);
        setFixedSlotInfo(res.data);
        setFixedSlotGroupId(fixedSlotId);

        const updatedFixedSlotsGroup = fixedSlotsGroup.map((group) => {
          if (group.id === fixedSlotId) {
            return { ...group, ...res.data }; // Update the specific group with fetched data
          }
          return group;
        });
        // Dispatch the updated fixed slots group list to the store
        dispatch(FixedSlotsGroup(updatedFixedSlotsGroup));
        setFixedSlotLoader(false);
      }
    });
  };

  // Storing dayId and timeId
  useEffect(() => {
    setSelectedKeys(Object.keys(selectedValue));
    let dayId = "";
    let timeId = "";

    for (const key of selectedKeys) {
      const [day, time] = key.split(",");
      dayId = day;
      timeId = time;
    }

    if (editSlot === null) {
      setDayId(dayId);
      setTimeId(timeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSlot, selectedKeys, selectedValue]);

  // Function used to details of selected  class course
  const handleSelectClassCourse = (newvalue) => {
    setClassCourseId(newvalue.id);
    setCombinedClassId(0);
    setAvailableHours(0);
    setSelectRooms([]);
    setEditSelectedRooms([]);
    setEditSelectedStaffs([]);
    setPossibleRooms([]);
    setAvailableStaffSelect([]);
    setAvailableStaffName([]);
    setAvailableStaffHours([]);
    setSelectedValue({});
    setEditSlot(null);
    setSavedCheckboxes({});
    setFixedSlot([]);
    setSelectedCheckboxes([]);

    classCourseAssignment
      .filter((clsCourse) => clsCourse.id === newvalue.id)
      .map((resClassCourse) => {
        courseList
          .filter((course) => course.id === resClassCourse.courseId)
          .map((res) => {
            setAvailableHours(res.courseHours);
          });

        setPossibleRoomsCount(resClassCourse.numberOfRoomsRequired);

        addNewRoom
          .filter((room) => resClassCourse.possibleRooms.includes(room.id))
          .forEach((res) => {
            setPossibleRooms((prevPossibleRooms) => [
              ...prevPossibleRooms,
              res.roomName,
            ]);
          });
      });

    const matchingStaffCourses = staffCourseAssignment.filter(
      (staffCourse) => staffCourse.classCourseAssignmentId === newvalue.id,
    );

    if (matchingStaffCourses.length > 0) {
      const matchingStaffIds = matchingStaffCourses
        .sort((a, b) => a.staffId - b.staffId)
        .map((staffCourse) => staffCourse.staffId);
      let namesArray = [];
      for (let i = 0; i < matchingStaffIds.length; i++) {
        let matchingStaffNames = staffList
          .filter((staff) => staff.id === matchingStaffIds[i])
          .map((staff) => staff.staffName);
        namesArray.push(matchingStaffNames);
      }
      let names = namesArray.flat();
      let hoursArray = [];
      for (let i = 0; i < matchingStaffIds.length; i++) {
        let matchingHours = matchingStaffCourses
          .filter((item) => item.staffId === matchingStaffIds[i])
          .map((obj) => obj.teachingHours);
        hoursArray.push(matchingHours);
      }
      let hours = hoursArray.flat();

      setAvailableStaffHours(hours);
      if (names.length > 0) {
        setAvailableStaffSelect(names);
        setAvailableStaffName(names);
      }
    }
  };

  //Function to retrive details from classCourseAssignmentId for displaying in List(RHS)
  const getClassCourseId = (id) => {
    return classCourseAssignment
      .filter((cls) => cls.id === id)
      .map((res) => getAllClassCourseName(res.classId, res.courseId));
  };

  //Function to get the Class Name and Course Name to display class course in menu box
  const getAllClassCourseName = (classId, courseId) => {
    const classNames = [];
    const courseNames = [];

    classList
      .filter((cls) => cls.id === classId)
      .map((resCls) => {
        classNames.push(resCls.className);
      });

    courseList
      .filter((course) => course.id === courseId)
      .map((resCourse) => {
        courseNames.push(resCourse.courseName);
      });

    // Combine class names and course names into a single string
    const combinedNames = classNames.map((className, index) => {
      const formattedName = `${className} - ${courseNames[index]}`;
      return formattedName;
    });
    return combinedNames;
  };

  // Function used to select details of selected combined course
  const handleSelectCombinedClass = (newvalue) => {
    setCombinedClassId(newvalue.id);
    setClassCourseId(0);
    setAvailableHours(0);
    setPossibleRooms([]);
    setAvailableStaffSelect([]);
    setSelectRooms([]);
    setEditSelectedRooms([]);
    setEditSelectedStaffs([]);
    setPossibleRooms([]);
    setAvailableStaffSelect([]);
    setAvailableStaffName([]);
    setAvailableStaffHours([]);
    setSelectedValue({});
    setEditSlot(null);
    setSavedCheckboxes({});
    setFixedSlot([]);
    setSelectedCheckboxes([]);

    classCombinedList
      .filter((clsCourse) => clsCourse.id === newvalue.id)
      .map((resClassCourse) => {
        courseList
          .filter((course) => course.id === resClassCourse.courseId)
          .map((res) => {
            setAvailableHours(res.courseHours);
          });
        setPossibleRoomsCount(resClassCourse.numberOfRoomsRequired);

        addNewRoom
          .filter((room) => resClassCourse.possibleRooms.includes(room.id))
          .forEach((res) => {
            setPossibleRooms((prevPossibleRooms) => [
              ...prevPossibleRooms,
              res.roomName,
            ]);
          });
      });

    const matchingStaffCourses = staffCourseAssignment.filter(
      (staffCourse) => staffCourse.combinedClassId === newvalue.id,
    );

    if (matchingStaffCourses.length > 0) {
      const matchingStaffIds = matchingStaffCourses
        .sort((a, b) => a.staffId - b.staffId)
        .map((staffCourse) => staffCourse.staffId);
      let namesArray = [];
      for (let i = 0; i < matchingStaffIds.length; i++) {
        let matchingStaffNames = staffList
          .filter((staff) => staff.id === matchingStaffIds[i])
          .map((staff) => staff.staffName);
        namesArray.push(matchingStaffNames);
      }
      let names = namesArray.flat();
      let hoursArray = [];
      for (let i = 0; i < matchingStaffIds.length; i++) {
        let matchingHours = matchingStaffCourses
          .filter((item) => item.staffId === matchingStaffIds[i])
          .map((obj) => obj.teachingHours);
        hoursArray.push(matchingHours);
      }
      let hours = hoursArray.flat();

      setAvailableStaffHours(hours);
      if (names.length > 0) {
        setAvailableStaffSelect(names);
        setAvailableStaffName(names);
      }
    }
  };

  //Function to retrive details from combinedClassId for displaying in List(RHS)
  const getCombinedClassId = (id) => {
    return classCombinedList
      .filter((cls) => cls.id === id)
      .map((res) => getAllCombinedClassName(res.courseId, res.id));
  };

  //Function to get the Class Name and Course Name to display in combined class menu box
  const getAllCombinedClassName = (courseId, cid) => {
    const classNames = [];
    const courseName = courseList.find(
      (course) => course.id === courseId,
    )?.courseName;

    const matchingClasses = combinedClassOptions.filter(
      (res) => res.id === cid,
    );

    matchingClasses.forEach((matchingClass) => {
      const formattedName = `${matchingClass.combinedClassName} - ${courseName}`;
      classNames.push(formattedName);
    });

    return classNames;
  };

  // Function used to handle the Checkbox values
  const handleChange = (event, cellName) => {
    const { checked } = event.target;

    if (savedCheckboxes[cellName] && !checked && !confirmationShown) {
      if (!window.confirm("Are you sure you want to Delete this Fixed slot?")) {
        return;
      }
      setEditSlot(null);
      setConfirmationShown(true);
    }

    setSelectedValue((prevSelectedValues) => {
      const updatedValues = { ...prevSelectedValues };
      if (checked) {
        updatedValues[cellName] = true;
        // Reset the confirmationShown flag when the checkbox is checked
        setConfirmationShown(false);
      } else {
        const [dayId, timeId] = cellName.split(",");
        setFixedSlot((prevFixedSlot) => {
          return prevFixedSlot.filter((slot) => {
            return !(slot.dayId === dayId && slot.timeId === timeId);
          });
        });

        delete updatedValues[cellName];
        if (savedCheckboxes[cellName]) {
          setSavedCheckboxes((prevSavedCheckboxes) => {
            const updated = { ...prevSavedCheckboxes };
            delete updated[cellName];
            return updated;
          });
        }
        // Reset the confirmationShown flag when the checkbox is unchecked
        setConfirmationShown(false);
      }
      return updatedValues;
    });
  };

  // Storing selected room ids used for select field
  const roomIds =
    editSlot && editSlot.staffAndRoomDetails
      ? editSlot.staffAndRoomDetails.map((item) => item.roomId)
      : [];
  const roomNames = roomIds.map((roomId) => {
    const room = addNewRoom.find((room) => room.id === roomId);
    return room ? room.roomName : "";
  });

  // Select field for Rooms
  const selectAvailableRooms = Array.from(
    { length: possibleRoomsCount },
    (_, index) => (
      <FormControl key={index} sx={{ m: 1, width: 300 }}>
        <Select
          labelId={`demo-multiple-name-label-${index}`}
          id={`possibleRooms-${index}`}
          value={selectRooms[index] || roomNames[index] || []}
          onChange={(event) => handleChangeRooms(event, index)}
          className="fixedslot-dropdown"
          required
          disabled={
            (possibleRooms.length === 0 ||
              fixedSlot.length === availableHours) &&
            editSlot === null
          }
        >
          {possibleRooms.map((room, roomIndex) => (
            <MenuItem key={roomIndex} value={room}>
              {room}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
  );

  // Define the function to handle room changes
  const handleChangeRooms = (event, index) => {
    setEmptyError();
    setHandleErrorEdit();

    const newValue = event.target.value;

    if (editSlot === null) {
      // Storing the selected rooms for adding
      setSelectRooms((prevSelectRoom) => {
        const updatedSelectRooms = [...prevSelectRoom];
        updatedSelectRooms[index] = newValue;
        return updatedSelectRooms;
      });

      const newRoomId = addNewRoom
        .filter((room) => room.roomName === event.target.value)
        .map((resRoom) => resRoom.id);
      setRoomIdArr(newRoomId);
    } else {
      // Update the selected rooms for editing
      setSelectRooms((prevSelectRoom) => {
        const updatedEditSelectedRoom = [...prevSelectRoom];
        updatedEditSelectedRoom[index] = newValue;
        return updatedEditSelectedRoom;
      });

      const editRoomId = addNewRoom
        .filter((room) => room.roomName === event.target.value)
        .map((resRoom) => resRoom.id);
      setEditRoomIdArr(editRoomId);
    }
  };

  // Edit field for Rooms
  const selectAvailableRoomsEdit = Array.from(
    { length: possibleRoomsCountEdit },
    (_, index) => (
      <FormControl key={index} sx={{ m: 1, width: 300 }}>
        <Select
          labelId={`demo-multiple-name-label-${index}`}
          id={`possibleRooms-${index}`}
          value={selectRooms[index] || roomNames[index] || []}
          onChange={(event) => handleChangeRooms(event, index)}
          className="fixedslot-dropdown"
          required
        >
          {possibleRooms.map((room, roomIndex) => (
            <MenuItem key={roomIndex} value={room}>
              {room}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
  );

  // Storing selected staff ids used for select field
  const staffIds =
    editSlot?.staffAndRoomDetails?.map((item) => item.staffIds) || [];
  const staffNames = staffIds.map((staffIdList) => {
    const namesForIds = staffIdList.map((staffId) => {
      const staff = staffList.find((staff) => staff.id === staffId);
      return staff ? staff.staffName : "";
    });
    return namesForIds;
  });

  // Select field for Staffs
  const selectAvailableStaffs = Array.from(
    { length: possibleRoomsCount },
    (_, index) => (
      <FormControl key={index} sx={{ m: 1, width: 300 }}>
        <Select
          labelId={`demo-multiple-name-label-${index}`}
          id={`possibleRooms-${index}`}
          value={selectedStaff[index] || staffNames[index] || []}
          onChange={(event) => handleChangeStaffs(event, index)}
          className="fixedslot-dropdown"
          required
          disabled={availableStaffSelect.length === 0}
          multiple
        >
          {availableStaffSelect.map((staff, staffIndex) => (
            <MenuItem key={staffIndex} value={staff}>
              {staff}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
  );

  // Define the function to handle staff changes
  const handleChangeStaffs = (event, index) => {
    setEmptyError();
    setHandleErrorEdit();

    const newValue = event.target.value;

    if (editSlot === null) {
      // Storing the selected staff for adding
      setSelectedStaff((prevSelectStaff) => {
        const updatedSelectStaff = [...prevSelectStaff];
        updatedSelectStaff[index] = newValue;
        return updatedSelectStaff;
      });
    } else {
      // Update the selected staff for editing
      setSelectedStaff((prevSelectStaff) => {
        const updatedEditSelectedStaff = [...prevSelectStaff];
        updatedEditSelectedStaff[index] = newValue;
        return updatedEditSelectedStaff;
      });
    }
  };

  const staffNameToIdMap = staffList.reduce((map, staff) => {
    map[staff.staffName] = staff.id;
    return map;
  }, {});

  // Storing stff id based on selected staff using add
  const selectedStaffIds = selectedStaff.map((staffNames) => {
    if (Array.isArray(staffNames)) {
      return staffNames.map((name) => staffNameToIdMap[name]);
    } else {
      return [];
    }
  });

  // Storing stff id based on selected staff using edit
  const editedStaffIds = selectedStaff.map((staffNames) => {
    if (Array.isArray(staffNames)) {
      return staffNames.map((name) => staffNameToIdMap[name]);
    } else {
      return [];
    }
  });

  // Edit field for Staffs
  const selectAvailableStaffsEdit = Array.from(
    { length: possibleRoomsCountEdit },
    (_, index) => (
      <FormControl key={index} sx={{ m: 1, width: 300 }}>
        <Select
          labelId={`demo-multiple-name-label-${index}`}
          id={`possibleRooms-${index}`}
          value={selectedStaff[index] || staffNames[index] || []}
          onChange={(event) => handleChangeStaffs(event, index)}
          className="fixedslot-dropdown"
          required
          multiple
        >
          {availableStaffSelect.map((staff, staffIndex) => (
            <MenuItem key={staffIndex} value={staff}>
              {staff}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
  );

  // Function used to handle edit icon to store that particular selected slot
  const handleEditSlot = (dayId, timeId) => {
    dayId = dayId.toString();
    timeId = timeId.toString();
    const slot = fixedSlot.find(
      (slot) => slot.dayId === dayId && slot.timeId === timeId,
    );
    if (slot) {
      const selectedRooms = slot.staffAndRoomDetails.map(
        (detail) => detail.roomId,
      );
      const selectedStaffs = slot.staffAndRoomDetails.map(
        (detail) => detail.staffIds,
      );
      setEditSelectedStaffs(selectedStaffs);
      setEditSelectedRooms(selectedRooms);
      setEditSlot(slot);
    }
  };

  // Function used to save / edit fixed slot
  const handleEditSaveSlot = (dayId, timeId) => {
    if (editSlot !== null) {
      dayId = dayId.toString();
      timeId = timeId.toString();

      // Handle editing a slot
      const editStaffAndRoomData = editSelectedRooms.map((room, index) => ({
        roomId: editRoomIdArr.length !== 0 ? editRoomIdArr[index] : room,
        staffIds:
          editedStaffIds.length === 0
            ? editSelectedStaffs[index]
            : editedStaffIds[index],
      }));

      setFixedSlot((prevFixedSlot) => {
        return prevFixedSlot.map((slot) => {
          if (slot.dayId === dayId && slot.timeId === timeId) {
            return {
              ...slot,
              staffAndRoomDetails: editStaffAndRoomData,
            };
          }

          return slot;
        });
      });

      // Clear the state variables
      setEditSlot(null);
      setEditSelectedRooms([]);
      setEditSelectedStaffs([]);
      setDayId("");
      setTimeId("");
      setSelectRooms([]);
      setSelectedStaff([]);
      setRoomIdArr([]);
      setEditRoomIdArr([]);

      setSavedCheckboxes((prevSavedCheckboxes) => ({
        ...prevSavedCheckboxes,
        [`${dayId},${timeId}`]: true,
      }));
    } else {
      // Handle saving a new slot
      const newStaffAndRoomData = selectRooms.map((room, index) => ({
        roomId: roomIdArr[index],
        staffIds: selectedStaffIds.length === 0 ? [] : selectedStaffIds[index],
      }));

      const newSlotData = {
        id: fixedSlot.length + 1,
        dayId: dayId,
        timeId: timeId,
        staffAndRoomDetails: newStaffAndRoomData,
      };

      const hasInvalidData =
        newSlotData.staffAndRoomDetails.length === 0 ||
        newSlotData.staffAndRoomDetails.some(
          (detail) => !detail.roomId || detail.staffIds.length === 0,
        );

      if (hasInvalidData) {
        setEmptyError("Room ID and Staff IDs must not be empty");
        return;
      }

      setFixedSlot((prevSlotList) => [...prevSlotList, newSlotData]);

      // Clear the state variables
      setDayId("");
      setTimeId("");
      setSelectRooms([]);
      setEditSelectedRooms([]);
      setEditSelectedStaffs([]);
      setSelectedStaff([]);
      setRoomIdArr([]);
      setEditSlot(null);

      setSavedCheckboxes((prevSavedCheckboxes) => ({
        ...prevSavedCheckboxes,
        [`${dayId},${timeId}`]: true,
      }));
    }
  };

  //Function to add fixedSlot Group
  const handleFixedSlotsGroups = () => {
    let data = {
      classCourseAssignmentId: classCourseId === 0 ? null : classCourseId,
      combinedClassId: combinedClassId === 0 ? null : combinedClassId,
      fixedSlotList: fixedSlot,
    };
    FixedSlotService.createFixedSlotGroup(data)
      .then((res) => {
        dispatch(FixedSlotsGroup([...fixedSlotsGroup, res.data]));

        if (res.status === 200) {
          setSelectedValue({});
          setSavedCheckboxes({});
          setAvailableStaffHours([]);
          setAvailableStaffName([]);
        }

        dispatch(AddFixedSlotModel(false));
        setClassId(0);
        setClassCourseId(0);
        setCombinedClassId(0);
      })
      .catch((error) => {
        setHandleErrorAdd(error.response.data);
      });
  };

  // Function used to remove the particular fixedSlot
  const handleRemoveFixedSlotGroup = (fixedSlotGroupId) => {
    FixedSlotService.deleteFixedSlotGroup(fixedSlotGroupId).then((res) => {
      setFixedSlotGroupId(null);

      const removeFixedGroup = fixedSlotsGroup.filter(
        (obj) => obj.id !== fixedSlotGroupId,
      );
      dispatch(FixedSlotsGroup(removeFixedGroup));
    });
  };

  // Function used to save / edit fixed slot
  const handleEditFixedSlot = (dayId, timeId) => {
    if (selectedCheckboxes.length === 0) {
      const editStaffAndRoomData = editSelectedRooms.map((room, index) => ({
        roomId: editRoomIdArr.length !== 0 ? editRoomIdArr[index] : room,
        staffIds:
          editedStaffIds.length === 0
            ? editSelectedStaffs[index]
            : editedStaffIds[index],
      }));

      const updatedFixedSlotsGroup = fixedSlotsGroup.map((grp) => {
        if (grp.id === fixedSlotGroupId) {
          // If the group matches the specified ID, update the fixedSlotList
          const updatedFixedSlotList = grp.fixedSlotList.map((slot) => {
            if (
              Number(slot.dayId) === Number(dayId) &&
              Number(slot.timeId) === Number(timeId)
            ) {
              return {
                ...slot,
                staffAndRoomDetails: editStaffAndRoomData,
              };
            }
            return slot;
          });

          return {
            ...grp,
            fixedSlotList: updatedFixedSlotList,
          };
        }
        return grp;
      });

      dispatch(FixedSlotsGroup(updatedFixedSlotsGroup));

      const foundFixedSlot = updatedFixedSlotsGroup.find(
        (fixed) => fixed.id === fixedSlotInfo.id,
      );

      if (foundFixedSlot) {
        const updatedFixedSlotInfo = {
          ...fixedSlotInfo,
          fixedSlotList: foundFixedSlot.fixedSlotList,
        };

        setFixedSlot(updatedFixedSlotInfo.fixedSlotList);
        setFixedSlotInfo(updatedFixedSlotInfo);
      }

      setEditSelectedRooms([]);
      setEditSelectedStaffs([]);
      setDayId("");
      setTimeId("");
      setSelectRooms([]);
      setSelectedStaff([]);
      setEditSlot(null);

      setSavedCheckboxes((prevSavedCheckboxes) => ({
        ...prevSavedCheckboxes,
        [`${dayId},${timeId}`]: true,
      }));
    } else {
      const combinedData = selectedCheckboxes.map((checkboxes) => ({
        dayId: checkboxes.dayId,
        timeId: checkboxes.timeId,
        staffAndRoomDetails: selectRooms.map((room, index) => ({
          roomId: roomIdArr[index],
          staffIds:
            selectedStaffIds.length === 0 || !selectedStaffIds[index]
              ? []
              : selectedStaffIds[index],
        })),
      }));

      const hasInvalidData = combinedData.some(
        (data) =>
          data.staffAndRoomDetails.length === 0 ||
          data.staffAndRoomDetails.some(
            (detail) => !detail.roomId || detail.staffIds.length === 0,
          ),
      );

      if (hasInvalidData) {
        setEmptyError("Room ID and Staff IDs must not be empty");
        return;
      }

      const updatedFixedSlotsGroup = fixedSlotsGroup.map((grp) => {
        if (grp.id === fixedSlotGroupId) {
          const updatedFixedSlotListNew = grp.fixedSlotList.map((slot) => {
            const matchingCombinedData = combinedData.find(
              (data) =>
                Number(data.dayId) === Number(slot.dayId) &&
                Number(data.timeId) === Number(slot.timeId),
            );
            return {
              ...slot,
              ...matchingCombinedData,
            };
          });

          // Add any new data in combinedData that doesn't exist in fixedSlotList
          const newDataToAdd = combinedData.filter(
            (data) =>
              !updatedFixedSlotListNew.some(
                (slot) =>
                  slot.dayId === data.dayId && slot.timeId === data.timeId,
              ),
          );

          return {
            ...grp,
            fixedSlotList: [...fixedSlot, ...newDataToAdd],
          };
        }
        return grp;
      });

      dispatch(FixedSlotsGroup(updatedFixedSlotsGroup));

      const foundFixedSlot = updatedFixedSlotsGroup.find(
        (fixed) => fixed.id === fixedSlotInfo.id,
      );

      if (foundFixedSlot) {
        const updatedFixedSlotInfo = {
          ...fixedSlotInfo,
          fixedSlotList: foundFixedSlot.fixedSlotList,
        };

        setFixedSlot(updatedFixedSlotInfo.fixedSlotList);
        setFixedSlotInfo(updatedFixedSlotInfo);
      }

      setEditSelectedRooms([]);
      setEditSelectedStaffs([]);
      setDayId("");
      setTimeId("");
      setSelectRooms([]);
      setSelectedStaff([]);
      setEditSlot(null);
    }
  };

  return (
    <>
      {fixedSlotLoader === true && <Loader />}
      {(isBuildingStatusCode === false ||
        isDepartmentStatusCode === false ||
        isCourseStatusCode === false ||
        isClassStatusCode === false ||
        isCombinedClassStatusCode === false ||
        isStaffStatusCode === false ||
        isFixedSlotStatusCode === false ||
        algorithmStatus === "") &&
      sessionStorage.getItem("branchName") !== null ? (
        <Loader />
      ) : (
        <>
          {sessionStorage.getItem("branchName") !== null ? (
            <>
              <div className="display-warnings">
                <AlgorithmWarnings />
              </div>
              <Grid container direction="row" className="fixedslots-area">
                <Grid item sx={{ mt: 0, ml: 3.5 }} className="fixedslots-LHS">
                  <Stack direction="row" className="fixedSlot-stack">
                    <Box className="fixedslot-box-start">
                      <Typography sx={{ mt: 2, ml: 5 }} className="main-font">
                        Fixed Slots
                      </Typography>
                    </Box>
                    <Box className="fixedslot-box-start">
                      <Grid
                        sx={{
                          mt: 1.3,
                          mr: 2,
                        }}
                      >
                        <Button
                          className={
                            algorithmStatus === null ||
                            algorithmStatus === "FAILED" ||
                            algorithmStatus === "UNKNOWN" ||
                            algorithmStatus === "NOT_CREATED"
                              ? "add-fixedslot"
                              : "add-fixedslot-disabled"
                          }
                          disabled={
                            algorithmStatus !== null &&
                            algorithmStatus !== "FAILED" &&
                            algorithmStatus !== "UNKNOWN" &&
                            algorithmStatus !== "NOT_CREATED"
                          }
                          onClick={() => {
                            setAvailableStaffHours([]);
                            setAvailableStaffName([]);
                            setSelectedValue([]);

                            dispatch(AddFixedSlotModel(true));
                          }}
                          variant="contained"
                        >
                          ADD
                        </Button>
                      </Grid>
                    </Box>
                  </Stack>
                  <br></br> <br></br>
                  <Grid sx={{ ml: 1 }}>
                    <FixedSlotGroupList
                      fixedSlotId={fixedSlotId}
                      handleError={handleErrorEdit}
                      setPossibleRooms={setPossibleRooms}
                      setSelectedCheckboxes={setSelectedCheckboxes}
                      setHandleErrorEdit={setHandleErrorEdit}
                      setEditSlot={setEditSlot}
                      setEditSelectedStaffs={setEditSelectedStaffs}
                      setEditSelectedRooms={setEditSelectedRooms}
                      setDayId={setDayId}
                      setTimeId={setTimeId}
                      setSelectRooms={setSelectRooms}
                      setSelectedStaff={setSelectedStaff}
                      setRoomIdArr={setRoomIdArr}
                      setEditRoomIdArr={setEditRoomIdArr}
                      fixedSlotGroupId={fixedSlotGroupId}
                      setFixedSlotGroupId={setFixedSlotGroupId}
                      handleFixedSlotGroupClick={handleFixedSlotGroupClick}
                      getClassCourseId={getClassCourseId}
                      getCombinedClassId={getCombinedClassId}
                      handleClick={handleClick}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      handleRemoveFixedSlotGroup={handleRemoveFixedSlotGroup}
                      classId={classId}
                      classCourseOptions={classCourseOptions}
                      getAllClassCourseName={getAllClassCourseName}
                      combinedClassId={combinedClassId}
                      combinedClassOptions={combinedClassOptions}
                      getAllCombinedClassName={getAllCombinedClassName}
                      dayId={dayId}
                      timeId={timeId}
                      possibleRooms={possibleRooms}
                      fixedSlotInfo={fixedSlotInfo}
                      selectAvailableRoomsEdit={selectAvailableRoomsEdit}
                      selectAvailableStaffsEdit={selectAvailableStaffsEdit}
                      handleEditFixedSlot={handleEditFixedSlot}
                      selectedCheckboxes={selectedCheckboxes}
                      setFixedSlotInfo={setFixedSlotInfo}
                      setFixedSlot={setFixedSlot}
                      setPossibleRoomsCountEdit={setPossibleRoomsCountEdit}
                      setAvailableStaffHours={setAvailableStaffHours}
                      setAvailableStaffSelect={setAvailableStaffSelect}
                      setAvailableStaffName={setAvailableStaffName}
                      fixedSlot={fixedSlot}
                      editSlot={editSlot}
                      emptyError={emptyError}
                      setEmptyError={setEmptyError}
                      setFixedSlotLoader={setFixedSlotLoader}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sx={{
                    mt: 0,
                    ml: 1,
                  }}
                  className="fixedslots-RHS"
                >
                  <FixedSlotTabs />
                </Grid>
              </Grid>
              {addFixedSlotModel === true && (
                <AddFixedSlot
                  handleError={handleErrorAdd}
                  setHandleErrorAdd={setHandleErrorAdd}
                  setClassId={setClassId}
                  setClassCourseId={setClassCourseId}
                  setCombinedClassId={setCombinedClassId}
                  setAvailableHours={setAvailableHours}
                  setEditSelectedStaffs={setEditSelectedStaffs}
                  setEditSelectedRooms={setEditSelectedRooms}
                  setPossibleRooms={setPossibleRooms}
                  setAvailableStaffSelect={setAvailableStaffSelect}
                  setAvailableStaffName={setAvailableStaffName}
                  setAvailableStaffHours={setAvailableStaffHours}
                  setSelectedValue={setSelectedValue}
                  setEditSlot={setEditSlot}
                  setSavedCheckboxes={setSavedCheckboxes}
                  setSelectRooms={setSelectRooms}
                  fixedSlot={fixedSlot}
                  setFixedSlot={setFixedSlot}
                  classId={classId}
                  classCourseId={classCourseId}
                  handleSelectClassCourse={handleSelectClassCourse}
                  classCourseOptions={classCourseOptions}
                  getAllClassCourseName={getAllClassCourseName}
                  combinedClassId={combinedClassId}
                  handleSelectCombinedClass={handleSelectCombinedClass}
                  combinedClassOptions={combinedClassOptions}
                  getAllCombinedClassName={getAllCombinedClassName}
                  availableHours={availableHours}
                  availableStaffName={availableStaffName}
                  availableStaffHours={availableStaffHours}
                  dayId={dayId}
                  timeId={timeId}
                  possibleRoomsCount={possibleRoomsCount}
                  possibleRooms={possibleRooms}
                  selectAvailableRooms={selectAvailableRooms}
                  availableStaffSelect={availableStaffSelect}
                  selectAvailableStaffs={selectAvailableStaffs}
                  handleEditSaveSlot={handleEditSaveSlot}
                  editSlot={editSlot}
                  handleFixedSlotsGroups={handleFixedSlotsGroups}
                  handleChange={handleChange}
                  selectedValue={selectedValue}
                  savedCheckboxes={savedCheckboxes}
                  handleEditSlot={handleEditSlot}
                  setDayId={setDayId}
                  setTimeId={setTimeId}
                  emptyError={emptyError}
                />
              )}
            </>
          ) : (
            <SemesterSelect />
          )}
        </>
      )}
    </>
  );
};

export default FixedSlots;
