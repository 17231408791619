import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http-common";
import { getBranchName } from "../Utils/common";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");
// ========================================|| STAFF COURSE ASSIGNMENT SERVICE ||======================================== //

const getAll = () => {
  return apiService.get("/staffCourseAssignment/getAll", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getById = (id) => {
  return apiService.get(`/staffCourseAssignment/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const createStaffCourseAssignment = (data) => {
  return apiService.post("/staffCourseAssignment", data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const editStaffCourseAssignment = (id, data) => {
  return apiService.put(`/staffCourseAssignment/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const deleteStaffCourseAssignment = (id) => {
  return apiService.delete(`/staffCourseAssignment/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const selectStaffCourseAssignment = (id) => {
  return apiService.get(`/staffCourseAssignment/staff/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const fixedSlotIdExistsInStaffCourseAssignment = (id) => {
  return apiService.get(`/fixedSlotExistsIds/staffCourseAssignment`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const StaffCourseAssignmentService = {
  getAll,
  getById,
  createStaffCourseAssignment,
  editStaffCourseAssignment,
  deleteStaffCourseAssignment,
  selectStaffCourseAssignment,
  fixedSlotIdExistsInStaffCourseAssignment,
};

export default StaffCourseAssignmentService;
