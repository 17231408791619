// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  addCombinedClassModel: false,
  editCombinedClassModel: false,
  deleteCombinedClassModel: false,
  classCombinedList: [],
  combinedId: null,
  combinedInfo: [],
  combinedInFixedSlot: [],
  isCombinedClassStatusCode: false,

  addClassCombinedModel: false,
  deleteClassCombinedModel: false,
  deleteAllClassInCombined: false,
  combinedClassList: [],
};

// ==============================|| SLICE - MENU ||============================== //

const combined_class_menu = createSlice({
  name: "combined_class_menu",
  initialState,
  reducers: {
    AddCombinedClassModel(state, action) {
      state.addCombinedClassModel = action.payload;
    },
    EditCombinedClassModel(state, action) {
      state.editCombinedClassModel = action.payload;
    },
    DeleteCombinedClassModel(state, action) {
      state.deleteCombinedClassModel = action.payload;
    },
    AddClassCombinedList(state, action) {
      state.classCombinedList = action.payload;
    },
    CombinedId(state, action) {
      state.combinedId = action.payload;
    },
    CombinedInfo(state, action) {
      state.combinedInfo = action.payload;
    },
    CombinedInFixedSlot(state, action) {
      state.combinedInFixedSlot = action.payload;
    },
    IsCombinedClassStatusCode(state, action) {
      state.isCombinedClassStatusCode = action.payload;
    },

    AddClassCombinedModel(state, action) {
      state.addClassCombinedModel = action.payload;
    },
    DeleteClassCombinedModel(state, action) {
      state.deleteClassCombinedModel = action.payload;
    },
    DeleteAllClassInCombined(state, action) {
      state.deleteAllClassInCombined = action.payload;
    },
    AddCombinedClassList(state, action) {
      state.combinedClassList = action.payload;
    },
  },
});

export default combined_class_menu.reducer;

export const {
  AddCombinedClassModel,
  EditCombinedClassModel,
  DeleteCombinedClassModel,
  AddClassCombinedList,
  CombinedId,
  CombinedInfo,
  CombinedInFixedSlot,
  IsCombinedClassStatusCode,

  AddClassCombinedModel,
  DeleteClassCombinedModel,
  DeleteAllClassInCombined,
  AddCombinedClassList,
} = combined_class_menu.actions;
