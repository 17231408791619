import React from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Divider,
  Tooltip,
  ListSubheader,
} from "@mui/material";
import {
  EditRoomsModel,
  DeleteRoomsModel,
} from "../../../../Store/Reducers/infrastructure_menu";
import { useSelector, useDispatch } from "react-redux";
import EditRoom from "./Room Models/EditRoom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteRoomConfirmation from "./Room Models/DeleteRoomConfirmation";
import Loader from "../../../Loader/Loader";
import "./RoomList.css";

// ==============================|| RHS : ROOM TABLE ||============================== //

const RoomTableHeader = ({
  handleRoomClick,
  handleRemoveRoom,
  retrieveRoomInFixedSlot,
}) => {
  const menu = useSelector((state) => state.infrastructure_menu);
  const editRoomsModel = menu.editRoomsModel;
  const deleteRoomsModel = menu.deleteRoomsModel;
  const buildingId = menu.buildingId;
  const addNewRoom = menu.addNewRoom;
  const roomId = menu.roomId;
  const roomInFixedSlot = menu.roomInFixedSlot;
  const isRoomStatusCode = menu.isRoomStatusCode;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function used to Show if multiple course allowed at one time is true then Yes, otherwise No
  const handleMultipleCourse = (value) => {
    if (value === "true") {
      return "Yes";
    } else if (value === "false") {
      return "No";
    }
  };

  // Function used for getting room name
  const getRoomName = (id) => {
    const room = addNewRoom.find((res) => res.id === id);

    if (room && roomInFixedSlot.includes(id)) {
      return (
        <span>
          <Tooltip
            title="Can't Edit and Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip title={room.roomName}>
            <span>{room.roomName}</span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip title={room.roomName}>
          <span>{room.roomName}</span>
        </Tooltip>
      );
    }
  };

  return (
    <>
      {isRoomStatusCode === false ? (
        <Loader />
      ) : (
        <Grid item xs={12}>
          <List className="roomlist-style" subheader={<li />}>
            <ListSubheader className="name-textColor">
              <Grid container>
                <Grid item>
                  <Typography>
                    <b> Room Name </b>
                  </Typography>
                </Grid>
                <Grid item sx={{ ml: 9 }}>
                  <Typography>
                    <b> Capacity </b>
                  </Typography>
                </Grid>
                <Grid item sx={{ ml: 12.8 }}>
                  <Typography>
                    <b>
                      Multiple classes<br></br>allowed at one time
                    </b>
                  </Typography>
                </Grid>

                <Divider sx={{ width: "102%" }} />
              </Grid>
            </ListSubheader>
            <div className="list-container roomlist-height">
              {addNewRoom
                .filter((obj) => Number(obj.buildingId) === Number(buildingId))
                .map((val) => (
                  <li key={val.id}>
                    <Button
                      className={
                        roomId === val.id
                          ? "room-content--button--selected room-content--button"
                          : "room-content--button"
                      }
                      onClick={(event) => {
                        handleRoomClick(val.id);
                      }}
                    >
                      <ListItem className="room-list">
                        <Grid item xs={12} sm={4} className="roomlist-items">
                          {getRoomName(val.id)}
                        </Grid>
                        <Grid item xs={12} sm={4} className="roomlist-items">
                          {val.roomCapacity}
                        </Grid>
                        <Grid item xs={12} sm={4} className="roomlist-items">
                          {handleMultipleCourse(
                            val.multipleCourseAllowedAtOnetime.toString(),
                          )}
                        </Grid>

                        <IconButton
                          aria-label="more"
                          onClick={(event) => {
                            handleClick(event);
                          }}
                          aria-haspopup="true"
                          aria-controls="long-menu"
                          className="room-menu-icon"
                        >
                          <MoreVertIcon className="moreVertIcon-style" />
                        </IconButton>
                      </ListItem>
                    </Button>
                  </li>
                ))}
            </div>
          </List>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                dispatch(EditRoomsModel(true));
                handleClose();
              }}
              className="room-menu"
              disabled={
                roomInFixedSlot.includes(roomId) ||
                (algorithmStatus !== null &&
                  algorithmStatus !== "FAILED" &&
                  algorithmStatus !== "UNKNOWN" &&
                  algorithmStatus !== "NOT_CREATED")
              }
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(DeleteRoomsModel(true));
                handleClose();
              }}
              disabled={
                roomInFixedSlot.includes(roomId) ||
                (algorithmStatus !== null &&
                  algorithmStatus !== "FAILED" &&
                  algorithmStatus !== "UNKNOWN" &&
                  algorithmStatus !== "NOT_CREATED")
              }
            >
              Delete
            </MenuItem>
          </Menu>
          {editRoomsModel === true && <EditRoom />}

          {deleteRoomsModel === true && (
            <DeleteRoomConfirmation handleRemoveRoom={handleRemoveRoom} />
          )}
        </Grid>
      )}
    </>
  );
};

export default RoomTableHeader;
