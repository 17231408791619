/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { EditStaffCourseModel } from "../../../../Store/Reducers/staff_menu";
import StaffCourseAssignmentService from "../../../../Services/StaffCourseAssignmentService";
import { AddStaffCourseAssignmentList } from "../../../../Store/Reducers/staff_menu";
import "../Staff.css";

// ==============================|| EDIT STAFF COURSE ASSIGNMENT MODEL ||============================== //

const EditStaffCourseAssignment = () => {
  const menu = useSelector((state) => state.staff_menu);
  const editStaffCourseModel = menu.editStaffCourseModel;
  const staffList = menu.staffList;
  const staffId = menu.staffId;
  const staffCourseAssignment = menu.staffCourseAssignment;
  const staffCourseId = menu.staffCourseId;
  const staffCourseInfo = menu.staffCourseInfo;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;
  const classCourseAssignment = class_menu.classCourseAssignmentAll;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;

  const dispatch = useDispatch();

  let modalOpen = false;
  editStaffCourseModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const [classCourseIdDetails, setClassCourseIdDetails] =
    useState(staffCourseInfo);
  const [combinedClassIdDetails, setCombinedClassIdDetails] =
    useState(staffCourseInfo);
  const [teachingHoursDetails, setTeachingHoursDetails] =
    useState(staffCourseInfo);
  const [continousHourPreferenceDetails, setContinousHourPreferenceDetails] =
    useState(staffCourseInfo);
  const [classId, setClassId] = useState("");
  const [editClassCourseId, setEditClassCourseId] = useState(
    staffCourseAssignment.classCourseAssignmentId,
  );
  const [editCombinedClassId, setEditCombinedClassId] = useState(
    staffCourseAssignment.combinedClassId,
  );
  const [editTeachingHours, setEditTeachingHours] = useState(
    staffCourseAssignment.teachingHours,
  );
  const [editContinousHourPreference, setEditContinousHourPreference] =
    useState([]);
  const [classid, setClassid] = useState("");
  const [courseType, setCourseType] = useState("");
  const [handleError, setHandleError] = useState("");
  const [editStaffCourse, setEditStaffCourse] = useState(false);

  const classCourseOptions = [...classCourseAssignment];
  const combinedClassOptions = [...classCombinedList];

  useEffect(() => {
    staffCourseAssignment
      .filter((staff) => staff.id === staffCourseId)
      .map((res) => {
        if (res.classCourseAssignmentId !== null) {
          setClassid(1);

          classCourseAssignment
            .filter(
              (clsCourse) =>
                clsCourse.id === classCourseIdDetails.classCourseAssignmentId,
            )
            .map((resClassCourse) => {
              courseList
                .filter((course) => course.id === resClassCourse.courseId)
                .map((res) => {
                  setCourseType(res.courseTypeName);
                });
            });
        } else if (res.combinedClassId !== null) {
          setClassid(2);

          classCombinedList
            .filter(
              (clsCourse) =>
                clsCourse.id === combinedClassIdDetails.combinedClassId,
            )
            .map((resClassCourse) => {
              courseList
                .filter((course) => course.id === resClassCourse.courseId)
                .map((res) => {
                  setCourseType(res.courseTypeName);
                });
            });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(EditStaffCourseModel(false));
  };

  // Function used to handle class - class course or combined class
  const handleSelectClass = (event) => {
    setClassId(event.target.value);
    setClassid("");
  };

  // Function used to select class course
  const handleSelectClassCourse = (event) => {
    setCombinedClassIdDetails(null);
    setEditCombinedClassId(null);
    setEditClassCourseId(event.target.value);
    setClassCourseIdDetails(event.target.value);

    classCourseAssignment
      .filter((clsCourse) => clsCourse.id === event.target.value)
      .map((resClassCourse) => {
        courseList
          .filter((course) => course.id === resClassCourse.courseId)
          .map((res) => {
            setCourseType(res.courseTypeName);
          });
      });
  };

  // Function used to select combined course
  const handleSelectCombinedClass = (event) => {
    setEditClassCourseId(null);
    setEditCombinedClassId(event.target.value);
    setCombinedClassIdDetails(event.target.value);

    classCombinedList
      .filter((clsCourse) => clsCourse.id === event.target.value)
      .map((resClassCourse) => {
        courseList
          .filter((course) => course.id === resClassCourse.courseId)
          .map((res) => {
            setCourseType(res.courseTypeName);
          });
      });
  };

  // Function used to select continuous preference
  const handleSelectContinousHourPreference = (event) => {
    setEditContinousHourPreference(event.target.value);
    setContinousHourPreferenceDetails(event.target.value);
  };

  // Function used to handle teaching hours
  const handleTeachingHours = (event) => {
    setEditTeachingHours(event.target.value);
    setTeachingHoursDetails(event.target.value);
    setEditContinousHourPreference([]);
  };

  //Function to get the Class Name and Course Name to display in menu box - Class course
  const getAllClassCourseName = (classId, courseId) => {
    const classNames = [];
    const courseNames = [];

    classList
      .filter((cls) => cls.id === classId)
      .map((resCls) => {
        classNames.push(resCls.className);
      });

    courseList
      .filter((course) => course.id === courseId)
      .map((resCourse) => {
        courseNames.push(resCourse.courseName);
      });

    // Combine class names and course names into a single string
    const combinedNames = classNames.map((className, index) => {
      const formattedName = `${className} - ${courseNames[index]}`;
      return formattedName;
    });
    return combinedNames;
  };

  //Function to get the Class Name and Course Name to display in menu box - Combined class
  const getAllCombinedClassName = (courseId, cid) => {
    const classNames = [];
    const courseName = courseList.find(
      (course) => course.id === courseId,
    )?.courseName; // Get the course name

    // Filter combinedClassOptions by matching courseId
    const matchingClasses = combinedClassOptions.filter(
      (res) => res.id === cid,
    );

    // Iterate through matching classes and create formatted names
    matchingClasses.forEach((matchingClass) => {
      const formattedName = `${matchingClass.combinedClassName} - ${courseName}`;
      classNames.push(formattedName);
    });

    return classNames;
  };

  // Generate possible number pairs
  const generateNumberPairs = (teachingHours) => {
    teachingHours = teachingHours.toString();
    const numberPairs = [];
    if (teachingHours === "2" || teachingHours === "3") {
      numberPairs.push([teachingHours]);
    } else if (teachingHours === "4") {
      numberPairs.push([4]);
      numberPairs.push([2, 2]);
    } else if (teachingHours === "5") {
      numberPairs.push([5]);
      numberPairs.push([3, 2]);
    } else if (teachingHours === "6") {
      numberPairs.push([2, 2, 2]);
      numberPairs.push([3, 3]);
    } else if (teachingHours === "12") {
      numberPairs.push([5, 5, 2]);
    }
    return numberPairs;
  };

  const numberPairs = generateNumberPairs(
    teachingHoursDetails.teachingHours || editTeachingHours,
  );

  // Function used to update the staff course assignment List
  const changeStaff = (
    editClassCourseId,
    editCombinedClassId,
    editTeachingHours,
    editContinousHourPreference,
  ) => {
    const updatedStaffCourseData = staffCourseAssignment.map((obj) => {
      if (obj.id === staffCourseId) {
        return {
          ...obj,
          staffId: staffId,
          classCourseAssignmentId:
            editClassCourseId === undefined
              ? staffCourseInfo.classCourseAssignmentId
              : editClassCourseId,
          combinedClassId:
            editCombinedClassId === undefined
              ? staffCourseInfo.combinedClassId
              : editCombinedClassId,
          teachingHours:
            editTeachingHours === undefined
              ? staffCourseInfo.teachingHours
              : editTeachingHours,
          continuousHourPreference:
            courseType === "Theory" || editTeachingHours === 1
              ? null
              : editContinousHourPreference.length === 0
              ? staffCourseInfo.continuousHourPreference
              : editContinousHourPreference
                  .split(",")
                  .map((item) => Number(item.trim())),
        };
      }
      return obj;
    });
    dispatch(AddStaffCourseAssignmentList(updatedStaffCourseData));
  };
  // Function used to Edit the staff course assignment
  const handleEdit = (event) => {
    setHandleError("");
    let data = {
      staffId: staffId,
      classCourseAssignmentId:
        editClassCourseId === undefined
          ? staffCourseInfo.classCourseAssignmentId
          : editClassCourseId,
      combinedClassId:
        editCombinedClassId === undefined
          ? staffCourseInfo.combinedClassId
          : editCombinedClassId,
      teachingHours:
        editTeachingHours === undefined
          ? staffCourseInfo.teachingHours
          : editTeachingHours,
      continuousHourPreference:
        courseType === "Theory" || editTeachingHours === "1"
          ? null
          : editContinousHourPreference.length === 0
          ? staffCourseInfo.continuousHourPreference
          : editContinousHourPreference
              .split(",")
              .map((item) => Number(item.trim())),
    };
    StaffCourseAssignmentService.editStaffCourseAssignment(staffCourseId, data)
      .then((res) => {
        changeStaff(
          editClassCourseId,
          editCombinedClassId,
          editTeachingHours,
          editContinousHourPreference,
        );
        handleClose();
      })
      .catch((error) => {
        setHandleError(error.response.data);
      });
  };

  // Getting staff name
  let staffName = "";
  staffList
    .filter((staff) => staff.id === staffId)
    .map((res) => {
      staffName = res.staffName;
    });

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "30rem", height: "37.3rem", boxShadow: "none" },
        }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            Edit Staff Course Assignment<br></br>
            {staffName}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-course-assignment"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <Typography className="model-text staff-assignment-text1">
                Select Class
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="class-dropdown"
                value={classid || classId}
                onChange={handleSelectClass}
              >
                <MenuItem value={0} disabled>
                  Choose
                </MenuItem>
                <MenuItem value={1}>Class Course </MenuItem>
                <MenuItem value={2}>Combined Class Course</MenuItem>
              </Select>

              <br></br>
              <br></br>

              {classId === 1 || classid === 1 ? (
                <>
                  <Typography className="model-text staff-assignment-text2">
                    Select Class Course
                  </Typography>

                  <FormControl sx={{ m: 1 }}>
                    <Select
                      value={
                        classCourseIdDetails.classCourseAssignmentId ||
                        editClassCourseId
                      }
                      onChange={handleSelectClassCourse}
                      size="small"
                      displayEmpty
                      className="department-dropdown"
                      disabled={classId === 0 || classid === 0}
                    >
                      {classCourseOptions.map((combinedName, index) => (
                        <MenuItem key={index} value={combinedName.id}>
                          {getAllClassCourseName(
                            combinedName.classId,
                            combinedName.courseId,
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : classId === 2 || classid === 2 ? (
                <>
                  <Typography className="model-text staff-assignment-text3">
                    Select Combined Class Course
                  </Typography>

                  <FormControl sx={{ m: 1 }}>
                    <Select
                      value={
                        combinedClassIdDetails.combinedClassId ||
                        editCombinedClassId
                      }
                      onChange={handleSelectCombinedClass}
                      size="small"
                      displayEmpty
                      className="department-dropdown"
                      disabled={classId === 0 || classid === 0}
                    >
                      {combinedClassOptions.map((combinedName, index) => (
                        <MenuItem key={index} value={combinedName.id}>
                          {getAllCombinedClassName(
                            combinedName.courseId,
                            combinedName.id,
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <></>
              )}

              <br></br>

              <Typography className="model-staff-text edit-text1">
                Teaching Hours
              </Typography>
              <OutlinedInput
                id="teachingHours"
                name="teachingHours"
                type="number"
                value={teachingHoursDetails.teachingHours || editTeachingHours}
                onChange={handleTeachingHours}
                placeholder="Enter Teaching Hours"
                variant="outlined"
                size="small"
                className="input-style"
              />

              <br></br>
              <br></br>

              <Typography className="model-text staff-assignment-text4">
                Select Continous Hour Preference
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="continousHourPreference"
                className="class-dropdown"
                value={
                  continousHourPreferenceDetails.continuousHourPreference ||
                  editContinousHourPreference
                }
                onChange={handleSelectContinousHourPreference}
                disabled={
                  teachingHoursDetails.teachingHours <= 1 ||
                  editTeachingHours <= 1 ||
                  courseType === "Theory"
                }
              >
                <MenuItem value={0} disabled>
                  Choose
                </MenuItem>
                {numberPairs.map((pair, index) => (
                  <MenuItem key={index} value={pair.toString()}>
                    {pair.join(", ")}
                  </MenuItem>
                ))}
              </Select>

              <br></br>
              <br></br>
              <br></br>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    editStaffCourse === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={editStaffCourse === true}
                  onClick={() => {
                    handleEdit();
                    setEditStaffCourse(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-building-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleError && (
                <>
                  <p className="duplicate-text handle-error">{handleError}</p>
                  {handleError !== "" &&
                    editStaffCourse &&
                    setEditStaffCourse(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditStaffCourseAssignment;
