// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  handleFailedModel: false,
  handleLoader: false,
  handleGenerateLoader: false,
  clearResultConfirm: false,
  viewOverallCount: false,
  overallCountStatusCode: false,

  timeList: [],
  dayList: [],
  roomAnalysisList: [],
  overAllCount: [],
  warningsList: [],
  isWarningStatusCode: false,
  algorithmStatus: "",
  algorithmFailedError: "",
  resultClassList: [],
  resultStaffList: [],
  resultRoomList: [],
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    HandleFailedModel(state, action) {
      state.handleFailedModel = action.payload;
    },
    HandleLoader(state, action) {
      state.handleLoader = action.payload;
    },
    HandleGenerateLoader(state, action) {
      state.handleGenerateLoader = action.payload;
    },
    ClearResultConfirm(state, action) {
      state.clearResultConfirm = action.payload;
    },
    ViewOverallCount(state, action) {
      state.viewOverallCount = action.payload;
    },
    OverallCountStatusCode(state, action) {
      state.overallCountStatusCode = action.payload;
    },

    TimeList(state, action) {
      state.timeList = action.payload;
    },
    DayList(state, action) {
      state.dayList = action.payload;
    },
    RoomAnalysisList(state, action) {
      state.roomAnalysisList = action.payload;
    },
    OverAllCount(state, action) {
      state.overAllCount = action.payload;
    },
    WarningsList(state, action) {
      state.warningsList = action.payload;
    },
    IsWarningStatusCode(state, action) {
      state.isWarningStatusCode = action.payload;
    },
    AlgorithmStatus(state, action) {
      state.algorithmStatus = action.payload;
    },
    AlgorithmFailedError(state, action) {
      state.algorithmFailedError = action.payload;
    },
    ResultClassList(state, action) {
      state.resultClassList = action.payload;
    },
    ResultStaffList(state, action) {
      state.resultStaffList = action.payload;
    },
    ResultRoomList(state, action) {
      state.resultRoomList = action.payload;
    },
  },
});

export default menu.reducer;

export const {
  HandleFailedModel,
  HandleLoader,
  HandleGenerateLoader,
  ClearResultConfirm,
  ViewOverallCount,
  OverallCountStatusCode,

  TimeList,
  DayList,
  RoomAnalysisList,
  OverAllCount,
  WarningsList,
  IsWarningStatusCode,
  AlgorithmStatus,
  AlgorithmFailedError,
  ResultClassList,
  ResultStaffList,
  ResultRoomList,
} = menu.actions;
