import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http-common";
import { getBranchName } from "../Utils/common";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");
// ========================================|| COMBINED CLASS SERVICE ||======================================== //

const getAll = () => {
  return apiService.get("/combinedClass/getAll", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getById = (id) => {
  return apiService.get(`/combinedClass/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const createCombinedClass = (data) => {
  return apiService.post("/combinedClass", data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const editCombinedClass = (id, data) => {
  return apiService.put(`/combinedClass/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const deleteCombinedClass = (id) => {
  return apiService.delete(`/combinedClass/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getClassCombined = (data) => {
  return apiService.get("/combinedClassList/getAll", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const addClassCombined = (data) => {
  return apiService.post("/combinedClassList", data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const fixedSlotIdExistsInCombinedClass = (id) => {
  return apiService.get(`/fixedSlotExistsIds/combinedClass`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const CombinedClassService = {
  getAll,
  getById,
  createCombinedClass,
  editCombinedClass,
  deleteCombinedClass,
  getClassCombined,
  addClassCombined,
  fixedSlotIdExistsInCombinedClass,
};

export default CombinedClassService;
