/* eslint-disable array-callback-return */
import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { AddCombinedClassModel } from "../../../../Store/Reducers/combined_class_menu";
import CombinedClassService from "../../../../Services/CombinedClassService";
import { AddClassCombinedList } from "../../../../Store/Reducers/combined_class_menu";

// ==============================|| ADD NEW COMBINED CLASS MODEL ||============================== //

const CombinedClassModel = () => {
  const menu = useSelector((state) => state.combined_class_menu);
  const addCombinedClassModel = menu.addCombinedClassModel;
  const classCombinedList = menu.classCombinedList;
  const combinedClassList = menu.combinedClassList;

  const building_menu = useSelector((state) => state.infrastructure_menu);
  const buildingList = building_menu.buildingList;
  const addNewRoom = building_menu.addNewRoom;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const dispatch = useDispatch();

  // Local variable used for combined class
  const newCombinedClass = {
    combinedClassName: "",
    courseId: 0,
    numberOfStaffRequiredAtOneTime: 0,
    numberOfRoomsRequired: 0,
    possibleRooms: [],
    classCourseAssignmentId: [],
  };

  let modalOpen = false;
  addCombinedClassModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const [combinedClassName, setCombinedClassName] = useState(newCombinedClass);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [numberOfStaffRequiredAtOneTime, setNumberOfStaffRequiredAtOneTime] =
    useState(0);
  const [numberOfRoomsRequired, setNumberOfRoomsRequired] = useState(0);
  const [possibleRooms, setPossibleRooms] = useState([]);
  const [handleError, setHandleError] = useState("");
  const [addCombinedClass, setAddCombinedClass] = useState(false);

  const courseOptions = [...courseList];

  let possibleRoomsId = [];
  possibleRooms.forEach((possible) => {
    addNewRoom
      .filter((room) => room.roomName === possible)
      .forEach((res) => {
        possibleRoomsId.push(res.id);
      });
  });

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(AddCombinedClassModel(false));
  };

  // Function used to store possible rooms
  const handleChange = (event) => {
    setPossibleRooms(event.target.value);
  };

  // Function used to handle number of rooms required
  const handleNumberOfRoomsRequired = (event) => {
    setNumberOfRoomsRequired(event.target.value);
  };

  // Function used to handle number of staff required
  const handleNumberOfStaffRequiredAtOneTime = (event) => {
    setNumberOfStaffRequiredAtOneTime(event.target.value);
  };

  // Function used to handle combined class name
  const handleCombinedClassName = (event) => {
    setCombinedClassName(event.target.value);
  };

  // Function used to store building data
  const buildingHeader = [];
  buildingList.map((build) => {
    buildingHeader.push(build);
  });

  // Function used to store room data
  const roomList = [];
  addNewRoom.map((room) => {
    roomList.push(room);
  });

  // Function used to display menu items
  const menuItems = buildingHeader.map((build, index) => {
    const roomsForBuilding = roomList.filter(
      (room) => room.buildingId === build.id,
    );

    return [
      <MenuItem
        key={`header-${build.id}`}
        disabled
        sx={{ color: "black", fontWeight: "bold" }}
      >
        {build.buildingName}
      </MenuItem>,
      ...roomsForBuilding.map((res) => (
        <MenuItem key={res.roomName} value={res.roomName}>
          {res.roomName}
        </MenuItem>
      )),
    ];
  });

  // Used to retrieve the selected course id
  let selectedCode, selectedCodeId;
  if (
    (selectedCourse !== null &&
      selectedCourse !== undefined &&
      typeof selectedCourse === "string") ||
    (selectedCode !== undefined && selectedCode !== null)
  ) {
    selectedCode = selectedCourse.split(" - ");
    if (selectedCode.length > 0) {
      selectedCode = selectedCode[0];
    }
  }

  if (selectedCode) {
    courseList
      .filter((course) => course.courseCode === selectedCode)
      .map((res) => {
        selectedCodeId = res.id;
      });
  }

  // Function used to save the new combined class
  const handleSave = (event) => {
    setHandleError("");
    let data = {
      combinedClassName: combinedClassName.trim(),
      courseId: selectedCodeId,
      combinedResourceDTO: {
        numberOfStaffRequiredAtOneTime: numberOfStaffRequiredAtOneTime,
        possibleRooms: possibleRoomsId,
        numberOfRoomsRequired: numberOfRoomsRequired,
      },
    };
    CombinedClassService.createCombinedClass(data)
      .then((res) => {
        dispatch(AddClassCombinedList([...classCombinedList, res.data]));
        dispatch(AddCombinedClassModel(false));
      })
      .catch((error) => {
        setHandleError(error.response.data);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { width: "30rem", height: "38rem" } }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            Add Combined Class
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-comclass-assignment"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <br></br>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={courseOptions.map(
                  (option, id) => option.courseCode + " - " + option.courseName,
                )}
                sx={{ width: 300, marginBottom: "1rem", marginTop: "-1rem" }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Course" />
                )}
                value={selectedCourse}
                onChange={(event, newValue) => {
                  setSelectedCourse(newValue);
                }}
              />

              <Typography className="model-text comclass-text2">
                Combined Class Name
              </Typography>
              <OutlinedInput
                id="className"
                name="className"
                type="text"
                value={combinedClassList.combinedClassName}
                onChange={handleCombinedClassName}
                placeholder="Enter Combined Class Name"
                variant="outlined"
                size="small"
                className="input-style"
              />
              <br></br>
              <Typography className="model-text course-assignment-text2">
                Staff Required
              </Typography>
              <OutlinedInput
                id="numberOfStaffRequiredAtOneTime"
                name="numberOfStaffRequiredAtOneTime"
                type="number"
                value={combinedClassList.numberOfStaffRequiredAtOneTime}
                onChange={handleNumberOfStaffRequiredAtOneTime}
                placeholder="Enter Number of Staff Required"
                variant="outlined"
                size="small"
                className="input-style"
                required
              />
              <br></br>
              <Typography className="model-text course-assignment-text4">
                Number of Rooms Required
              </Typography>
              <OutlinedInput
                id="numberOfRoomsRequired"
                name="numberOfRoomsRequired"
                type="number"
                value={combinedClassList.numberOfRoomsRequired}
                onChange={handleNumberOfRoomsRequired}
                placeholder="Enter Number of Rooms Required"
                variant="outlined"
                size="small"
                className="input-style"
                required
              />
              <br></br>
              <Typography className="model-text course-assignment-text3">
                Possible Rooms
              </Typography>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-multiple-name-label"
                  id="possibleRooms"
                  multiple
                  value={possibleRooms}
                  onChange={handleChange}
                  className="possible-rooms-dropdown"
                  required
                >
                  {menuItems}
                  {buildingHeader.map((build) => {
                    roomList
                      .filter((room) => room.buildingId === build.id)
                      .map((res) => {
                        possibleRooms.includes(res.roomName);
                      });
                  })}
                </Select>
              </FormControl>
              <br></br>
              <br></br>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    selectedCourse === "" ||
                    combinedClassList.combinedClassName === "" ||
                    combinedClassList.numberOfStaffRequiredAtOneTime === 0 ||
                    combinedClassList.numberOfRoomsRequired === 0 ||
                    possibleRooms.length === 0 ||
                    addCombinedClass === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  onClick={() => {
                    handleSave();
                    setAddCombinedClass(true);
                  }}
                  disabled={
                    selectedCourse === "" ||
                    combinedClassList.combinedClassName === "" ||
                    combinedClassList.numberOfStaffRequiredAtOneTime === 0 ||
                    combinedClassList.numberOfRoomsRequired === 0 ||
                    possibleRooms.length === 0 ||
                    addCombinedClass === true
                  }
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-class-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleError && (
                <>
                  <p className="duplicate-text">{handleError}</p>
                  {handleError !== "" &&
                    addCombinedClass &&
                    setAddCombinedClass(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CombinedClassModel;
