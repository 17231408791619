// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  addStaffModel: false,
  editStaffModel: false,
  deleteStaffModel: false,
  staffList: [],
  staffId: null,
  staffInfo: [],
  staffInFixedSlot: [],
  isStaffStatusCode: false,

  addStaffCourseModel: false,
  editStaffCourseModel: false,
  deleteStaffCourseModel: false,
  staffCourseAssignment: [],
  staffCourseAssignmentAll: [],
  staffCourseId: null,
  staffCourseInfo: [],
  staffCourseInFixedSlot: [],
  isStaffCourseStatusCode: false,

  timePreference: [],
};

// ==============================|| SLICE - STAFF MENU ||============================== //

const staff_menu = createSlice({
  name: "staff_menu",
  initialState,
  reducers: {
    AddStaffModel(state, action) {
      state.addStaffModel = action.payload;
    },
    EditStaffModel(state, action) {
      state.editStaffModel = action.payload;
    },
    DeleteStaffModel(state, action) {
      state.deleteStaffModel = action.payload;
    },
    AddStaffList(state, action) {
      state.staffList = action.payload;
    },
    StaffId(state, action) {
      state.staffId = action.payload;
    },
    StaffInfo(state, action) {
      state.staffInfo = action.payload;
    },
    StaffInFixedSlot(state, action) {
      state.staffInFixedSlot = action.payload;
    },
    IsStaffStatusCode(state, action) {
      state.isStaffStatusCode = action.payload;
    },

    AddStaffCourseModel(state, action) {
      state.addStaffCourseModel = action.payload;
    },
    EditStaffCourseModel(state, action) {
      state.editStaffCourseModel = action.payload;
    },
    DeleteStaffCourseModel(state, action) {
      state.deleteStaffCourseModel = action.payload;
    },
    AddStaffCourseAssignmentList(state, action) {
      state.staffCourseAssignment = action.payload;
    },
    AddStaffCourseAssignmentListAll(state, action) {
      state.staffCourseAssignmentAll = action.payload;
    },
    StaffCourseId(state, action) {
      state.staffCourseId = action.payload;
    },
    StaffCourseInfo(state, action) {
      state.staffCourseInfo = action.payload;
    },
    StaffCourseInFixedSlot(state, action) {
      state.staffCourseInFixedSlot = action.payload;
    },
    IsStaffCourseStatusCode(state, action) {
      state.isStaffCourseStatusCode = action.payload;
    },

    TimePreference(state, action) {
      state.timePreference = action.payload;
    },
  },
});

export default staff_menu.reducer;

export const {
  AddStaffModel,
  EditStaffModel,
  DeleteStaffModel,
  AddStaffList,
  StaffId,
  StaffInfo,
  StaffInFixedSlot,
  IsStaffStatusCode,

  AddStaffCourseModel,
  EditStaffCourseModel,
  DeleteStaffCourseModel,
  AddStaffCourseAssignmentList,
  AddStaffCourseAssignmentListAll,
  StaffCourseId,
  StaffCourseInfo,
  StaffCourseInFixedSlot,
  IsStaffCourseStatusCode,

  TimePreference,
} = staff_menu.actions;
