import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { EditBuildingModel } from "../../../../../Store/Reducers/infrastructure_menu";
import BuildingService from "../../../../../Services/BuildingService";
import { AddBuilding } from "../../../../../Store/Reducers/infrastructure_menu";
import "./BuildingModel.css";

// ==============================|| EDIT BUILDING MODEL ||============================== //

const EditBuilding = () => {
  const menu = useSelector((state) => state.infrastructure_menu);
  const editBuildingModel = menu.editBuildingModel;
  const buildingList = menu.buildingList;
  const buildingId = menu.buildingId;
  const buildingInfo = menu.buildingInfo;

  const dispatch = useDispatch();

  let modalOpen = false;
  editBuildingModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const [buildingDetails, setBuildingDetails] = useState(buildingInfo);
  const [editBuildingName, setEditBuildingName] = useState(
    buildingList.buildingName,
  );
  const [handleEditBuildingError, setHandleEditBuildingError] = useState("");
  const [editBuilding, setEditBuilding] = useState(false);

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(EditBuildingModel(false));
  };

  // Function used to handle building name
  const handleEditBuildingName = (event) => {
    setEditBuildingName(event.target.value);
    setBuildingDetails(event.target.value);
  };

  // Function used to update the Building List
  const changeBuilding = (editBuildingName) => {
    const updatedBuildingData = buildingList.map((obj) => {
      if (obj.id === buildingId) {
        return {
          ...obj,
          buildingName:
            editBuildingName === undefined
              ? buildingInfo.buildingName
              : editBuildingName.trim(),
        };
      }
      return obj;
    });
    dispatch(AddBuilding(updatedBuildingData));
  };

  // Function used to edit building
  const handleEdit = () => {
    setHandleEditBuildingError("");
    let data = {
      buildingName:
        editBuildingName === undefined
          ? buildingInfo.buildingName
          : editBuildingName.trim(),
    };
    BuildingService.editBuilding(buildingId, data)
      .then((res) => {
        changeBuilding(editBuildingName);
        handleClose();
      })
      .catch((error) => {
        setHandleEditBuildingError(error.response.data);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "23rem", height: "18rem", boxShadow: "none" },
        }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">Edit Building</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-building"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <br></br>
              <Typography className="model-building-text">
                Building Name
              </Typography>
              <OutlinedInput
                id="buildingName"
                name="buildingName"
                type="text"
                value={buildingDetails.buildingName || editBuildingName}
                onChange={handleEditBuildingName}
                placeholder="Enter Building Name"
                variant="outlined"
                size="small"
                className="input-style"
              />
              <br></br>
              <br></br>
              <br></br>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    editBuildingName === "" || editBuilding === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={editBuildingName === "" || editBuilding === true}
                  onClick={() => {
                    handleEdit();
                    setEditBuilding(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-building-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleEditBuildingError && (
                <>
                  <p className="duplicate-text">{handleEditBuildingError}</p>
                  {handleEditBuildingError !== "" &&
                    editBuilding &&
                    setEditBuilding(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditBuilding;
