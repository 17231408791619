/* eslint-disable array-callback-return */
import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimetableGanttChart from "../TimetableGanttchart";
import { useSelector } from "react-redux";

// ==============================|| TIMETABLE ROOM ACCORDION ||============================== //

export default function TimetableRoomAccordion({
  searchBuildingList,
  expandedMainAccordion,
  handleAccordion,
  expandedAccordion,
  handleClassAccordion,
  ganttRoomItems,
  ganttGroup,
  value,
}) {
  const building_menu = useSelector((state) => state.infrastructure_menu);
  const addNewRoom = building_menu.addNewRoom;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  return (
    <div className="accordion-timeTable">
      {searchBuildingList.map((build) => (
        <Accordion
          key={build.id}
          expanded={expandedMainAccordion.includes(build.id)}
          onChange={handleAccordion(build.id)}
          disabled={algorithmStatus !== "COMPLETED"}
          className={algorithmStatus !== "COMPLETED" ? "disable-accordion" : ""}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${build.id}-content`}
            id={`panel${build.id}-header`}
          >
            <Typography>{build.buildingName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {addNewRoom
              .filter((roomInfo) => roomInfo.buildingId === build.id)
              .map((room) => (
                <Accordion
                  Accordion
                  key={room.id}
                  expanded={expandedAccordion.includes(room.id)}
                  onChange={handleClassAccordion(room.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${room.id}-content`}
                    id={`panel${room.id}-header`}
                  >
                    <Typography>{room.roomName}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ mt: -1.5, ml: 2 }}>
                      {ganttRoomItems
                        .filter((ganttdata) => ganttdata.id === room.id)
                        .map((result) => (
                          <TimetableGanttChart
                            ganttGroup={ganttGroup}
                            ganttItems={ganttRoomItems}
                            resultItems={result.timeLineItem}
                            value={value}
                          />
                        ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
