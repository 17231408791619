import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import {
  Tabs,
  Typography,
  Box,
  OutlinedInput,
  InputAdornment,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GanttChartService from "../../../Services/GanttChartService";
import moment from "moment";
import { useSelector } from "react-redux";
import FixedslotClassAccordion from "./FixedslotAccordion/FixedslotClassAccordion";
import FixedslotStaffAccordion from "./FixedslotAccordion/FixedslotStaffAccordion";
import FixedslotRoomAccordion from "./FixedslotAccordion/FixedslotRoomAccordion";

// ==============================|| FIXED SLOT TABS ||============================== //

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// ==============================|| MAIN FUNCTION ||============================== //

export default function FixedSlotTabs() {
  const building_menu = useSelector((state) => state.infrastructure_menu);
  const buildingList = building_menu.buildingList;
  const departmentList = building_menu.departmentList;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const class_menu = useSelector((state) => state.class_menu);
  const classCourseAssignment = class_menu.classCourseAssignmentAll;

  const day_menu = useSelector((state) => state.menu);
  const dayList = day_menu.dayList;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;

  const [value, setValue] = useState(0);
  const [ganttGroup, setGanttGroup] = useState([]);
  const [ganttClassItems, setGanttClassItems] = useState([]);
  const [ganttStaffItems, setGanttStaffItems] = useState([]);
  const [ganttRoomItems, setGanttRoomItems] = useState([]);
  const [statusCode, setStatusCode] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [expandedAccordion, setExpandedAccordion] = useState([]);
  const [expandedMainAccordion, setExpandedMainAccordion] = useState([]);
  const [searchDepartmentList, setSearchDepartmentList] =
    useState(departmentList);
  const [searchBuildingList, setSearchBuildingList] = useState(buildingList);

  // Handling main accordion
  const handleAccordion = (id) => (event, isExpanded) => {
    // Check if the accordion is being expanded or collapsed
    if (isExpanded) {
      // If the accordion ID is not already in the array, add it
      if (!expandedMainAccordion.includes(id)) {
        setExpandedMainAccordion([...expandedMainAccordion, id]);
      }
    } else {
      // If the accordion ID is in the array, remove it
      if (expandedMainAccordion.includes(id)) {
        setExpandedMainAccordion(
          expandedMainAccordion.filter((accordionId) => accordionId !== id),
        );
      }
    }
  };

  // Handling  tabs - Class, Staff, Room
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSearchValue("");
    setSearchDepartmentList(departmentList);
    setSearchBuildingList(buildingList);
    setExpandedAccordion([]);
    setExpandedMainAccordion([]);
  };

  // Function used to retrieve search text
  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    searchFixedSlot(e.target.value);
  };

  // Function used for search accordion
  const searchFixedSlot = (text) => {
    const filteredDepartment = departmentList.filter((value) => {
      const searchText = text.toLowerCase();
      const lowercaseClassName = value.departmentName.toLowerCase();
      const uppercaseClassName = value.departmentName.toUpperCase();
      return (
        lowercaseClassName.includes(searchText) ||
        uppercaseClassName.includes(searchText)
      );
    });
    setSearchDepartmentList(filteredDepartment);

    const filteredBuilding = buildingList.filter((value) => {
      const searchText = text.toLowerCase();
      const lowercaseClassName = value.buildingName.toLowerCase();
      const uppercaseClassName = value.buildingName.toUpperCase();
      return (
        lowercaseClassName.includes(searchText) ||
        uppercaseClassName.includes(searchText)
      );
    });
    setSearchBuildingList(filteredBuilding);
  };

  // Handling accodrion onChange
  const handleClassAccordion = (id) => (event, isExpanded) => {
    setGanttGroup([]);

    // Check if the accordion is being expanded or collapsed
    if (isExpanded) {
      // If the accordion ID is not already in the array, add it
      if (!expandedAccordion.includes(id)) {
        setExpandedAccordion([...expandedAccordion, id]);
      }
    } else {
      // If the accordion ID is in the array, remove it
      if (expandedAccordion.includes(id)) {
        setExpandedAccordion(
          expandedAccordion.filter((accordionId) => accordionId !== id),
        );
      }
    }

    let i = 1;
    let tempGanttItems = []; // Create a temporary array to collect items

    if (value === 0 && isExpanded === true) {
      GanttChartService.getByClassId(id).then((res) => {
        res.data.forEach((classItem, index) => {
          let courseName = "",
            courseCode = "",
            courseType = "";

          if (classItem.classCourseAssignmentId !== null) {
            const assignedCourse = classCourseAssignment.find(
              (clsCourse) => clsCourse.id === classItem.classCourseAssignmentId,
            );

            if (assignedCourse) {
              const resCourse = courseList.find(
                (course) => course.id === assignedCourse.courseId,
              );

              if (resCourse) {
                courseName = resCourse.courseName;
                courseCode = resCourse.courseCode;
                courseType = resCourse.courseTypeName;
              }
            }
          } else if (classItem.combinedClassId !== null) {
            const combinedClass = classCombinedList.find(
              (clsCom) => clsCom.id === classItem.combinedClassId,
            );

            if (combinedClass) {
              const resCourse = courseList.find(
                (course) => course.id === combinedClass.courseId,
              );

              if (resCourse) {
                courseName = resCourse.courseName;
                courseCode = resCourse.courseCode;
                courseType = resCourse.courseTypeName;
              }
            }
          }

          // Convert time format
          const startTime = classItem.start_time
            .replace("01:30", "13:30")
            .replace("02:30", "14:30")
            .replace("03:30", "15:30");
          const endTime = classItem.end_time
            .replace("02:30", "14:30")
            .replace("03:30", "15:30");

          let stime = moment().startOf("day").add(startTime, "hour");
          let etime = moment().startOf("day").add(endTime, "hour");

          let roomId = [];
          let staffId = [];

          classItem.staffAndRoomDetailsList.forEach((room) => {
            roomId.push(room.roomId);
            room.staffIds.forEach((staff) => {
              staffId.push(staff);
            });
          });

          tempGanttItems.push({
            id: i++,
            group: classItem.dayId,
            title: courseName,
            courseCode: courseCode,
            courseType: courseType,
            start_time: stime.valueOf(),
            end_time: etime.valueOf(),
            classCourseAssignmentId: classItem.classCourseAssignmentId,
            combinedClassId: classItem.combinedClassId,
            roomId: roomId,
            staffId: staffId,
            sTime: classItem.start_time,
          });
        });

        if (isExpanded === true) {
          const existingItemIndex = ganttClassItems.findIndex(
            (item) => item.id === id,
          );

          if (existingItemIndex === -1) {
            // If not exists, add a new item
            setGanttClassItems((prevGanttClassItems) => [
              ...prevGanttClassItems,
              {
                id: id,
                timeLineItem: tempGanttItems,
              },
            ]);
          } else {
            setGanttClassItems((prevGanttClassItems) => {
              const updatedItems = [...prevGanttClassItems];
              updatedItems[existingItemIndex].timeLineItem = tempGanttItems;
              return updatedItems;
            });
          }
        }
        if (res.status === 200) {
          setStatusCode(true);
        }
      });
    }

    if (value === 1 && isExpanded === true) {
      GanttChartService.getByStaffId(id)
        .then((res) => {
          // Initialize an array to collect gantt items
          const tempGanttItems = [];
          let index = 1; // Initialize index

          // Process each staffItem
          res.data.forEach((staffItem) => {
            let staffItemLength = staffItem.courseAndRoomDetailsDTOList.length;

            for (let i = 0; i < staffItemLength; i++) {
              const staffItemDetail = staffItem.courseAndRoomDetailsDTOList[i];
              let courseName = "",
                courseCode = "",
                courseType = "";

              if (staffItemDetail.classCourseAssignmentId !== null) {
                const assignedCourse = classCourseAssignment.find(
                  (clsCourse) =>
                    clsCourse.id === staffItemDetail.classCourseAssignmentId,
                );

                if (assignedCourse) {
                  const resCourse = courseList.find(
                    (course) => course.id === assignedCourse.courseId,
                  );

                  if (resCourse) {
                    courseName = resCourse.courseName;
                    courseCode = resCourse.courseCode;
                    courseType = resCourse.courseTypeName;
                  }
                }
              }

              if (staffItemDetail.combinedClassId !== null) {
                const combinedClass = classCombinedList.find(
                  (clsCom) => clsCom.id === staffItemDetail.combinedClassId,
                );

                if (combinedClass) {
                  const resCourse = courseList.find(
                    (course) => course.id === combinedClass.courseId,
                  );

                  if (resCourse) {
                    courseName = resCourse.courseName;
                    courseCode = resCourse.courseCode;
                    courseType = resCourse.courseTypeName;
                  }
                }
              }

              // Convert time format
              const startTime = staffItem.start_time
                .replace("01:30", "13:30")
                .replace("02:30", "14:30")
                .replace("03:30", "15:30");
              const endTime = staffItem.end_time
                .replace("02:30", "14:30")
                .replace("03:30", "15:30");

              const stime = moment().startOf("day").add(startTime, "hour");
              const etime = moment().startOf("day").add(endTime, "hour");

              // Create a gantt item and push it to the array
              tempGanttItems.push({
                id: index,
                group: staffItem.dayId,
                title: courseName,
                courseCode: courseCode,
                courseType: courseType,
                start_time: stime.valueOf(),
                end_time: etime.valueOf(),
                classCourseAssignmentId:
                  staffItemDetail.classCourseAssignmentId,
                combinedClassId: staffItemDetail.combinedClassId,
                roomId: staffItemDetail.roomId,
                staffId: id,
                sTime: staffItem.start_time,
              });

              index++;
            }
          });

          if (isExpanded === true) {
            const existingItemIndex = ganttStaffItems.findIndex(
              (item) => item.id === id,
            );

            if (existingItemIndex === -1) {
              // If not exists, add a new item
              setGanttStaffItems((prevGanttStaffItems) => [
                ...prevGanttStaffItems,
                {
                  id: id,
                  timeLineItem: tempGanttItems,
                },
              ]);
            } else {
              setGanttStaffItems((prevGanttStaffItems) => {
                const updatedItems = [...prevGanttStaffItems];
                updatedItems[existingItemIndex].timeLineItem = tempGanttItems;
                return updatedItems;
              });
            }
          }

          if (res.status === 200) {
            setStatusCode(true);
          }
        })
        .catch((error) => {
          console.error("Error :", error);
        });
    }

    if (value === 2 && isExpanded === true) {
      GanttChartService.getByRoomId(id)
        .then((res) => {
          // Initialize an array to collect gantt items
          const tempGanttItems = [];
          let index = 1; // Initialize index

          // Process each roomItem
          res.data.forEach((roomItem) => {
            let roomItemLength = roomItem.courseAndStaffDetailsList.length;

            for (let i = 0; i < roomItemLength; i++) {
              const roomItemDetail = roomItem.courseAndStaffDetailsList[i];
              let courseName = "",
                courseCode = "",
                courseType = "";

              if (roomItemDetail.classCourseAssignmentId !== null) {
                const assignedCourse = classCourseAssignment.find(
                  (clsCourse) =>
                    clsCourse.id === roomItemDetail.classCourseAssignmentId,
                );

                if (assignedCourse) {
                  const resCourse = courseList.find(
                    (course) => course.id === assignedCourse.courseId,
                  );

                  if (resCourse) {
                    courseName = resCourse.courseName;
                    courseCode = resCourse.courseCode;
                    courseType = resCourse.courseTypeName;
                  }
                }
              }

              if (roomItemDetail.combinedClassId !== null) {
                const combinedClass = classCombinedList.find(
                  (clsCom) => clsCom.id === roomItemDetail.combinedClassId,
                );

                if (combinedClass) {
                  const resCourse = courseList.find(
                    (course) => course.id === combinedClass.courseId,
                  );

                  if (resCourse) {
                    courseName = resCourse.courseName;
                    courseCode = resCourse.courseCode;
                    courseType = resCourse.courseTypeName;
                  }
                }
              }

              // Convert time format
              const startTime = roomItem.start_time
                .replace("01:30", "13:30")
                .replace("02:30", "14:30")
                .replace("03:30", "15:30");
              const endTime = roomItem.end_time
                .replace("02:30", "14:30")
                .replace("03:30", "15:30");

              const stime = moment().startOf("day").add(startTime, "hour");
              const etime = moment().startOf("day").add(endTime, "hour");

              // Create a gantt item and push it to the array
              tempGanttItems.push({
                id: index,
                group: roomItem.dayId,
                title: courseName,
                courseCode: courseCode,
                courseType: courseType,
                start_time: stime.valueOf(),
                end_time: etime.valueOf(),
                classCourseAssignmentId: roomItemDetail.classCourseAssignmentId,
                combinedClassId: roomItemDetail.combinedClassId,
                roomId: id,
                staffId: roomItemDetail.staffIds,
                sTime: roomItem.start_time,
              });

              index++;
            }
          });

          if (isExpanded === true) {
            const existingItemIndex = ganttRoomItems.findIndex(
              (item) => item.id === id,
            );

            if (existingItemIndex === -1) {
              // If not exists, add a new item
              setGanttRoomItems((prevGanttRoomItems) => [
                ...prevGanttRoomItems,
                {
                  id: id,
                  timeLineItem: tempGanttItems,
                },
              ]);
            } else {
              setGanttRoomItems((prevGanttRoomItems) => {
                const updatedItems = [...prevGanttRoomItems];
                updatedItems[existingItemIndex].timeLineItem = tempGanttItems;
                return updatedItems;
              });
            }
          }

          if (res.status === 200) {
            setStatusCode(true);
          }
        })
        .catch((error) => {
          console.error("Error :", error);
        });
    }

    dayList.forEach((day) => {
      setGanttGroup((ganttGroup) => [
        ...ganttGroup,
        {
          id: day.id,
          title: day.dayName,
        },
      ]);
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Stack direction="row">
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab label="Class" {...a11yProps(0)} />
            <Tab label="Staff" {...a11yProps(1)} />
            <Tab label="Room" {...a11yProps(2)} />
          </Tabs>
          <OutlinedInput
            id="outlined-adornment-weight"
            className="searchfixedslot"
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            placeholder="Search"
            onChange={handleSearchChange}
            value={searchValue}
          />
        </Stack>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <FixedslotClassAccordion
          searchDepartmentList={searchDepartmentList}
          expandedMainAccordion={expandedMainAccordion}
          handleAccordion={handleAccordion}
          expandedAccordion={expandedAccordion}
          handleClassAccordion={handleClassAccordion}
          statusCode={statusCode}
          ganttClassItems={ganttClassItems}
          ganttGroup={ganttGroup}
          value={value}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <FixedslotStaffAccordion
          searchDepartmentList={searchDepartmentList}
          expandedMainAccordion={expandedMainAccordion}
          handleAccordion={handleAccordion}
          expandedAccordion={expandedAccordion}
          handleClassAccordion={handleClassAccordion}
          statusCode={statusCode}
          ganttStaffItems={ganttStaffItems}
          ganttGroup={ganttGroup}
          value={value}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <FixedslotRoomAccordion
          searchBuildingList={searchBuildingList}
          expandedMainAccordion={expandedMainAccordion}
          handleAccordion={handleAccordion}
          expandedAccordion={expandedAccordion}
          handleClassAccordion={handleClassAccordion}
          statusCode={statusCode}
          ganttRoomItems={ganttRoomItems}
          ganttGroup={ganttGroup}
          value={value}
        />
      </CustomTabPanel>
    </Box>
  );
}
