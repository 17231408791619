import React from "react";
import Timeline, {
  TimelineHeaders,
  DateHeader,
  SidebarHeader,
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import { GanttChartModel } from "../FixedSlot Models/GanttChartModel";
import "./GanttChart.css";

// ==============================|| CLASS GANTTCHART ||============================== //

export default function GanttChart({
  ganttGroup,
  resultItems,
  ganttItems,
  statusCode,
  value,
}) {
  const defaultTimeStart = moment().startOf("day").add(9, "hour");
  const defaultTimeEnd = moment().endOf("day").add(-8, "hour");

  const getClassNameForDialog = (item, ganttGroup) => {
    if (ganttGroup[0].id === item.group && item.sTime === "09:30") {
      return `gantt-dialog fixed-group0-time-first`;
    }
    if (ganttGroup[0].id === item.group && item.sTime === "10:30") {
      return `gantt-dialog fixed-group0-time-first`;
    }
    if (ganttGroup[0].id === item.group && item.sTime === "11:30") {
      return `gantt-dialog fixed-group0-time-first`;
    }
    if (ganttGroup[0].id === item.group && item.sTime === "01:30") {
      return `gantt-dialog fixed-group0-time-four`;
    }
    if (ganttGroup[0].id === item.group && item.sTime === "02:30") {
      return `gantt-dialog fixed-group0-time-last`;
    }
    if (ganttGroup[1].id === item.group && item.sTime === "09:30") {
      return `gantt-dialog fixed-group1-time-first`;
    }
    if (ganttGroup[1].id === item.group && item.sTime === "10:30") {
      return `gantt-dialog fixed-group1-time-first`;
    }
    if (ganttGroup[1].id === item.group && item.sTime === "11:30") {
      return `gantt-dialog fixed-group1-time-first`;
    }
    if (ganttGroup[1].id === item.group && item.sTime === "01:30") {
      return `gantt-dialog fixed-group1-time-four`;
    }
    if (ganttGroup[1].id === item.group && item.sTime === "02:30") {
      return `gantt-dialog fixed-group1-time-last`;
    }
    if (ganttGroup[2].id === item.group && item.sTime === "09:30") {
      return `gantt-dialog fixed-group2-time-first`;
    }
    if (ganttGroup[2].id === item.group && item.sTime === "10:30") {
      return `gantt-dialog fixed-group2-time-first`;
    }
    if (ganttGroup[2].id === item.group && item.sTime === "11:30") {
      return `gantt-dialog fixed-group2-time-first`;
    }
    if (ganttGroup[2].id === item.group && item.sTime === "01:30") {
      return `gantt-dialog fixed-group2-time-four`;
    }
    if (ganttGroup[2].id === item.group && item.sTime === "02:30") {
      return `gantt-dialog fixed-group2-time-last`;
    }
    if (ganttGroup[3].id === item.group && item.sTime === "09:30") {
      return `gantt-dialog fixed-group3-time-first`;
    }
    if (ganttGroup[3].id === item.group && item.sTime === "10:30") {
      return `gantt-dialog fixed-group3-time-first`;
    }
    if (ganttGroup[3].id === item.group && item.sTime === "11:30") {
      return `gantt-dialog fixed-group3-time-first`;
    }
    if (ganttGroup[3].id === item.group && item.sTime === "01:30") {
      return `gantt-dialog fixed-group3-time-four`;
    }
    if (ganttGroup[3].id === item.group && item.sTime === "02:30") {
      return `gantt-dialog fixed-group3-time-last`;
    }
    if (ganttGroup[4].id === item.group && item.sTime === "09:30") {
      return `gantt-dialog fixed-group3-time-first`;
    }
    if (ganttGroup[4].id === item.group && item.sTime === "10:30") {
      return `gantt-dialog fixed-group3-time-first`;
    }
    if (ganttGroup[4].id === item.group && item.sTime === "11:30") {
      return `gantt-dialog fixed-group3-time-first`;
    }
    if (ganttGroup[4].id === item.group && item.sTime === "01:30") {
      return `gantt-dialog fixed-group3-time-four`;
    }
    if (ganttGroup[4].id === item.group && item.sTime === "02:30") {
      return `gantt-dialog fixed-group3-time-last`;
    }
    if (ganttGroup[5].id === item.group && item.sTime === "09:30") {
      return `gantt-dialog fixed-group5-time-first`;
    }
    if (ganttGroup[5].id === item.group && item.sTime === "10:30") {
      return `gantt-dialog fixed-group5-time-first`;
    }
    if (ganttGroup[5].id === item.group && item.sTime === "11:30") {
      return `gantt-dialog fixed-group5-time-first`;
    }
    if (ganttGroup[5].id === item.group && item.sTime === "01:30") {
      return `gantt-dialog fixed-group5-time-four`;
    }
    if (ganttGroup[5].id === item.group && item.sTime === "02:30") {
      return `gantt-dialog fixed-group5-time-last`;
    } else {
      return "gantt-dialog";
    }
  };

  // Function for Rendering the Item Content
  const itemRenderer = ({ item, itemContext, getItemProps }) => {
    let borderColor;
    let backgroundColor;
    const color = "#000";

    if (itemContext.selected) {
      if (item.combinedClassId === null) {
        if (item.courseType === "Lab") {
          borderColor = "#0000ff";
        }
        borderColor = "#80daeb  !important";
        backgroundColor = "#80daeb   !important";
      } else if (item.combinedClassId !== null) {
        if (item.courseType === "Lab") {
          borderColor = "#0000ff";
        }
        borderColor = "#bdda57  !important";
        backgroundColor = "#bdda57  !important";
      }
    } else if (item.combinedClassId === null) {
      if (item.courseType === "Lab") {
        borderColor = "#0000ff";
      } else {
        borderColor = "#80daeb";
      }
      backgroundColor = "#80daeb";
    } else if (item.combinedClassId !== null) {
      if (item.courseType === "Lab") {
        borderColor = "#0000ff";
      } else {
        borderColor = "#bdda57";
      }
      backgroundColor = "#bdda57";
    } else {
      borderColor = "#E3E5EA";
      backgroundColor = "#E3E5EA";
    }

    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            color,
            borderColor,
            borderStyle: "solid",
            height: 5,
            borderWidth: 2,
            borderRadius: 12,
            borderLeftWidth: itemContext.selected ? 3 : 1,
            borderRightWidth: itemContext.selected ? 3 : 1,
            fontSize: "0.8rem",
            paddingLeft: "0.5rem",
          },
        })}
      >
        {item.title.length > 10
          ? `${item.title.substring(0, 10)}...`
          : item.title}

        {itemContext.selected && (
          <div className={getClassNameForDialog(item, ganttGroup)}>
            <GanttChartModel item={item} />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Timeline
        groups={ganttGroup}
        items={resultItems}
        visibleTimeStart={defaultTimeStart}
        visibleTimeEnd={defaultTimeEnd}
        timeSteps={{
          day: 1,
          month: 1,
          year: 1,
        }}
        lineHeight={40}
        itemHeightRatio={0.7}
        fullUpdate
        stackItems
        itemRenderer={itemRenderer}
        onTimeChange={(_start, _end, updateScrollCanvas) => {
          updateScrollCanvas(
            moment(defaultTimeEnd).valueOf(),
            moment(defaultTimeEnd).valueOf(),
          );
        }}
        canMove={false}
        canResize={false}
        canChangeGroup={false}
      >
        <TimelineHeaders className="timeline-header">
          <SidebarHeader>
            {({ getRootProps }) => {
              return (
                <div
                  {...getRootProps({
                    style: {
                      width: 30,
                      color: "#000",
                      fontFamily: "sans-serif",
                      fontSize: " 0.875rem",
                      marginTop: "0.35rem",
                    },
                  })}
                >
                  <center>
                    <b>Day Order / Hours</b>
                  </center>
                </div>
              );
            }}
          </SidebarHeader>
          <DateHeader
            className="timeline-header__date"
            unit="hour"
            labelFormat="HH:mm"
          />
        </TimelineHeaders>
      </Timeline>
    </>
  );
}
