// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  addCourseModel: false,
  editCourseModel: false,
  deleteCourseModel: false,
  courseList: [],
  courseId: null,
  courseInfo: [],
  courseInFixedSlot: [],
  isCourseStatusCode: false,
};

// ==============================|| SLICE - COURSE MENU ||============================== //

const course_menu = createSlice({
  name: "course_menu",
  initialState,
  reducers: {
    AddCourseModel(state, action) {
      state.addCourseModel = action.payload;
    },
    EditCourseModel(state, action) {
      state.editCourseModel = action.payload;
    },
    DeleteCourseModel(state, action) {
      state.deleteCourseModel = action.payload;
    },
    AddCourseList(state, action) {
      state.courseList = action.payload;
    },
    CourseId(state, action) {
      state.courseId = action.payload;
    },
    CourseInfo(state, action) {
      state.courseInfo = action.payload;
    },
    CourseInFixedSlot(state, action) {
      state.courseInFixedSlot = action.payload;
    },
    IsCourseStatusCode(state, action) {
      state.isCourseStatusCode = action.payload;
    },
  },
});

export default course_menu.reducer;

export const {
  AddCourseModel,
  EditCourseModel,
  DeleteCourseModel,
  AddCourseList,
  CourseId,
  CourseInfo,
  CourseInFixedSlot,
  IsCourseStatusCode,
} = course_menu.actions;
