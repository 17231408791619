import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./SemesterSelect.css";

// ==============================|| SEMESTER SELECT WARNING ||============================== //

function SemesterSelect() {
  const navigate = useNavigate();
  return (
    <>
      <div className="center-container">
        <Typography className="select-semester-text">
          <a
            href="/home"
            onClick={() => navigate("/home")}
            className="warning-text-style"
          >
            Please Select a Semester
          </a>
        </Typography>
      </div>
    </>
  );
}

export default SemesterSelect;
