/* eslint-disable array-callback-return */
import React from "react";
import { Grid, Typography, Stack } from "@mui/material";
import { useSelector } from "react-redux";

const PossibleRoomsModelStaffList = ({ classCourseRoom }) => {
  const building_menu = useSelector((state) => state.infrastructure_menu);
  const buildingList = building_menu.buildingList;
  const addNewRoom = building_menu.addNewRoom;

  // Function used to store building data
  const buildingHeader = [];
  buildingList.map((build) => {
    buildingHeader.push(build);
  });

  // Function used to store room data
  const roomList = [];
  addNewRoom.map((room) => {
    roomList.push(room);
  });

  let capacity = [],
    building = [];
  const filteredRooms = roomList
    .filter((room) => classCourseRoom.includes(room.id))
    .map((room) => ({
      id: room.id,
      roomName: room.roomName,
      roomCapacity: room.roomCapacity,
      multipleCourseAllowedAtOnetime: room.multipleCourseAllowedAtOnetime,
      buildingId: room.buildingId,
    }));

  filteredRooms.map((res) => {
    capacity.push(res.roomCapacity);
  });

  filteredRooms.map((res) => {
    buildingHeader
      .filter((build) => build.id === res.buildingId)
      .map((val) => {
        building.push(val.buildingName);
      });
  });
  return (
    <>
      <Grid>
        {filteredRooms.map((room, index) => (
          <div key={room.id}>
            <Stack direction="col">
              <Typography className="institution-details1">
                Building Name:
              </Typography>
              <Typography className="institution-details2">
                {building[filteredRooms.indexOf(room)]}
              </Typography>
            </Stack>

            <Stack direction="col" sx={{ mt: -1.5 }}>
              <Typography className="institution-details1">
                Room Name:
              </Typography>
              <Typography className="institution-details2">
                {room.roomName}
              </Typography>
            </Stack>

            <Stack direction="col" sx={{ mt: -1.5, mb: -1.7 }}>
              <Typography className="institution-details1">
                Room Capacity:
              </Typography>
              <Typography className="institution-details2">
                {capacity[filteredRooms.indexOf(room)]}
              </Typography>
            </Stack>

            {index !== filteredRooms.length - 1 && (
              <hr
                sx={{
                  color: "black",
                  width: "100%",
                }}
                flexItem
              />
            )}
          </div>
        ))}
      </Grid>
    </>
  );
};

export default PossibleRoomsModelStaffList;
