import React from "react";
import {
  Grid,
  Paper,
  Stack,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CombinedClassesList from "../CombinedClasses/CombinedClassesList";
import CombinedClassContent from "./CombinedClassContent";
import { AddCombinedClassModel } from "../../../Store/Reducers/combined_class_menu";
import { useDispatch, useSelector } from "react-redux";
import CombinedClassModel from "./CombinedClass Model/CombinedClassModel";
import Loader from "../../Loader/Loader";
import AlgorithmWarnings from "../Timetable/Warnings/AlgorithmWarnings";
import "./CombinedClasses.css";
import SemesterSelect from "../../SemesterSelectWarning/SemesterSelect";

// ==============================|| COMBINED CLASSES MAIN PAGE ||============================== //

const CombinedClasses = ({
  handleCombinedClassClick,
  handleRemoveCombinedClass,
  retrieveCombinedClassList,
}) => {
  const dispatch = useDispatch();

  const menu = useSelector((state) => state.combined_class_menu);
  const addCombinedClassModel = menu.addCombinedClassModel;
  const combinedId = menu.combinedId;
  const combinedInfo = menu.combinedInfo;
  const isCombinedClassStatusCode = menu.isCombinedClassStatusCode;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const isBuildingStatusCode = infrastructure_menu.isBuildingStatusCode;
  const isDepartmentStatusCode = infrastructure_menu.isDepartmentStatusCode;

  const course_menu = useSelector((state) => state.course_menu);
  const isCourseStatusCode = course_menu.isCourseStatusCode;

  const class_menu = useSelector((state) => state.class_menu);
  const isClassStatusCode = class_menu.isClassStatusCode;

  const staff_menu = useSelector((state) => state.staff_menu);
  const isStaffStatusCode = staff_menu.isStaffStatusCode;

  const fixedslot_menu = useSelector((state) => state.fixedslot_menu);
  const isFixedSlotStatusCode = fixedslot_menu.isFixedSlotStatusCode;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  return (
    <>
      {(isBuildingStatusCode === false ||
        isDepartmentStatusCode === false ||
        isCourseStatusCode === false ||
        isClassStatusCode === false ||
        isCombinedClassStatusCode === false ||
        isStaffStatusCode === false ||
        isFixedSlotStatusCode === false ||
        algorithmStatus === "") &&
      sessionStorage.getItem("branchName") !== null ? (
        <Loader />
      ) : (
        <>
          {sessionStorage.getItem("branchName") !== null ? (
            <>
              <div className="display-warnings">
                <AlgorithmWarnings />
              </div>
              <Paper
                className="cc-paper-padding"
                elevation={1}
                sx={{ mt: -0.1 }}
              >
                <Grid container className="content-body">
                  <Grid>
                    <Grid item xs={8}>
                      <Stack direction="row" sx={{ mt: -3 }}>
                        <Tooltip
                          title="Add Course for Combined"
                          placement="right"
                          arrow
                        >
                          <IconButton
                            className={
                              algorithmStatus === null ||
                              algorithmStatus === "FAILED" ||
                              algorithmStatus === "UNKNOWN" ||
                              algorithmStatus === "NOT_CREATED"
                                ? "add-class-button"
                                : "add-class-button-disabled"
                            }
                            type="submit"
                            variant="contained"
                            onClick={() => {
                              dispatch(AddCombinedClassModel(true));
                            }}
                            disabled={
                              algorithmStatus !== null &&
                              algorithmStatus !== "FAILED" &&
                              algorithmStatus !== "UNKNOWN" &&
                              algorithmStatus !== "NOT_CREATED"
                            }
                          >
                            <AddIcon className="add-icon" />
                          </IconButton>
                        </Tooltip>
                        <Typography className="combined-class-text">
                          <b>Combined Classes</b>
                        </Typography>
                      </Stack>
                    </Grid>

                    <div className="combinedclass-list">
                      <CombinedClassesList
                        handleCombinedClassClick={handleCombinedClassClick}
                        handleRemoveCombinedClass={handleRemoveCombinedClass}
                      />

                      {addCombinedClassModel === true && <CombinedClassModel />}
                    </div>
                  </Grid>
                  {combinedId && combinedInfo && (
                    <CombinedClassContent
                      retrieveCombinedClassList={retrieveCombinedClassList}
                    />
                  )}
                </Grid>
              </Paper>
            </>
          ) : (
            <SemesterSelect />
          )}
        </>
      )}
    </>
  );
};

export default CombinedClasses;
