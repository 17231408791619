/* eslint-disable array-callback-return */
import React from "react";
import {
  Grid,
  Stack,
  Typography,
  List,
  ListItem,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Divider,
  ListSubheader,
  Tooltip,
  Chip,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  EditClassCourseModel,
  DeleteClassCourseModel,
} from "../../../Store/Reducers/class_menu";
import EditClassCourseAssignment from "./Classes Model/EditClassCourseAssignment";
import DeleteClassCourseAssignment from "./Classes Model/DeleteClassCourseAssignment";
import PossibleRoomsModel from "./Classes Model/PossibleRoomsModel";
import "../Classes/ClassContent.css";

// ==============================|| DISPLAYING COURSE IN CLASS LIST ||============================== //

const ClassCourseAssignmentList = ({
  handleClassCourseClick,
  courseCode,
  courseName,
  noOfStudents,
  possibleRooms,
  noOfStaff,
  noOfRoom,
  handleRemoveClassCourse,
  className,
}) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.class_menu);
  const editClassCourseModel = menu.editClassCourseModel;
  const deleteClassCourseModel = menu.deleteClassCourseModel;
  const classCourseAssignment = menu.classCourseAssignment;
  const classCourseId = menu.classCourseId;
  const classCourseInFixedSlot = menu.classCourseInFixedSlot;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const addNewRoom = infrastructure_menu.addNewRoom;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function used to split the possible room for display
  const getPossibleRoomsSplit = (rooms) => {
    if (rooms !== undefined) {
      let matchingRoomNames = [];
      classCourseAssignment.map((cls) => {
        matchingRoomNames = rooms.map((roomId) =>
          addNewRoom
            .filter((room) => room.id === roomId)
            .map((matchingRoom) => matchingRoom.roomName),
        );
      });
      return matchingRoomNames.join(", ");
    }
  };

  // Function used to retrieve the course code
  const getCourseCode = (id, cid) => {
    const course = courseList.find((res) => res.id === id);
    const maxLengthCode = 7;

    if (course && classCourseInFixedSlot.includes(cid)) {
      return (
        <span>
          <Tooltip
            title="Can't Edit and Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip title={course.courseCode}>
            <span>
              {course.courseCode.length > 6
                ? `${course.courseCode.substring(0, 6)}...`
                : course.courseCode}
            </span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip title={course.courseCode}>
          <span>
            {course.courseCode.length > maxLengthCode
              ? `${course.courseCode.substring(0, maxLengthCode)}...`
              : course.courseCode}
          </span>
        </Tooltip>
      );
    }
  };

  // Function used to retrieve the course name
  const getCourseName = (id) => {
    const course = courseList.find((code) => code.id === id);
    if (course) {
      return course.courseName;
    }
  };

  // Function used to retrieve the course hours
  const getCourseHours = (id) => {
    const course = courseList.find((code) => code.id === id);
    if (course) {
      return course.courseHours;
    }
  };
  let totalCourseHours = 0;
  let len = classCourseAssignment.length;
  let i = 0;

  const getTotalCourseHours = (id) => {
    const course = courseList.find((code) => code.id === id);
    if (course) {
      totalCourseHours = totalCourseHours + course.courseHours;
      i++;
    }
    if (i === len) {
      return totalCourseHours;
    }
  };

  // Function used to retrieve the course type
  const getCourseType = (id) => {
    const course = courseList.find((code) => code.id === id);
    if (course) {
      return course.courseTypeName;
    }
  };

  const maxLengthName = 10;

  // Function to truncate long names
  const truncateName = (name) => {
    if (name.length > maxLengthName) {
      return `${name.substring(0, maxLengthName)}...`;
    }

    return name;
  };

  return (
    <Grid>
      <List className="classcourse-list-style" subheader={<li />}>
        <ListSubheader>
          <Grid container className="class-course-list_header-Grid">
            <Grid>
              <Typography className="class-course-header">
                Course Code
              </Typography>
            </Grid>

            <Grid>
              <Typography className="class-course-header">
                Course Name
              </Typography>
            </Grid>
            <Grid>
              <Typography className="class-course-header">
                Course <br></br>Type
              </Typography>
            </Grid>
            <Grid>
              <Typography className="class-course-header">
                Course <br></br>Hours
              </Typography>
            </Grid>
            <Grid>
              <Typography className="class-course-header">
                Number of <br></br>students
              </Typography>
            </Grid>
            <Grid>
              <Typography className="class-course-header">
                Possible<br></br>rooms
              </Typography>
            </Grid>
            <Grid>
              <Typography className="class-course-header">
                Number of <br></br>Staff required <br></br>at one time
              </Typography>
            </Grid>
          </Grid>
        </ListSubheader>
        <Divider sx={{ ml: 3 }} />
        <div className="list-container classCourseList-height">
          {classCourseAssignment.map((courseValue) => (
            <>
              <li className="comclass-list list-width">
                <Button
                  className={
                    classCourseId === courseValue.id
                      ? "class-course-content--button--selected class-course-content--button"
                      : "class-course-content--button"
                  }
                  onClick={(event) => {
                    handleClassCourseClick(courseValue.id);
                  }}
                >
                  <ListItem>
                    <Grid item xs={2}>
                      {courseValue.combinedClassId === null ? (
                        <Chip
                          label={getCourseCode(
                            courseValue.courseId,
                            courseValue.id,
                          )}
                          className="custom-chip-notcombined"
                        />
                      ) : (
                        <Chip
                          label={getCourseCode(
                            courseValue.courseId,
                            courseValue.id,
                          )}
                          className="custom-chip-iscombined"
                        />
                      )}
                    </Grid>

                    <Grid item xs={2.7}>
                      <Stack
                        direction="row"
                        className="list-title-sort"
                        sx={{ ml: 2.4, mt: 0 }}
                      >
                        <Tooltip title={getCourseName(courseValue.courseId)}>
                          {courseValue.combinedClassId === null ? (
                            <Chip
                              label={truncateName(
                                getCourseName(courseValue.courseId),
                              )}
                              className="custom-chip-notcombined"
                            />
                          ) : (
                            <Chip
                              label={truncateName(
                                getCourseName(courseValue.courseId),
                              )}
                              className="custom-chip-iscombined"
                            />
                          )}
                        </Tooltip>
                      </Stack>
                    </Grid>
                    <Grid item xs={1.3}>
                      <Stack direction="row" className="list-title-sort">
                        <Typography className="room-header1">
                          {getCourseType(courseValue.courseId)}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid xs={1.4}>
                      <Typography className="room-header1">
                        {getCourseHours(courseValue.courseId)}
                      </Typography>
                    </Grid>
                    <Grid xs={1.6}>
                      <Typography className="room-header1">
                        {courseValue.numberOfStudents}
                      </Typography>
                    </Grid>
                    <Grid xs={1.3}>
                      {courseValue.possibleRooms !== null &&
                      courseValue.possibleRooms.length !== 0 ? (
                        <Tooltip
                          title={
                            <div className="possibleRooms-hover">
                              <PossibleRoomsModel
                                getPossibleRoomsSplit={getPossibleRoomsSplit}
                                classCourseAssignment={courseValue}
                              />
                            </div>
                          }
                        >
                          <Typography className="possiblerooms-header">
                            <u>
                              {courseValue.possibleRooms !== null &&
                                courseValue.possibleRooms.length !== null &&
                                courseValue.possibleRooms.length}
                            </u>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography className="possiblerooms-header">
                          <u>
                            {courseValue.possibleRooms !== null &&
                              courseValue.possibleRooms.length !== null &&
                              courseValue.possibleRooms.length}
                          </u>
                        </Typography>
                      )}
                    </Grid>
                    <Grid>
                      <Typography className="room-header1">
                        {courseValue.numberOfStaffRequiredAtOneTime}
                      </Typography>
                    </Grid>
                    <IconButton
                      aria-label="more"
                      onClick={(event) => {
                        handleClick(event);
                        handleClassCourseClick(courseValue.id);
                      }}
                      aria-haspopup="true"
                      aria-controls="long-menu"
                      className="room-menu-icon"
                    >
                      <MoreVertIcon className="moreVertIcon-style" />
                    </IconButton>
                  </ListItem>
                </Button>
              </li>
            </>
          ))}
          <Typography sx={{ marginLeft: "80%", mt: 1 }}>
            <b className="hoursText-color">Total Hours : </b>

            {classCourseAssignment.map((courseValue) =>
              getTotalCourseHours(courseValue.courseId),
            )}
          </Typography>
        </div>
      </List>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(EditClassCourseModel(true));
            handleClose();
          }}
          disabled={
            classCourseInFixedSlot.includes(classCourseId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
          className="room-menu"
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(DeleteClassCourseModel(true));
            handleClose();
          }}
          disabled={
            classCourseInFixedSlot.includes(classCourseId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
        >
          Delete
        </MenuItem>
      </Menu>
      {editClassCourseModel === true && (
        <EditClassCourseAssignment className={className} />
      )}

      {deleteClassCourseModel === true && (
        <DeleteClassCourseAssignment
          handleRemoveClassCourse={handleRemoveClassCourse}
        />
      )}
    </Grid>
  );
};

export default ClassCourseAssignmentList;
