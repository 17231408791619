// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  addFixedSlotModel: false,
  viewFixedSlotGroupModel: false,
  editFixedSlotGroupModel: false,
  deleteFixedSlotGroupModel: false,
  fixedSlotsGroup: [],
  isFixedSlotStatusCode: false,
};

// ==============================|| SLICE - MENU ||============================== //

const fixedslot_menu = createSlice({
  name: "fixedslot_menu",
  initialState,
  reducers: {
    AddFixedSlotModel(state, action) {
      state.addFixedSlotModel = action.payload;
    },
    ViewFixedSlotGroupModel(state, action) {
      state.viewFixedSlotGroupModel = action.payload;
    },
    EditFixedSlotGroupModel(state, action) {
      state.editFixedSlotGroupModel = action.payload;
    },
    DeleteFixedSlotGroupModel(state, action) {
      state.deleteFixedSlotGroupModel = action.payload;
    },
    FixedSlotsGroup(state, action) {
      state.fixedSlotsGroup = action.payload;
    },
    IsFixedSlotStatusCode(state, action) {
      state.isFixedSlotStatusCode = action.payload;
    },
  },
});

export default fixedslot_menu.reducer;

export const {
  AddFixedSlotModel,
  ViewFixedSlotGroupModel,
  EditFixedSlotGroupModel,
  DeleteFixedSlotGroupModel,
  FixedSlotsGroup,
  IsFixedSlotStatusCode,
} = fixedslot_menu.actions;
