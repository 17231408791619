import React, { useState } from "react";
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import DepartmentModel from "./Department Model/DepartmentModel";
import {
  AddDepartmentModel,
  AddDepartment,
} from "../../../../Store/Reducers/infrastructure_menu";
import DepartmentList from "./DepartmentList";
import DepartmentService from "../../../../Services/DepartmentService";

// ==============================|| DEPARTMENT MAIN PAGE ||============================== //

const Department = ({ handleDepartmentClick, handleRemoveDepartment }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.infrastructure_menu);
  const addDepartmentModel = menu.addDepartmentModel;
  const departmentList = menu.departmentList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  /*************** Add Department **************************/

  // Local variable for building
  const newDepartment = {
    departmentName: "",
  };
  const [department, setDepartment] = useState(newDepartment);
  const [addDepartmentSuccessful, setAddDepartmentSuccessful] = useState(false);
  const [addDepartmentError, setAddDepartmentError] = useState(false);
  const [handleAddDepartmentError, sethandleAddDepartmentError] = useState("");

  // Function used to handle department name
  const handleDepartmentName = (event) => {
    const { name, value } = event.target;
    setDepartment({ [name]: value });
  };

  // Function used to save the new department
  const handleSave = (event) => {
    let data = {
      departmentName: department.departmentName.trim(),
    };
    DepartmentService.createDepartment(data)
      .then((res) => {
        dispatch(AddDepartment([...departmentList, res.data]));
        dispatch(AddDepartmentModel(false));
        if (res.status === 200) {
          setAddDepartmentSuccessful(true);
        } else {
          setAddDepartmentError(true);
        }
      })
      .catch((error) => {
        sethandleAddDepartmentError(error.response.data);
      });
  };

  // Function for closing the snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAddDepartmentSuccessful(false);
    setAddDepartmentError(false);
  };

  return (
    <>
      <Grid container>
        <Tooltip title="Add Department" placement="bottom" arrow>
          <IconButton
            className={
              algorithmStatus === null ||
              algorithmStatus === "FAILED" ||
              algorithmStatus === "UNKNOWN" ||
              algorithmStatus === "NOT_CREATED"
                ? "add-department-button"
                : "add-department-button-disabled"
            }
            type="submit"
            variant="contained"
            onClick={() => {
              dispatch(AddDepartmentModel(true));
            }}
            disabled={
              algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED"
            }
          >
            <AddIcon className="add-icon" />
          </IconButton>
        </Tooltip>
        <Typography className="department-text">
          <b>Departments</b>
        </Typography>

        <DepartmentList
          handleDepartmentClick={handleDepartmentClick}
          handleRemoveDepartment={handleRemoveDepartment}
        />
        {addDepartmentModel === true && (
          <DepartmentModel
            department={department}
            handleDepartmentName={handleDepartmentName}
            handleSave={handleSave}
            handleAddDepartmentError={handleAddDepartmentError}
          />
        )}
      </Grid>
      <Snackbar
        open={addDepartmentSuccessful}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          className="alert-success-style"
        >
          New Department successfully added!
        </Alert>
      </Snackbar>
      <Snackbar
        open={addDepartmentError}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          className="alert-error-style"
        >
          Error in adding new department!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Department;
