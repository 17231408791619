import React from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  Menu,
  MenuItem,
  Typography,
  ListSubheader,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector, useDispatch } from "react-redux";
import EditCombinedClass from "./CombinedClass Model/EditCombinedClass";
import {
  EditCombinedClassModel,
  DeleteCombinedClassModel,
} from "../../../Store/Reducers/combined_class_menu";
import DeleteCombinedClass from "./CombinedClass Model/DeleteCombinedClass";
import "./CombinedClasses.css";

// ==============================|| COMBINED CLASS LIST ||============================== //

const CombinedClassesList = ({
  handleCombinedClassClick,
  handleRemoveCombinedClass,
}) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.combined_class_menu);
  const editCombinedClassModel = menu.editCombinedClassModel;
  const deleteCombinedClassModel = menu.deleteCombinedClassModel;
  const classCombinedList = menu.classCombinedList;
  const combinedId = menu.combinedId;
  const combinedInFixedSlot = menu.combinedInFixedSlot;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function used to retrieve the course code
  const getCourseCode = (id, cid) => {
    const course = courseList.find((res) => res.id === id);

    if (course && combinedInFixedSlot.includes(cid)) {
      return (
        <span>
          <Tooltip
            title="Can't Edit and Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip title={course.courseCode}>
            <span>{course.courseCode}</span>
          </Tooltip>
        </span>
      );
    } else {
      return course && course.courseCode ? (
        <Tooltip title={course.courseCode}>
          <span>{course.courseCode}</span>
        </Tooltip>
      ) : null;
    }
  };

  // Function used to retrieve the course name
  const getCourseName = (id) => {
    const course = courseList.find((code) => code.id === id);
    if (course) {
      return course.courseName;
    }
  };

  return (
    <Grid container className="course-page">
      <Grid container>
        <Grid item xs={12}>
          <List className="combinedclass-list-style" subheader={<li />}>
            <ListSubheader className="comclass-list_header">
              <Grid container mt={2}>
                <Grid item xs={3.5}>
                  <Typography className="comclass-header" sx={{ ml: -0.5 }}>
                    Course Code
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="comclass-header">
                    Course name
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="comclass-header">
                    Combined Class Name
                  </Typography>
                </Grid>
              </Grid>
            </ListSubheader>
            <Divider className="comclass-divider" />
            <div className="list-combined-container combinedclass-list-height">
              {classCombinedList.map((obj) => (
                <li className="comclass-list list-width">
                  <Button
                    className={
                      combinedId === obj.id
                        ? "combinedclass-content--button--selected combinedclass-content--button"
                        : "combinedclass-content--button"
                    }
                    onClick={() => {
                      handleCombinedClassClick(obj.id);
                    }}
                  >
                    <Grid container sx={{ ml: 2 }}>
                      <Grid
                        item
                        xs={3.5}
                        style={{
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        className="align-text"
                      >
                        {getCourseCode(obj.courseId, obj.id)}
                      </Grid>
                      <Grid item xs={3.8} className="align-text" sx={{ ml: 1 }}>
                        <Tooltip title={getCourseName(obj.courseId)}>
                          {getCourseName(obj.courseId)
                            ? getCourseName(obj.courseId).length > 13
                              ? `${getCourseName(obj.courseId).substring(
                                  0,
                                  13,
                                )}...`
                              : getCourseName(obj.courseId)
                            : "Course Name Not Found"}
                        </Tooltip>
                      </Grid>
                      <Grid item xs={3} className="align-text">
                        <Tooltip title={obj.combinedClassName}>
                          {obj.combinedClassName
                            ? obj.combinedClassName.length > 7
                              ? `${obj.combinedClassName.substring(0, 7)}...`
                              : obj.combinedClassName
                            : "Class Name Not Found"}
                        </Tooltip>
                        <IconButton
                          aria-label="more"
                          onClick={handleClick}
                          aria-haspopup="true"
                          aria-controls="long-menu"
                          className="combinedclass-menu-icon"
                        >
                          <MoreVertIcon className="moreVertIcon-style" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Button>
                </li>
              ))}
            </div>
          </List>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              dispatch(EditCombinedClassModel(true));
              handleClose();
            }}
            disabled={
              combinedInFixedSlot.includes(combinedId) ||
              (algorithmStatus !== null &&
                algorithmStatus !== "FAILED" &&
                algorithmStatus !== "UNKNOWN" &&
                algorithmStatus !== "NOT_CREATED")
            }
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(DeleteCombinedClassModel(true));
              handleClose();
            }}
            disabled={
              combinedInFixedSlot.includes(combinedId) ||
              (algorithmStatus !== null &&
                algorithmStatus !== "FAILED" &&
                algorithmStatus !== "UNKNOWN" &&
                algorithmStatus !== "NOT_CREATED")
            }
          >
            Delete
          </MenuItem>
        </Menu>
        {editCombinedClassModel === true && <EditCombinedClass />}
        {deleteCombinedClassModel === true && (
          <DeleteCombinedClass
            handleRemoveCombinedClass={handleRemoveCombinedClass}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CombinedClassesList;
