import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http-common";
import { getBranchName } from "../Utils/common";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");
// ========================================|| DEPARTMENT SERVICE ||======================================== //

const getAll = () => {
  return apiService.get("/department/getAll", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getById = (id) => {
  return apiService.get(`/department/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const createDepartment = (data) => {
  return apiService.post("/department", data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const editDepartment = (id, data) => {
  return apiService.put(`/department/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const deleteDepartment = (id) => {
  return apiService.delete(`/department/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const DepartmentService = {
  getAll,
  getById,
  createDepartment,
  editDepartment,
  deleteDepartment,
};

export default DepartmentService;
