/* eslint-disable array-callback-return */
import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteClassCombinedModel } from "../../../../Store/Reducers/combined_class_menu";
import CombinedClassService from "../../../../Services/CombinedClassService";
import { AddCombinedClassList } from "../../../../Store/Reducers/combined_class_menu";

// ==============================|| DELETE CLASS CONFIRMATION MODEL ||============================== //

const DeleteClassCombined = ({
  retrieveCombinedClassList,
  classCombinedId,
}) => {
  const menu = useSelector((state) => state.combined_class_menu);
  const deleteClassCombinedModel = menu.deleteClassCombinedModel;
  const classCombinedList = menu.classCombinedList;
  const combinedClassList = menu.combinedClassList;
  const combinedClassId = menu.combinedId;

  const class_menu = useSelector((state) => state.class_menu);
  const classCourseAssignment = class_menu.classCourseAssignmentAll;

  let modalOpen = false;
  deleteClassCombinedModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const [deleteClassCombined, setDeleteClassCombined] = useState(false);

  const dispatch = useDispatch();

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(DeleteClassCombinedModel(false));
  };

  // Function used to delete a particular classCourseAssignment
  const handleDelete = () => {
    classCombinedList
      .filter((cid) => cid.id === combinedClassId)
      .forEach((item, index) => {
        // Check if item is defined before accessing its properties
        if (item && item.classCourseAssignmentId) {
          const res = classCourseAssignment.find(
            (clsid) => clsid.id === classCombinedId,
          );

          if (res) {
            // Create a new array without the element to be removed
            const updatedClassCourseAssignmentId =
              item.classCourseAssignmentId.filter((id) => id !== res.id);

            let data = {
              combinedClassId: combinedClassId,
              classCourseAssignmentId: updatedClassCourseAssignmentId,
            };

            CombinedClassService.addClassCombined(data).then((res) => {
              dispatch(AddCombinedClassList([...combinedClassList, res.data]));
              retrieveCombinedClassList();
            });
          }
        }
      });
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "23.7rem", height: "13rem", boxShadow: "none" },
        }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            {" Clear Confirmation"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="model-close-icon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText className="dialog-style" align="center">
            Are you sure you want to Delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button
            disabled={deleteClassCombined === true}
            className={
              deleteClassCombined === true ? "yes-btn-disabled" : "yes-btn"
            }
            onClick={() => {
              handleDelete();
              setDeleteClassCombined(true);
            }}
            variant="outlined"
          >
            Yes
          </Button>
          <Button className="no-btn" onClick={handleClose} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteClassCombined;
