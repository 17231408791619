// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  addClassModel: false,
  editClassModel: false,
  deleteClassModel: false,
  classList: [],
  classId: null,
  classInfo: [],
  classInFixedSlot: [],
  isClassStatusCode: false,

  addClassCourseModel: false,
  editClassCourseModel: false,
  deleteClassCourseModel: false,
  classCourseAssignment: [],
  classCourseAssignmentAll: [],
  classCourseId: null,
  classCourseInfo: [],
  classCourseInFixedSlot: [],
  isClassCourseStatusCode: false,
};

// ==============================|| SLICE - CLASS MENU ||============================== //

const class_menu = createSlice({
  name: "class_menu",
  initialState,
  reducers: {
    AddClassModel(state, action) {
      state.addClassModel = action.payload;
    },
    EditClassModel(state, action) {
      state.editClassModel = action.payload;
    },
    DeleteClassModel(state, action) {
      state.deleteClassModel = action.payload;
    },
    AddClassList(state, action) {
      state.classList = action.payload;
    },
    ClassId(state, action) {
      state.classId = action.payload;
    },
    ClassInfo(state, action) {
      state.classInfo = action.payload;
    },
    ClassInFixedSlot(state, action) {
      state.classInFixedSlot = action.payload;
    },
    IsClassStatusCode(state, action) {
      state.isClassStatusCode = action.payload;
    },

    AddClassCourseModel(state, action) {
      state.addClassCourseModel = action.payload;
    },
    EditClassCourseModel(state, action) {
      state.editClassCourseModel = action.payload;
    },
    DeleteClassCourseModel(state, action) {
      state.deleteClassCourseModel = action.payload;
    },
    AddClassCourseAssignment(state, action) {
      state.classCourseAssignment = action.payload;
    },
    AddClassCourseAssignmentAll(state, action) {
      state.classCourseAssignmentAll = action.payload;
    },
    ClassCourseId(state, action) {
      state.classCourseId = action.payload;
    },
    ClassCourseInfo(state, action) {
      state.classCourseInfo = action.payload;
    },
    ClassCourseInFixedSlot(state, action) {
      state.classCourseInFixedSlot = action.payload;
    },
    IsClassCourseStatusCode(state, action) {
      state.isClassCourseStatusCode = action.payload;
    },
  },
});

export default class_menu.reducer;

export const {
  AddClassModel,
  EditClassModel,
  DeleteClassModel,
  AddClassList,
  ClassId,
  ClassInfo,
  ClassInFixedSlot,
  IsClassStatusCode,

  AddClassCourseModel,
  EditClassCourseModel,
  DeleteClassCourseModel,
  AddClassCourseAssignment,
  AddClassCourseAssignmentAll,
  ClassCourseId,
  ClassCourseInfo,
  ClassCourseInFixedSlot,
  IsClassCourseStatusCode,
} = class_menu.actions;
