import React from "react";
import {
  Grid,
  Button,
  List,
  Typography,
  ListItem,
  IconButton,
  Divider,
  Tooltip,
  ListSubheader,
} from "@mui/material";
import {
  EditBuildingModel,
  DeleteBuildingModel,
} from "../../../../Store/Reducers/infrastructure_menu";
import { useSelector, useDispatch } from "react-redux";
import EditBuilding from "./Building Models/EditBuilding";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteBuildingConfirmation from "./Building Models/DeleteBuildingConfirmation";
import "./BuildingList.css";

// ==============================|| LHS BUILDING LIST ||============================== //

const BuildingList = ({
  handleBuildingClick,
  handleRemoveBuilding,
  retrieveBuildingInFixedSlot,
}) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.infrastructure_menu);
  const editBuildingModel = menu.editBuildingModel;
  const deleteBuildingModel = menu.deleteBuildingModel;
  const buildingList = menu.buildingList;
  const buildingId = menu.buildingId;
  const buildingInFixedSlot = menu.buildingInFixedSlot;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function used for getting the building name
  const getBuildingName = (id) => {
    const building = buildingList.find((res) => res.id === id);

    if (building && buildingInFixedSlot.includes(id)) {
      return (
        <span>
          <Tooltip title="Can't delete because it exists in Fixed Slot">
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip title={building.buildingName}>
            <span>
              {building.buildingName.length > 18
                ? `${building.buildingName.substring(0, 18)}...`
                : building.buildingName}
            </span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip title={building.buildingName}>
          <span>
            {building.buildingName.length > 18
              ? `${building.buildingName.substring(0, 18)}...`
              : building.buildingName}
          </span>
        </Tooltip>
      );
    }
  };

  return (
    <>
      <Grid container>
        <List subheader={<li />} className="building-list">
          <ListSubheader>
            <Grid>
              <Typography
                className="name-textColor"
                sx={{ mb: 3, mt: 0.4, ml: -1 }}
              >
                <b>Building Name</b>
              </Typography>
            </Grid>
          </ListSubheader>
          <Divider />
          <div className="list-container buildinglist-height">
            {buildingList.map((obj) => (
              <li>
                <Button
                  className={
                    buildingId === obj.id
                      ? "building-content--button--selected building-content--button"
                      : "building-content--button"
                  }
                  onClick={() => {
                    handleBuildingClick(obj.id);
                  }}
                >
                  <ListItem className="building-listItem">
                    <Grid
                      item
                      xs={10}
                      className="buildingName-grid textOverflow-style"
                    >
                      {getBuildingName(obj.id)}
                    </Grid>
                    <IconButton
                      aria-label="more"
                      onClick={handleClick}
                      aria-haspopup="true"
                      aria-controls="long-menu"
                      className="building-menu-icon"
                    >
                      <MoreVertIcon className="moreVertIcon-style" />
                    </IconButton>
                  </ListItem>
                </Button>
              </li>
            ))}
          </div>
        </List>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              dispatch(EditBuildingModel(true));
              handleClose();
            }}
            disabled={
              algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED"
            }
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(DeleteBuildingModel(true));
              handleClose();
            }}
            disabled={
              (Array.isArray(buildingInFixedSlot) &&
                buildingInFixedSlot.includes(buildingId)) ||
              (algorithmStatus !== null &&
                algorithmStatus !== "FAILED" &&
                algorithmStatus !== "UNKNOWN" &&
                algorithmStatus !== "NOT_CREATED")
            }
          >
            Delete
          </MenuItem>
        </Menu>
        {editBuildingModel === true && <EditBuilding />}

        {deleteBuildingModel === true && (
          <DeleteBuildingConfirmation
            handleRemoveBuilding={handleRemoveBuilding}
          />
        )}
      </Grid>
    </>
  );
};
export default BuildingList;
