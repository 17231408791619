/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { EditClassCourseModel } from "../../../../Store/Reducers/class_menu";
import DoneIcon from "@mui/icons-material/Done";
import ClassCourseAssignment from "../../../../Services/ClassCourseAssignmentService";
import { AddClassCourseAssignment } from "../../../../Store/Reducers/class_menu";
import "./ClassModel.css";

// ==============================|| EDIT NEW COURSE IN CLASS MODEL ||============================== //

const EditClassCourseAssignment = ({ className }) => {
  const menu = useSelector((state) => state.class_menu);
  const editClassCourseModel = menu.editClassCourseModel;
  const classId = menu.classId;
  const classCourseAssignment = menu.classCourseAssignment;
  const classCourseId = menu.classCourseId;
  const classCourseInfo = menu.classCourseInfo;

  const building_menu = useSelector((state) => state.infrastructure_menu);
  const buildingList = building_menu.buildingList;
  const addNewRoom = building_menu.addNewRoom;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const dispatch = useDispatch();

  let selectedPossibleRoom = [];
  if (classCourseInfo.possibleRooms !== null) {
    addNewRoom
      .filter((val) => classCourseInfo.possibleRooms.includes(val.id))
      .map((res) => {
        selectedPossibleRoom.push(res.roomName);
      });
  }

  let modalOpen = false;
  editClassCourseModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const courseCodeOptions = [...courseList];

  const [numberOfStudentsDetails, setNumberOfStudentsDetails] =
    useState(classCourseInfo);
  const [
    numberOfStaffRequiredAtOneTimeDetails,
    setNumberOfStaffRequiredAtOneTimeDetails,
  ] = useState(classCourseInfo);
  const [numberOfRoomsRequiredDetails, setNumberOfRoomsRequiredDetails] =
    useState(classCourseInfo);

  const [editNumberOfStudents, setEditNumberOfStudents] = useState(
    classCourseAssignment.numberOfStudents,
  );
  const [
    editNumberOfStaffRequiredAtOneTime,
    setEditNumberOfStaffRequiredAtOneTime,
  ] = useState(classCourseAssignment.numberOfStaffRequiredAtOneTime);
  const [editNumberOfRoomsRequired, setEditNumberOfRoomsRequired] = useState(
    classCourseAssignment.numberOfRoomsRequired,
  );
  const [editCourseValue, setEditCourseValue] = useState("");
  const [handleBackendError, setBackendError] = useState("");
  const [editPossibleRooms, setEditPossibleRooms] =
    useState(selectedPossibleRoom);
  const [editClassCourse, setEditClassCourse] = useState(false);

  // Function used to store building data
  const buildingHeader = [];
  buildingList.map((build) => {
    buildingHeader.push(build);
  });

  // Function used to store room data
  const roomList = [];
  addNewRoom.map((room) => {
    roomList.push(room);
  });

  // Function used to display menu items
  const menuItems = buildingHeader.map((build, index) => {
    const roomsForBuilding = roomList.filter(
      (room) => room.buildingId === build.id,
    );

    return [
      <MenuItem key={`header-${build.id}`} disabled className="building-menu">
        {build.buildingName}
      </MenuItem>,
      ...roomsForBuilding.map((res) => (
        <MenuItem key={res.roomName} value={res.roomName}>
          {res.roomName} - {res.roomCapacity}
          {editPossibleRooms.includes(res.roomName) && (
            <DoneIcon sx={{ marginLeft: "50%" }} />
          )}
        </MenuItem>
      )),
    ];
  });

  // Function used to store possible rooms
  const handleChange = (event) => {
    setBackendError("");
    setEditPossibleRooms(event.target.value);
  };

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(EditClassCourseModel(false));
  };

  // Function used to handle number of students
  const handleEditNumberOfStudents = (event) => {
    setNumberOfStudentsDetails(event.target.value);
    setEditNumberOfStudents(event.target.value);
  };

  // Function used to handle number of staff required
  const handleEditNumberOfStaffRequiredAtOneTime = (event) => {
    setNumberOfStaffRequiredAtOneTimeDetails(event.target.value);
    setEditNumberOfStaffRequiredAtOneTime(event.target.value);
  };

  // Function used to handle number of rooms required
  const handleEditNumberOfRoomsRequired = (event) => {
    setBackendError("");
    setNumberOfRoomsRequiredDetails(event.target.value);
    setEditNumberOfRoomsRequired(event.target.value);
  };

  // Used to retrieve the selected course id
  let selectedCode,
    selectedCodeId,
    possibleRoomsId = [];
  if (
    (editCourseValue !== null && editCourseValue !== undefined) ||
    (selectedCode !== undefined && selectedCode !== null)
  ) {
    selectedCode = editCourseValue.split(" - ");
    selectedCode = selectedCode[0];
  }
  courseList
    .filter((course) => course.courseCode === selectedCode)
    .map((res) => {
      selectedCodeId = res.id;
    });

  editPossibleRooms.forEach((possible) => {
    addNewRoom
      .filter((room) => room.roomName === possible)
      .forEach((res) => {
        possibleRoomsId.push(res.id);
      });
  });

  // Function used to update the Class Course Assignment List
  const changeClassCourseAssignmentAll = (
    classId,
    selectedCodeId,
    editNumberOfStudents,
    editNumberOfStaffRequiredAtOneTime,
    editNumberOfRoomsRequired,
    possibleRoomsId,
  ) => {
    const updatedClassCourseDataAll = classCourseAssignment.map((obj) => {
      if (obj.id === classCourseId) {
        return {
          ...obj,
          classId: classId,
          courseId: selectedCodeId,
          numberOfStudents:
            editNumberOfStudents === undefined
              ? classCourseInfo.numberOfStudents
              : editNumberOfStudents,

          numberOfStaffRequiredAtOneTime:
            editNumberOfStaffRequiredAtOneTime === undefined
              ? classCourseInfo.numberOfStaffRequiredAtOneTime
              : editNumberOfStaffRequiredAtOneTime,
          numberOfRoomsRequired:
            editNumberOfRoomsRequired === undefined
              ? classCourseInfo.numberOfRoomsRequired
              : editNumberOfRoomsRequired,
          possibleRooms: possibleRoomsId,
        };
      }
      return obj;
    });
    dispatch(AddClassCourseAssignment(updatedClassCourseDataAll));
  };
  // Function used to edit class course assignment
  const handleEditAll = (event) => {
    const numberOfRoomsRequired =
      editNumberOfRoomsRequired !== undefined
        ? editNumberOfRoomsRequired
        : classCourseInfo.numberOfRoomsRequired;

    if (possibleRoomsId.length >= numberOfRoomsRequired) {
      setBackendError("");

      const data = {
        classId: classId,
        courseId: selectedCodeId,
        numberOfStudents:
          editNumberOfStudents !== undefined
            ? editNumberOfStudents
            : classCourseInfo.numberOfStudents,
        numberOfStaffRequiredAtOneTime:
          editNumberOfStaffRequiredAtOneTime !== undefined
            ? editNumberOfStaffRequiredAtOneTime
            : classCourseInfo.numberOfStaffRequiredAtOneTime,
        numberOfRoomsRequired: numberOfRoomsRequired,
        possibleRooms: possibleRoomsId,
      };

      ClassCourseAssignment.editClassCourseAssignment(classCourseId, data)
        .then((res) => {
          changeClassCourseAssignmentAll(
            classId,
            selectedCodeId,
            editNumberOfStudents,
            editNumberOfStaffRequiredAtOneTime,
            editNumberOfRoomsRequired,
            possibleRoomsId,
          );
          handleClose();
        })
        .catch((error) => {
          setBackendError(error.response.data);
        });
    } else {
      setBackendError(
        "Number of possible rooms must be at least the number of required rooms.",
      );
    }
  };

  // Getting classCourseAssignment list data
  let clsid, crsid, staff, room, posRoom;
  classCourseAssignment
    .filter((clscourse) => clscourse.id === classCourseId)
    .map((res) => {
      clsid = res.classId;
      crsid = res.courseId;
      staff = res.numberOfStaffRequiredAtOneTime;
      room = res.numberOfRoomsRequired;
      posRoom = res.possibleRooms;
    });

  // Function used to update the Class Course Assignment List
  const changeClassCourseAssignmentStudent = (
    clsid,
    crsid,
    editNumberOfStudents,
    staff,
    room,
    posRoom,
  ) => {
    const updatedClassCourseData = classCourseAssignment.map((obj) => {
      if (obj.id === classCourseId) {
        return {
          ...obj,
          classId: clsid,
          courseId: crsid,
          numberOfStudents:
            editNumberOfStudents === undefined
              ? classCourseInfo.numberOfStudents
              : editNumberOfStudents,
          numberOfStaffRequiredAtOneTime: staff,
          numberOfRoomsRequired: room,
          possibleRooms: posRoom,
        };
      }
      return obj;
    });
    dispatch(AddClassCourseAssignment(updatedClassCourseData));
  };

  // Function used to edit the new class course assignment for only student
  const handleEditStudent = (event) => {
    let data = {
      classId: clsid,
      courseId: crsid,
      numberOfStudents:
        editNumberOfStudents === undefined
          ? classCourseInfo.numberOfStudents
          : editNumberOfStudents,
      numberOfStaffRequiredAtOneTime: staff,
      numberOfRoomsRequired: room,
      possibleRooms: posRoom,
    };
    ClassCourseAssignment.editClassCourseAssignment(classCourseId, data)
      .then((res) => {
        changeClassCourseAssignmentStudent(
          clsid,
          crsid,
          editNumberOfStudents,
          staff,
          room,
          posRoom,
        );
        handleClose();
      })
      .catch((error) => {
        setBackendError(error.response.data);
      });
  };
  useEffect(() => {
    fetchInitialSelectedCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //To display the course which is selected
  const fetchInitialSelectedCourse = () => {
    let initialSelectedCourse;
    const matchingCourse = courseList.find(
      (course) => course.id === classCourseInfo.courseId,
    );

    if (matchingCourse) {
      initialSelectedCourse = `${matchingCourse.courseCode} - ${matchingCourse.courseName}`;
      setEditCourseValue(initialSelectedCourse);
    }
  };

  return (
    <div>
      {classCourseAssignment
        .filter((clscourse) => clscourse.id === classCourseId)
        .map((res) =>
          res.combinedClassId === null ? (
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              PaperProps={{
                sx: { width: "30rem", height: "43rem", boxShadow: "none" },
              }}
            >
              <Stack direction="row">
                <DialogTitle id="responsive-dialog-title">
                  Edit Class Course Assignment <br></br>
                  {className}
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  className="close-icon-course-assignment"
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Divider />
              <DialogContent>
                <DialogContentText>
                  <center>
                    <br></br>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={courseCodeOptions.map(
                        (option) =>
                          option.courseCode + " - " + option.courseName,
                      )}
                      className="autocomplete-classcourse"
                      renderInput={(params) => (
                        <TextField {...params} label="Course" />
                      )}
                      value={editCourseValue}
                      onChange={(event, newValue) => {
                        setEditCourseValue(newValue);
                      }}
                    />

                    <Typography className="model-text course-assignment-text1">
                      Number of Students
                    </Typography>
                    <OutlinedInput
                      id="numberOfStudents"
                      name="numberOfStudents"
                      type="number"
                      value={
                        numberOfStudentsDetails.numberOfStudents ||
                        editNumberOfStudents
                      }
                      onChange={handleEditNumberOfStudents}
                      placeholder="Enter Number of Students"
                      variant="outlined"
                      size="small"
                      className="input-style"
                      required
                    />

                    <Typography className="model-text course-assignment-text2">
                      Staff Required
                    </Typography>
                    <OutlinedInput
                      id="numberOfStaffRequiredAtOneTime"
                      name="numberOfStaffRequiredAtOneTime"
                      type="number"
                      value={
                        numberOfStaffRequiredAtOneTimeDetails.numberOfStaffRequiredAtOneTime ||
                        editNumberOfStaffRequiredAtOneTime
                      }
                      onChange={handleEditNumberOfStaffRequiredAtOneTime}
                      placeholder="Enter Number of Staff Required"
                      variant="outlined"
                      size="small"
                      className="input-style"
                      required
                    />

                    <Typography className="model-text course-assignment-text4">
                      Number of Rooms Required
                    </Typography>
                    <OutlinedInput
                      id="numberOfRoomsRequired"
                      name="numberOfRoomsRequired"
                      type="number"
                      value={
                        numberOfRoomsRequiredDetails.numberOfRoomsRequired ||
                        editNumberOfRoomsRequired
                      }
                      onChange={handleEditNumberOfRoomsRequired}
                      placeholder="Enter Number of Rooms Required"
                      variant="outlined"
                      size="small"
                      className="input-style"
                      required
                    />

                    <Typography className="model-text course-assignment-text">
                      Possible Rooms - Capacity
                    </Typography>

                    <FormControl sx={{ m: 1, width: 300 }}>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="possibleRooms"
                        multiple
                        value={editPossibleRooms}
                        onChange={handleChange}
                        className="possible-rooms-dropdown"
                        required
                      >
                        {menuItems}
                      </Select>
                    </FormControl>
                    {handleBackendError && (
                      <>
                        <p className="class-course-error">
                          {handleBackendError}
                        </p>
                        {handleBackendError !== "" &&
                          editClassCourse &&
                          setEditClassCourse(false)}
                      </>
                    )}
                    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                      <Button
                        variant="contained"
                        className={
                          selectedCodeId === "" ||
                          editNumberOfStudents === 0 ||
                          editNumberOfStudents === "" ||
                          editNumberOfStaffRequiredAtOneTime === 0 ||
                          editNumberOfStaffRequiredAtOneTime === "" ||
                          editNumberOfRoomsRequired === 0 ||
                          editNumberOfRoomsRequired === "" ||
                          editPossibleRooms === 0 ||
                          editClassCourse === true ||
                          handleBackendError !== ""
                            ? "save-building-button-disabled"
                            : "save-building-button"
                        }
                        disabled={
                          selectedCodeId === "" ||
                          editNumberOfStudents === 0 ||
                          editNumberOfStudents === "" ||
                          editNumberOfStaffRequiredAtOneTime === 0 ||
                          editNumberOfStaffRequiredAtOneTime === "" ||
                          editNumberOfRoomsRequired === 0 ||
                          editNumberOfRoomsRequired === "" ||
                          editPossibleRooms === 0 ||
                          editClassCourse === true ||
                          handleBackendError !== ""
                        }
                        onClick={() => {
                          handleEditAll();
                          setEditClassCourse(true);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        className="cancel-building-button"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </center>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          ) : (
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              PaperProps={{
                sx: { width: "30rem", height: "21rem", boxShadow: "none" },
              }}
            >
              <Stack direction="row">
                <DialogTitle id="responsive-dialog-title">
                  Edit Class Course Assignment<br></br> {className}
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  className="close-icon-course-assignment"
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Divider />
              <DialogContent>
                <DialogContentText>
                  <center>
                    <Typography className="model-text course-assignment-text1">
                      Number of Students
                    </Typography>
                    <OutlinedInput
                      id="numberOfStudents"
                      name="numberOfStudents"
                      type="number"
                      value={
                        numberOfStudentsDetails.numberOfStudents ||
                        editNumberOfStudents
                      }
                      onChange={handleEditNumberOfStudents}
                      placeholder="Enter Number of Students"
                      variant="outlined"
                      size="small"
                      className="input-style"
                      required
                    />

                    <br></br>
                    <br></br>
                    <br></br>

                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        className={
                          editNumberOfStudents === 0 ||
                          editNumberOfStudents === ""
                            ? "save-building-button-disabled"
                            : "save-building-button"
                        }
                        disabled={
                          editNumberOfStudents === 0 ||
                          editNumberOfStudents === ""
                        }
                        onClick={handleEditStudent}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        className="cancel-building-button"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </center>
                  {handleBackendError && (
                    <p className="duplicate-text">{handleBackendError}</p>
                  )}
                </DialogContentText>
              </DialogContent>
            </Dialog>
          ),
        )}
    </div>
  );
};

export default EditClassCourseAssignment;
